import { Injector } from "@angular/core";
import { ethers } from "ethers";
import { take } from "rxjs/operators";
import { eActionType } from "../../../common/actions/action-types";
import { ePlatforms } from "../../../common/networks";
import { TConfig } from "../config/config.service";
import { CryptoWalletService } from "../crypto-wallet/crypto-wallet.service";
import { ProviderFactoryService } from "../provider/provider-factory.service";
import { BSCCurrenciesFactory } from "./bsc-currency";
import { IEthereumContract } from "./currency";
import { eBlockChainExplorerFactory } from "./explorers/blockchain-explorer";
import { IBlockChainExplorer, ITransactionResponceEx } from "./explorers/types";
import { ContractLike, IContract, Wallet } from "./wallet.service";

export class BSCWallet extends Wallet
{
    protected iconUrl: string = '/assets/images/symbols/bnb.svg';

    private name: string = 'Binance';

    private readonly explorer: IBlockChainExplorer;

    constructor(
        protected injector: Injector,
        _cryptoWallet: CryptoWalletService,
        _cryptoWalletId: string,
        _address: string,
        _initCurrencies = false
    )
    {
        super(injector, _cryptoWallet, _cryptoWalletId, _address, _initCurrencies);

        this.init();

        this.explorer = eBlockChainExplorerFactory.CreateBSCExplorer(injector.get(ProviderFactoryService));
    }

    protected async init()
    {
        this.provider = await this.providerFactoryService.getProvider('bsc');

        await this.loadCurrencies();

        this.isWalletReady.next(true);

        this.eventTransport
            .on('BSC_PROVIDER_UPDATE')
            .subscribe(async () =>
            {
                this.isWalletReady.next(false);

                this.provider = await this.providerFactoryService.getProvider('bsc');

                await this.loadCurrencies();

                this.isWalletReady.next(true);
            });
    }

    public async setEncryptedData(_encrJson: string, _password: string)
    {
        this.privateWallet = await ethers.Wallet.fromEncryptedJson(
            _encrJson,
            _password
        );
    }

    public async setPrivateKey(_privateKey: string)
    {
        let privateKey = _privateKey;

        if (!privateKey.startsWith('0x'))
        {
            privateKey = '0x' + privateKey;
        }

        this.privateWallet = new ethers.Wallet(privateKey);
    }

    public async setWalletPhrase(_phrase: string)
    {
        this.privateWallet = ethers.Wallet.fromMnemonic(_phrase);
    }

    private static ConvertContractToObject(_contract: ContractLike): IContract
    {
        if (Boolean(_contract) && _contract !== 'null')
        {
            return typeof _contract === 'string'
                ? JSON.parse(_contract)
                : _contract;
        }
        else
        {
            return null;
        }
    }

    public async addCurrencyByContract(
        _contract: ContractLike,
        _symbol: string,
        _id?: number,
        _config?: TConfig,
        _data?: any
    )
    {
        let contractData: IEthereumContract = null;

        let contract = BSCWallet.ConvertContractToObject(_contract);

        if (contract != null)
        {
            if (!Boolean(contract[this.network]) && _config != null)
            {
                //todo refac
                this.currenciesCount -= 1;
                this.checkCurrenciesLoaded();
                return;
            }

            contractData = {
                address: contract[this.network].address,
                abi: contract[this.network].abi,
            };
        }

        const currency = BSCCurrenciesFactory(
            _symbol,
            this.injector,
            this,
            this.address,
            contractData
        );

        //refac me
        // if (_config != null) {
        //     await currency.onSetup.pipe(take(1)).toPromise();

        //     if (
        //         !_data &&
        //         _config.currencies.add_if_not_empty_balance.includes(
        //             _symbol.toUpperCase()
        //         ) &&
        //         Number(await currency.getBalance()) === 0
        //     ) {
        //         console.log(_symbol);
        //         this.currenciesCount -= 1;
        //         this.checkCurrenciesLoaded();
        //         this.currencyApi.removeWalletCurrency(this.cryptoWalletId, _id);
        //         return;
        //     }
        // }

        await currency.onSetup.pipe(take(1)).toPromise();

        this.addCurrency(currency);
    }

    protected async loadCurrencies()
    {
        this.currencies = [];
        const config = await this.configService.get();
        this.network = await this.networksService.getNetwork('bsc');

        let currencies = await this.currencyApi.getWalletCurrency(
            this.cryptoWalletId,
            this.address,
            'BSC'
        );


        if (currencies.length === 0 && this.initCurrencies)
        {
            currencies = await this.currencyApi.createBaseWalletCurrencies(
                this.cryptoWalletId,
                this.address,
                this.getPlatform()
            );
        }

        this.currenciesCount = currencies.length;

        if (currencies.length === 0 && !this.initCurrencies)
        {
            this.currenciesSubject.next(this.currencies);
            this.checkCurrenciesLoaded();
        }

        try
        {
            await Promise.all(
                currencies.map(async (_currency, index) =>
                {
                    await this.addCurrencyByContract(
                        _currency.contract,
                        _currency.symbol,
                        _currency['id'],
                        config
                    );
                })
            );
        } catch (error)
        {
            console.log(error);
        }
    }

    public async getBlockInfo(_blockNumber: number)
    {
        return await this.provider.getBlock(_blockNumber);
    }

    public getPlatform(): string
    {
        return ePlatforms.BSC;
    }

    public getName(): string
    {
        return this.name;
    }

    public async getHistory(): Promise<ITransactionResponceEx[]>
    {
        const block = await this.provider.getBlockNumber();

        //todo do pagination instead
        let history = await this.explorer.GetHistory(
            this.address,
            null,
            { startBlock: 0, endBlock: block });

        this.history = history.filter((_tx, index) =>
        {
            const txIndex = history.findIndex(_item => _item.hash === _tx.hash);

            return txIndex === index;
        });

        return this.history;
    }

    public async getPrivateKey(): Promise<string> {
        const cryptoWallet = await this.cryptoWallet.getCryptoWalletById(this.cryptoWalletId);
        const actionInfo = await this.actionService.prepareAction(eActionType.ExportKey, this.cryptoWalletId, cryptoWallet.name);
        await this.setupPrivateData(actionInfo);
        return this.privateWallet.privateKey;
    }

    public async getEncryptedJSON(_password: string): Promise<string> {
        const cryptoWallet = await this.cryptoWallet.getCryptoWalletById(this.cryptoWalletId);
        const actionInfo = await this.actionService.prepareAction(eActionType.ExportJson, this.cryptoWalletId, cryptoWallet.name);
        await this.setupPrivateData(actionInfo);

        return await this.privateWallet.encrypt(_password);
    }

    public async sendTransaction(_txData: any, _txInfo: {symbol: string, value: string, to?: string}): Promise<any> {
        await super.sendTransaction(_txData, _txInfo);

        const connectedWallet = this.privateWallet.connect(this.provider);

        _txData.from = connectedWallet.address;

        return await connectedWallet.sendTransaction(_txData);
    }

    public static getAddressByPrivateKey(_privateKey: string): string
    {
        let privateKEy = _privateKey;

        if (!_privateKey.startsWith('0x'))
        {
            privateKEy = '0x' + _privateKey;
        }

        return new ethers.Wallet(privateKEy).address;
    }

    public static getAddressByPhrase(_phrase: string): string
    {
        return ethers.Wallet.fromMnemonic(_phrase).address;
    }

    public static async getAddressByEncryptedJson(
        _json: string,
        _password: string
    ): Promise<string>
    {
        const wallet = await ethers.Wallet.fromEncryptedJson(_json, _password);

        return wallet.address;
    }

    public async getLinkToExplorer(_txHash: string): Promise<string>
    {
        const network = await this.networksService.getNetwork('bsc');

        if (network === 'testnet')
        {
            return `https://testnet.bscscan.com/tx/${_txHash}`;
        } else
        {
            return `https://bscscan.com/tx/${_txHash}`;
        }
    }
}
