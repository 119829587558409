export default [
    "HSVC.A",
    "CAKE",
    "BAKE",
    "FTX",
    "AAVE",
    "CUSDC",
    "WBTC",
    "UNI",
    "ITNM",
    "JC",
    "JCOIN",
    "KRW",
    "JASMY1",
    "BUSD",
    "DAI",
    "JC3",
    "JC6",
    "JDT3",
    "JDT6",
    "AIOT",
    "IOT",
    "JASMY",
    "$PAC",
    "0XBTC",
    "2GIVE",
    "ABT",
    "ACT",
    "ACTN",
    "ADA",
    "ADD",
    "ADX",
    "AE",
    "AEON",
    "AEUR",
    "AGI",
    "AGRS",
    "AION",
    "AMB",
    "AMP",
    "AMPL",
    "ANT",
    "APPC",
    "ARDR",
    "ARG",
    "ARK",
    "ARN",
    "ARY",
    "AST",
    "ATM",
    "ATOM",
    "AUDR",
    "AUTO",
    "AYWA",
    "BAB",
    "BAT",
    "BAY",
    "BCBC",
    "BCC",
    "BCD",
    "BCH",
    "BCIO",
    "BCN",
    "BCO",
    "BCPT",
    "BDL",
    "BEAM",
    "BELA",
    "BIX",
    "BLCN",
    "BLK",
    "BLOCK",
    "BLZ",
    "BNB",
    "BNT",
    "BNTY",
    "BOOTY",
    "BOS",
    "BPT",
    "BQ",
    "BRD",
    "BSD",
    "BSV",
    "BTC",
    "BTCD",
    "BTCH",
    "BTCP",
    "BTCZ",
    "BTDX",
    "BTG",
    "BTM",
    "BTS",
    "BTT",
    "BTX",
    "BURST",
    "BZE",
    "CALL",
    "CC",
    "CDN",
    "CDT",
    "CHAIN",
    "CHAT",
    "CHIPS",
    "CIX",
    "CLAM",
    "CLOAK",
    "CMM",
    "CMT",
    "CND",
    "CNX",
    "CNY",
    "COB",
    "COLX",
    "COQUI",
    "CRED",
    "CRPT",
    "CRW",
    "CS",
    "CTR",
    "CTXC",
    "CVC",
    "D",
    "DASH",
    "DASH",
    "DAT",
    "DATA",
    "DBC",
    "DCN",
    "DCR",
    "DEEZ",
    "DENT",
    "DEW",
    "DGB",
    "DGD",
    "DLT",
    "DNT",
    "DOCK",
    "DOGE",
    "DOT",
    "DRGN",
    "DROP",
    "DTA",
    "DTH",
    "DTR",
    "EBST",
    "ECA",
    "EDG",
    "EDO",
    "EDOGE",
    "ELA",
    "ELEC",
    "ELF",
    "ELIX",
    "ELLA",
    "EMC",
    "EMC2",
    "ENG",
    "ENJ",
    "ENTRP",
    "EON",
    "EOP",
    "EOS",
    "EQLI",
    "EQUA",
    "ETC",
    "ETH",
    "ETHOS",
    "ETN",
    "ETP",
    "EUR",
    "EVX",
    "EXMO",
    "EXP",
    "FAIR",
    "FCT",
    "FIL",
    "FJC",
    "FLDC",
    "FLO",
    "FSN",
    "FTC",
    "FUEL",
    "FUN",
    "GAME",
    "GAS",
    "GBP",
    "GBX",
    "GBYTE",
    "GENERIC",
    "GIN",
    "GLXT",
    "GMR",
    "GNO",
    "GNT",
    "GOLD",
    "GRC",
    "GRIN",
    "GRS",
    "GSC",
    "GTO",
    "GUP",
    "GUSD",
    "GVT",
    "GXS",
    "GZR",
    "HIGHT",
    "HODL",
    "HOT",
    "HPB",
    "HSR",
    "HT",
    "HTML",
    "HUC",
    "HUSH",
    "ICN",
    "ICX",
    "IGNIS",
    "ILK",
    "INK",
    "INS",
    "ION",
    "IOP",
    "IOST",
    "IOTX",
    "IQ",
    "ITC",
    "JMT",
    "JST",
    "JNT",
    "JPY",
    "KCS",
    "KIN",
    "KLOWN",
    "KMD",
    "KNC",
    "KRB",
    "LBC",
    "LEND",
    "LEO",
    "LINK",
    "LKK",
    "LOOM",
    "LPT",
    "LRC",
    "LSK",
    "LTC",
    "LUN",
    "MAID",
    "MANA",
    "MATIC",
    "MCAP",
    "MCO",
    "MDA",
    "MDS",
    "MED",
    "MEETONE",
    "MFT",
    "MIOTA",
    "MITH",
    "MKR",
    "MLN",
    "MNX",
    "MNZ",
    "MOAC",
    "MOD",
    "MONA",
    "MSR",
    "MTH",
    "MTL",
    "MUSIC",
    "MZC",
    "NANO",
    "NAS",
    "NAV",
    "NCASH",
    "NDZ",
    "NEBL",
    "NEO",
    "NEOS",
    "NEU",
    "NEXO",
    "NGC",
    "NIO",
    "NLC2",
    "NLG",
    "NMC",
    "NPXS",
    "NULS",
    "NXS",
    "NXT",
    "OAX",
    "OK",
    "OMG",
    "OMNI",
    "ONG",
    "ONT",
    "OOT",
    "OST",
    "OST",
    "OX",
    "PART",
    "PASC",
    "PASL",
    "PAX",
    "PAY",
    "PAYX",
    "PINK",
    "PIRL",
    "PIVX",
    "PLR",
    "POA",
    "POE",
    "POLIS",
    "POLY",
    "POT",
    "POWR",
    "PPC",
    "PPP",
    "PPT",
    "PRE",
    "PRL",
    "PUNGO",
    "PURA",
    "QASH",
    "QIWI",
    "QLC",
    "QRL",
    "QSP",
    "QTUM",
    "R",
    "RADS",
    "RAP",
    "RCN",
    "RDD",
    "RDN",
    "REN",
    "REP",
    "REQ",
    "RHOC",
    "RIC",
    "RISE",
    "RLC",
    "RPX",
    "RUB",
    "RVN",
    "RYO",
    "SAFE",
    "SAI",
    "SALT",
    "SAN",
    "SBD",
    "SBERBANK",
    "SC",
    "SHIFT",
    "SIB",
    "SIN",
    "SKY",
    "SLR",
    "SLS",
    "SMART",
    "SMART",
    "SNGLS",
    "SNM",
    "SNT",
    "SOC",
    "SPANK",
    "SPHTX",
    "SRN",
    "STAK",
    "START",
    "STEEM",
    "STORJ",
    "STORM",
    "STQ",
    "STRAT",
    "SUB",
    "SUMO",
    "SYS",
    "TAAS",
    "TAU",
    "TBX",
    "TEL",
    "TEN",
    "TERN",
    "TGCH",
    "THETA",
    "TIX",
    "TKN",
    "TKS",
    "TNB",
    "TNC",
    "TNT",
    "TOMO",
    "TPAY",
    "TRIG",
    "TRTL",
    "TRX",
    "TUSD",
    "TZC",
    "UBQ",
    "UNITY",
    "USD",
    "USDC",
    "USDT",
    "UTK",
    "VERI",
    "VET",
    "VIA",
    "VIB",
    "VIBE",
    "VIVO",
    "VRC",
    "VRSC",
    "VTC",
    "VTHO",
    "WABI",
    "WAN",
    "WAVES",
    "WAX",
    "WGR",
    "WICC",
    "WINGS",
    "WPR",
    "WTC",
    "X",
    "XAS",
    "XBC",
    "XBP",
    "XBY",
    "XCP",
    "XDN",
    "XEM",
    "XIN",
    "XLM",
    "XMCC",
    "XMG",
    "XMO",
    "XMR",
    "XMY",
    "XP",
    "XPA",
    "XPM",
    "XRP",
    "XRP",
    "XSG",
    "XTZ",
    "XUC",
    "XVC",
    "XVG",
    "XZC",
    "YFI",
    "YOYOW",
    "ZCL",
    "ZEC",
    "ZEL",
    "ZEN",
    "ZEST",
    "ZEST",
    "ZIL",
    "ZILLA",
    "ZRX",
    "XETH",
    "XXBT"
];
