import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

  @Input('alert-title')       title:        string;//key
  @Input('alert-description') description:  string;//already formatted
  @Input('alert-action')      action:       string;
  @Input('alert-close')       close:        string;

  @Output() onClose:  EventEmitter<void> = new EventEmitter();
  @Output() onAction: EventEmitter<void> = new EventEmitter();

  constructor() {}
}
