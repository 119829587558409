import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { IUser } from './types';
import { Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userData: IUser = null;

  private userState: ReplaySubject<IUser> = new ReplaySubject(1);

  private resourceData: {[key: string]: any} = {};

  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) {
    this.authService.loginState.subscribe(_state => {
      if (_state) {
        this.loadUserData();
      }
    });
  }

  get data(): Observable<IUser> {
    return this.userState.asObservable();
  }

  get dataPromise(): Promise<IUser> {
    return this.userState.asObservable().pipe(take(1)).toPromise();
  }

  public async getResourceData(_key: string, _force = false) {
    if (this.resourceData[_key] != null && !_force) return this.resourceData[_key];

    const response = await this.http.post('/api/resource/user-data/get', {
      keys: [_key]
    }).toPromise();

    if (response['result'] !== 0) {
      return false;
    }

    if (!response['values']) {
      return false;
    }

    const value = response['values'].find(_data => _data['value_key'] === _key);

    let result = value ? value['value_data'] : null;

    try {
      const jsonResult = JSON.parse(result);

      result = jsonResult;
    } catch (e) {}

    this.resourceData[_key] = result;

    return result;
  }

  private async loadUserData() {
    try {
      const response = await this.http.get('/api/user').toPromise();

      this.userData = {
        id: response['id'],
        email: response['email']
      };

      this.userState.next(this.userData);
    } catch (error) {
      throw error;
    }
  }

  private clear() {
    this.userData = null;

    this.userState.next(this.userData);
  }
}
