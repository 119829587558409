import { Injectable } from '@angular/core';
import { providers } from 'ethers';
import { environment } from '../../../environments/environment';
import { BscNetworks, NetworksService } from '../networks/networks.service';
import { EventTransportService } from '../event-transport/event-transport.service';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { eBlockChainExplorerFactory } from '../wallets/explorers/blockchain-explorer';
import { IProviderFactoryService } from './IProviderFactoryService';
import { CustomJsonRpcProvider } from './custom-json-rpc-provider';
import { CustomFallbackProvider } from './custom-fallback-provider';





@Injectable({
  providedIn: 'root'
})
export class ProviderFactoryService implements IProviderFactoryService
{

  private etherProvider: providers.BaseProvider;
  private bscProvider: providers.BaseProvider;

  private etherscanApiSubject: ReplaySubject<string> = new ReplaySubject(1);

  constructor(
    private networksService: NetworksService,
    private eventTransport: EventTransportService
  )
  {
    this.setupEtherscanProvider();

    this.listenNetworks();
  }

  private listenNetworks()
  {
    this.eventTransport.on('NETWORK_CHANGE').subscribe(({ data }) =>
    {
      if (Boolean(data['ethereum']))
      {
        this.updateProvider('ethereum', data['ethereum']);
      }

      if (Boolean(data['bsc']))
      {
        this.updateProvider('bsc', data['bsc']);
      }
    });
  }

  public async getProvider(_platform: string)
  {

    if (_platform === 'ethereum')
    {
      const etherNetwork = await this.networksService.getNetwork('ethereum');
      if (!Boolean(this.etherProvider))
      {
        // this.etherProvider = new providers.EtherscanProvider(etherNetwork, environment.etherScanApi);
        const alchemyApis = environment.alchemy[etherNetwork];
        const alchemyApi = alchemyApis[Math.round(Math.random() * (alchemyApis.length - 1))];

        this.etherProvider = new providers.StaticJsonRpcProvider(`https://eth-${etherNetwork}.alchemyapi.io/v2/${alchemyApi}`, etherNetwork);
      }

      return this.etherProvider;
    }

    if (_platform === 'bsc')
    {
      const bscNetwork = await this.networksService.getNetwork('bsc');

      if (!Boolean(this.bscProvider))
      {
        const bscProviders = [];

        environment.bsc[bscNetwork].forEach((_url) =>
        {
          bscProviders.push({provider: new CustomJsonRpcProvider(BscNetworks[bscNetwork], _url, BscNetworks[bscNetwork]), priority: Math.round(Math.random() * 100)});
        });

        this.bscProvider = new CustomFallbackProvider(bscProviders, 1);
      }

      return this.bscProvider;
    }
  }

  public async getApi(_platform: string)
  {
    if (_platform === 'etherscan')
    {
      return await this.etherscanApiSubject.pipe(take(1)).toPromise();
    }

    if (_platform === 'bsc')
    {
      const bscNetwork = await this.networksService.getNetwork('bsc');

      return environment.bscContractsApi[bscNetwork];
    }
  }

  private updateProvider(_platform: string, _network: string)
  {
    if (_platform === 'ethereum')
    {
      // this.etherProvider = new providers.EtherscanProvider(_network, environment.etherScanApi);
      const alchemyApis = environment.alchemy[_network];
      const alchemyApi = alchemyApis[Math.round(Math.random() * (alchemyApis.length - 1))];
      this.etherProvider = new providers.AlchemyProvider(_network, alchemyApi);

      this.eventTransport.send('ETHEREUM_PROVIDER_UPDATE');

      this.setupEtherscanProvider(_network);
    }

    if (_platform === 'bsc')
    {
      // this.etherProvider = new providers.EtherscanProvider(_network, environment.etherScanApi);
      const bscProviders = [];

      environment.bsc[_network].forEach((_url) =>
      {
        bscProviders.push(new CustomJsonRpcProvider(BscNetworks[_network], _url));
      });

      this.bscProvider = new CustomFallbackProvider(bscProviders, 1);

      this.eventTransport.send('BSC_PROVIDER_UPDATE');
    }
  }

  private async setupEtherscanProvider(_network?: string)
  {
    const network = _network || await this.networksService.getNetwork('ethereum');

    let api = environment.etherContractsApi[network];

    let explorer = eBlockChainExplorerFactory.CreateEtherExplorer(api);

    try
    {
      //check connection
      await explorer.GetBlockNumber();
    }
    catch (error)
    {
      if (error.message === 'Failed to fetch')
      {
        api = environment.etherContractsApi['cn'];
      }
    }

    if (api.includes('api-cn') && network !== 'mainnet')
    {
      this.eventTransport.send('toast', { message: 'COMMON.TESTNET-NOT-SUPPORTED', type: 'warning' })
    }

    this.etherscanApiSubject.next(api);
  }
}
