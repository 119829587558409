import { ethers } from 'ethers';
import { environment } from '../../../../environments/environment';
import { IProviderFactoryService } from '../../provider/IProviderFactoryService';
import { eBlockLookup, ePageLookup, eRejectFailReason, IBlockChainExplorer, IGasOracle, ITransactionResponceEx } from './types';

interface IEtherscanResponce<T>
{
    status:     string;
    message:    string;

    result:     T;
}

interface IEtherscanHistoryNodeResponce
{
    timeStamp:      number;
    value:          string;
    from:           string;
    to:             string;
    hash:           string;
    input:          string;
    confirmations:  string;
    blockNumber:    string;

    gas:            string;
    gasPrice:       string;
    gasUsed:        string;
}

interface IEtherscanHistoryResponce extends IEtherscanResponce<IEtherscanHistoryNodeResponce[]>
{
}

interface IEtherscanABIResponce extends IEtherscanResponce<string>
{
}

interface IEtherscanBlockResponce extends IEtherscanResponce<string>
{
}

interface IEtherscanEstimatationResponce extends IEtherscanResponce<string>
{
}

interface IEtherscanGasOracleResponceResult
{
    SafeGasPrice:       string,
    ProposeGasPrice:    string,
    FastGasPrice:       string,
}

interface IEtherscanGasOracleResponce extends IEtherscanResponce<IEtherscanGasOracleResponceResult>
{
}

enum eEtherscanModule
{
    PROXY       = "proxy",
    ACCOUNT     = "account",
    CONTRACT    = "contract",
    GAS_TRACKER = "gastracker"
}

enum eEtherscanAction
{
    ETH_BLOCKNUMBER = "eth_blockNumber",
    GASORACLE       = "gasoracle",
    GASESTIMATE     = "gasestimate",
    TOKENTX         = "tokentx",
    TXLIST          = "txlist"
}

function ParseWei(_value: string)
{
    return ethers.utils.parseUnits(_value, 'wei');
}

export class eEtherscanBlockChainExplorer implements IBlockChainExplorer
{
    private providerFactoryService: IProviderFactoryService | string;//todo remove me. pass only etherscanApi to ctor 

    constructor(_providerService: IProviderFactoryService | string)
    {
        this.providerFactoryService = _providerService;
    }

    //#region Public Methods
    public async GetEstimatationOfConfirmTime(gasPrice: string): Promise<number>
    {
        let action = `${eEtherscanAction.GASESTIMATE}&gasprice=${gasPrice}`;
        let res = (<IEtherscanEstimatationResponce>await this.Fetch(eEtherscanModule.GAS_TRACKER, action));
        return Number(res.result);
    }

    public async GetBlockNumber(): Promise<number>
    {
        let res = (<IEtherscanBlockResponce>await this.Fetch(eEtherscanModule.PROXY, eEtherscanAction.ETH_BLOCKNUMBER));
        return Number(res.result);
    }

    public async GetHistory(
        _address:           string,
        _contractAddress:   string,
        _lookSettings:      ePageLookup | eBlockLookup): Promise<ITransactionResponceEx[]>
    {

        let action = _contractAddress != null ? `${eEtherscanAction.TOKENTX}&contractaddress=${_contractAddress}`
                                              : eEtherscanAction.TXLIST;

        let url = `${action}`
                + `&address=${_address}`
                + `&sort=desc`;

        if (_lookSettings instanceof ePageLookup)
        {
            url += `&page=${_lookSettings.page}&offset=${_lookSettings.page}`;
        }
        else
        {
            url += `&startblock=${_lookSettings.startBlock}&endblock=${_lookSettings.endBlock}`;
        }

        let res = <IEtherscanHistoryResponce>await this.Fetch(eEtherscanModule.ACCOUNT, url);

        let history = res.result.map((_tx) =>
        {
            return {
                from:           _tx.from,
                to:             _tx.to,
                hash:           _tx.hash,
                data:           _tx.input,

                gasLimit:       ParseWei(_tx.gas),
                gasUsed:        ParseWei(_tx.gasUsed),
                gasPrice:       ParseWei(_tx.gasPrice),

                timestamp:      Number(_tx.timeStamp),
                confirmations:  Number(_tx.confirmations),
                value:          ParseWei(_tx.value),
                blockNumber:    Number(_tx.blockNumber),
            };
        });

        return history;
    }

    public async GetContractABI(_address: string): Promise<string>
    {
        let url = `getabi&address=${_address}`;
        return (<IEtherscanABIResponce>await this.Fetch(eEtherscanModule.CONTRACT, url)).result;
    }

    public async GetGasOracle(): Promise<IGasOracle>
    {
        let apiRes = (<IEtherscanGasOracleResponce>await this.Fetch(eEtherscanModule.GAS_TRACKER, eEtherscanAction.GASORACLE)).result;

        return {
            proposeGasPrice: apiRes.ProposeGasPrice,
            fastGasPrice:    apiRes.FastGasPrice,
            safeGasPrice:    apiRes.SafeGasPrice,
        }
    }
    //#endregion Public Methods

    private ParseFailReason<T>(_responce: IEtherscanResponce<T>)
    {
        //todo check http return code

        const S_OK = "1";

        // NOTE: Etherscan for empty history returns next:
        // >>>Status: "0", message: "No transactions found", result: Array(0)}
        // Here "0" is rather normal status, so if _responce.result is string,
        // then it must be error message. Or no??

        //todo use npm styring check
        if (_responce.status !== S_OK && typeof((<any>_responce).result) === "string")
        {
            if ((<any>_responce).result.toLowerCase() === 'max rate limit reached')
            {
                throw eRejectFailReason.FAIL_BY_LIMIT_REACHED;
            }
            else
            {
                throw eRejectFailReason.FAIL;
            }
        }
    }
    
    private async Fetch(_module:string, _action: string): Promise<any>
    {
        let api: string = null;

        //todo
        if (typeof this.providerFactoryService === 'string' || this.providerFactoryService instanceof String)
        {
            api = <string>this.providerFactoryService;
        }
        else
        {
            api = await this.providerFactoryService.getApi('etherscan');
        }

        let url = `${api}?module=${_module}&action=${_action}&apikey=${environment.etherScanApi}`;
        return fetch(url).then(async res =>
        {
            var data = <IEtherscanResponce<any>>await res.json();
            this.ParseFailReason(data);
            return data;
        });
    }
}
