import {Injectable} from '@angular/core';
import { ePlatforms } from '../../../common/networks';
import symbolsWithIcon from '../../../common/symbols';

@Injectable({
  providedIn: 'root'
})
export class CoinsService {

  constructor() {
  }

  public getSymbolIcon(_symbol: string) {
    if (symbolsWithIcon.includes(_symbol.toUpperCase())) {
      return `/assets/images/symbols/${_symbol.toLowerCase()}.svg`;
    }

    return null;
  }

  public getPlatformIcon(_platform: string) {
    if (_platform.toLowerCase() === ePlatforms.ETHEREUM.toLowerCase()) return '/assets/images/symbols/eth.svg';

    if (_platform.toLowerCase() === ePlatforms.BSC.toLowerCase()) return '/assets/images/symbols/bnb.svg';
  }

  public parseExchangeSymbol(_symbol: string): string[] {
    const exchangeSymbol = _symbol.toUpperCase();
    let coins = exchangeSymbol.toUpperCase().split(/-|_|\/|\./g);

    if (coins.length === 2) {
      return coins;
    }

    symbolsWithIcon.some(_coinSymbol => {
      const secondSymbol = exchangeSymbol.replace(_coinSymbol, '');
      if (exchangeSymbol.toUpperCase().startsWith(_coinSymbol) && symbolsWithIcon.includes(secondSymbol)) {
        coins = [_coinSymbol, secondSymbol];
        return true;
      }

      return false;
    });

    if (coins.length === 2) {
      return coins;
    }

    return [null, null];
  }
}
