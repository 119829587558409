import { IActionInfo } from "./action-interfaces";
import { eActionType, action_ids, getActionId } from "./actions/action-types";
import { getAction, IValues_1, action_version } from "./actions/values1-type";
import { eActionTemplate } from "./request_enums";

type extractValueFunc = (val: string, ...args: string[]) => Promise<string>;

export function getPreparedAction(type: eActionType, version: action_version = 0)
{
    const template_id = getTemplate(type);
    let action;

    if(version && version == 1)
    {
        action = {
            template_id,
            version,
            ...getAction(type)
        };
    }
    else
    {
        action = {
            template_id,
            ...actions[type]
        };
    }

    return action;
}
                    
export function getActionType(action: IActionInfo): eActionType
{
    switch (action.template_id)
    {
        case eActionTemplate.authorization: return eActionType.Authorization;
        case eActionTemplate.transaction:   return eActionType.TransactionStart;
        case eActionTemplate.exchange:      return eActionType.ExchangeStart;
    }

    // parse actions
    // if(checkAction(action.action_id, 'transaction'))     return eActionType.TransactionStart;
    // if(checkAction(action.action_id, 'exchange-start'))  return eActionType.ExchangeStart;
    if (checkAction(action.action_id, 'exchange-load'))     return eActionType.ExchangeLoad;
    if (checkAction(action.action_id, 'backup-remove'))     return eActionType.BackupRemove;
    if (checkAction(action.action_id, 'backup-load'))       return eActionType.BackupLoad;
    if (checkAction(action.action_id, 'export-phrase'))     return eActionType.ExportPhrase;
    if (checkAction(action.action_id, 'export-json'))       return eActionType.ExportJson;
    if (checkAction(action.action_id, 'export-key'))        return eActionType.ExportKey;
    if (checkAction(action.action_id, 'wallet-remove'))     return eActionType.WalletRemove;
    if (checkAction(action.action_id, 'wallet-update'))     return eActionType.WalletUpdate;
    if (checkAction(action.action_id, 'security-change'))   return eActionType.SecurityChange;

    throw new Error('UNKNOWN_ACTION');
}

export async function extractActionValues(info: {version: action_version, template_id: eActionTemplate, values}, extractObj, ...args: string[])
{
    if(info.version && info.version == 1)
    {
        let sourceValues = info.values;
        let destValues = {};

        for (const key in sourceValues) {
            const element = sourceValues[key];
            let value = '';
            if (Array.isArray(element))
            {
                for (const item of element) {
                    value = value + (await extractObj.extractValue(item, ...args));
                }
            }
            else
            {
                value = await extractObj.extractValue(element, ...args);
            }
            destValues[key] = value;
        }

        let extractedValues = {};        
        switch(info.template_id){
            case eActionTemplate.transaction:   extractedValues['transaction']  = destValues; break;
            case eActionTemplate.exchange:      extractedValues['exchange']     = destValues; break;
            case eActionTemplate.action:        extractedValues['action']       = destValues; break;
        }

        return extractedValues as IValues_1;
    }
    else
    {
        return await Promise.all(info.values.map(async (kv) =>
        {
          const nam = await extractObj.extractValue(kv.name, ...args);
          const val = await extractObj.extractValue(kv.value, ...args);
          return { name: nam, value: val };
        }));
    }
}

const actions = {
    [eActionType.TransactionStart]: {
        action_id: `${getActionId(eActionType.TransactionStart)}:{0}`,
        values: [
            { name: 'ACTIONS.TRANSACTION.SRC-TITLE', value: '{1}' },
            { name: 'ACTIONS.TRANSACTION.DST-TITLE', value: '{2}' },
            { name: '{3}', value: '{4}' },
            { name: '--', value: '' }
        ]
    },
    [eActionType.ExchangeStart]: {
        action_id: `${getActionId(eActionType.ExchangeStart)}:{0}`,
        values: [
            { name: '{1}', value: 'ACTIONS.EXCHANGE.LABEL' },
            { name: 'ACTIONS.EXCHANGE.PAY-TITLE',   value: '{2} {3}' },
            { name: 'ACTIONS.EXCHANGE.GET-TITLE',   value: '{4} {5}' },
            { name: '--', value: '' }
        ]
    },
    [eActionType.ExchangeLoad]: {
        action_id: `${getActionId(eActionType.ExchangeLoad)}:{0}`,
        values: [
            { name: 'ACTIONS.ACTION.EXCHANGE-TITLE', value: '{1}' },
            { name: 'ACTIONS.ACTION.ACTION-TITLE',   value: 'ACTIONS.ACTION.EXCHANGE-LOAD' },
            { name: '--', value: '' }
        ]
    },
    [eActionType.BackupRemove]: {
        action_id: `${getActionId(eActionType.BackupRemove)}:{0}`,
        values: [
            { name: 'ACTIONS.ACTION.WALLET-TITLE', value: '{1}' },
            { name: 'ACTIONS.ACTION.ACTION-TITLE', value: 'ACTIONS.ACTION.BACKUP-REMOVE' },
            { name: '--', value: '' }
        ]
    },
    [eActionType.BackupLoad]: {
        action_id: `${getActionId(eActionType.BackupLoad)}:{0}`,
        values: [
            { name: 'ACTIONS.ACTION.WALLET-TITLE', value: '{1}' },
            { name: 'ACTIONS.ACTION.ACTION-TITLE', value: 'ACTIONS.ACTION.BACKUP-LOAD' },
            { name: '--', value: '' }
        ]
    },
    [eActionType.ExportPhrase]: {
        action_id: `${getActionId(eActionType.ExportPhrase)}:{0}`,
        values: [
            { name: 'ACTIONS.ACTION.WALLET-TITLE', value: '{1}' },
            { name: 'ACTIONS.ACTION.ACTION-TITLE', value: 'ACTIONS.ACTION.EXPORT-PHRASE' },
            { name: '--', value: '' }
        ]
    },
    [eActionType.ExportJson]: {
        action_id: `${getActionId(eActionType.ExportJson)}:{0}`,
        values: [
            { name: 'ACTIONS.ACTION.WALLET-TITLE', value: '{1}' },
            { name: 'ACTIONS.ACTION.ACTION-TITLE', value: 'ACTIONS.ACTION.EXPORT-JSON' },
            { name: '--', value: '' }
        ]
    },
    [eActionType.ExportKey]: {
        action_id: `${getActionId(eActionType.ExportKey)}:{0}`,
        values: [
            { name: 'ACTIONS.ACTION.WALLET-TITLE', value: '{1}' },
            { name: 'ACTIONS.ACTION.ACTION-TITLE', value: 'ACTIONS.ACTION.EXPORT-KEY' },
            { name: '--', value: '' }
        ]
    },
    [eActionType.WalletRemove]: {
        action_id: `${getActionId(eActionType.WalletRemove)}:{0}`,
        values: [
            { name: 'ACTIONS.ACTION.WALLET-TITLE', value: '{1}' },
            { name: 'ACTIONS.ACTION.ACTION-TITLE', value: 'ACTIONS.ACTION.WALLET-REMOVE' },
            { name: '--', value: '' }
        ]
    },
    [eActionType.WalletUpdate]: {
        action_id: `${getActionId(eActionType.WalletUpdate)}:{0}`,
        values: [
            { name: 'ACTIONS.ACTION.WALLET-TITLE', value: '{1}' },
            { name: 'ACTIONS.ACTION.ACTION-TITLE', value: 'ACTIONS.ACTION.WALLET-UPDATE' },
            { name: '--', value: '' }
        ]
    },
    [eActionType.SecurityChange]: {
        action_id: `${getActionId(eActionType.SecurityChange)}`,
        values: [
            { name: 'ACTIONS.ACTION.SECURITY-CHANGE', value: '' },
            { name: '--', value: '' }
        ]
    }
};

function checkAction(actionId: string, id: action_ids): boolean
{
    return actionId.startsWith(id);
}

function getTemplate(type: eActionType): eActionTemplate
{
    switch (type)
    {
        case eActionType.Authorization:     return eActionTemplate.authorization;
        case eActionType.ExchangeStart:     return eActionTemplate.exchange;
        case eActionType.TransactionStart:  return eActionTemplate.transaction;
    }
    return eActionTemplate.action;
}
