import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { CoinsService } from '../coins/coins.service';

export interface ISymbol
{
    symbol: string;
    base_currency: string;
    quote_currency: string;
    precision: string;
}

export interface ITicker
{
    buy: string;
    sell: string;
}

export type OrderState = 'processing' | 'complete' | 'canceled' | 'failed';

export type CurrencyNames = 'AUD' | 'CAD' | 'GBP' | 'JASMY' | 'JPY' | 'ETH' | 'EUR' | 'LTC' | 'USD' | 'XBT' | 'XRP';

export interface IOrderInfo
{
    orderId: string;
    sendAmount: string;
    getAmount: string;
    fee: string;
    finishedAt: string;
    price: string;
    state: OrderState;
    type: 'buy' | 'sell';
    symbol: string;
    currencySend: string;
    currencyGet: string;
}

export abstract class Market<MarketSettings> {

    protected abstract name:    string;
    protected icon:             string;
    protected coinsService:     CoinsService;
    protected http:             HttpClient;

    constructor(
        protected injector: Injector,
        protected settings: MarketSettings
    )
    {
        this.coinsService = this.injector.get(CoinsService);
        this.http = this.injector.get(HttpClient);
    }

    public getName(): string
    {
        return this.name;
    }

    public getIcon(): string
    {
        return this.icon;
    }

    public getOrderInfoUrl(_orderId: string, _symbol?: string): { url: string, method?: string, data: any }
    {
        return null;
    }

    public getSettings()
    {
        return this.settings;
    }

    public abstract checkConnection(): Promise<boolean>;

    public abstract getCurrencies(): Promise<string[]>;

    public abstract getSymbols(_currency?: string): Promise<ISymbol[]>;

    public abstract getBalance(_currency: string): Promise<string>;

    public abstract getTicker(_symbol: string): Promise<ITicker>;

    public abstract getOrderInfo(_orderId: string, symbol?: string): Promise<IOrderInfo>;

    public abstract getOrdersHistory(_symbol?: string): Promise<IOrderInfo[]>;

    public abstract createOrder(_symbol: string, _amount: string, _type: 'buy' | 'sell'): Promise<string>;

    public getCurrencyDisplay(_currency: string): CurrencyNames {
        return <CurrencyNames>_currency;
    }

    
    protected convertAmount(_amount: number, _symbol: ISymbol, _ticker: ITicker, _type: 'buy' | 'sell'): number
    {
        const koeff = _type == 'buy' ? _ticker.buy : _ticker.sell;
        let amount = (Number(_amount) / Number(koeff));

        let multiplyer: number;

        if(_symbol['stepSize']){
            multiplyer = Math.round(1/+_symbol['stepSize']);
        }
        else{
            const decimals = koeff.split('.')[1]?.length || 0;
            multiplyer = +(`1${'0'.repeat(decimals)}`);
        }
        const ceiledAmount = +Math.floor(+amount * multiplyer);
        amount = (ceiledAmount / multiplyer);

        return amount;
    }
}
