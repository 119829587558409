import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {String} from 'src/common/string-extensions';
import {eActionType} from 'src/common/actions/action-types';
import {IActionInfo} from 'src/common/action-interfaces';
import {getPreparedAction, extractActionValues} from 'src/common/action-common';

@Injectable({
    providedIn: 'root'
})
export class ActionService {
  constructor(
    private translate: TranslateService
  )
  {}

  public async prepareAction(type: eActionType, ...args: string[]): Promise<IActionInfo>
  {
    const info = getPreparedAction(type);
    if(info['action_id'])
    {
      info['action_id'] = String.Format(info['action_id'], ...args);
    }

    if(info['values'])
    {
      info['values'] = await extractActionValues(info, this, ...args);
    }

    console.log(` == prepareAction == `);
    console.log(info);

    return info;
  }

  public async extractValue(value: string, ...args: string[])
  {
    if(String.isNullOrWhiteSpace(value))
      return String.Empty;

    try{
      return this.translate.get(String.Format(value, ...args)).toPromise();
    }
    catch(err){
      return String.Empty;
    }
  }
}
