import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { dbConfig } from '../services/storage/db.config';
import { ToastComponent } from './toast/toast.component';
import {Observable} from 'rxjs';

class CustomLoader implements TranslateLoader {
  constructor(
    private http: HttpClient
  ) { }

  getTranslation(lang: string): Observable<any> {
    return this.http.get(`/assets/i18n/${lang}.json?${Date.now()}`);
  }
}

// export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
//   return new TranslateHttpLoader(http, '/assets/i18n/', `.json?${Date.now()}`);
// }

@NgModule({
    declarations: [ToastComponent],
    exports: [
        ToastComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: (CustomLoader),
                deps: [HttpClient]
            }
        }),
        NgxIndexedDBModule.forRoot(dbConfig)
    ],
    providers: [
    ]
})
export class CoreModule {
}
