<main class="main">
    <router-outlet></router-outlet>
    <app-alert *ngIf='showUpdateAlert'
               (onClose)='HideUpdateAlert()'
               (onAction)='UpdateToLastVersion()'
               alert-title='COMMON.UPDATE-ALERT.TITLE'
               [alert-description]='this.alertDescription'
               alert-action='COMMON.UPDATE-ALERT.UPDATE_ACTION'></app-alert>
    <app-alert *ngIf='showIndexDBAlert'
               (onClose)='HideIndexDBAlert()'
               (onAction)='HideIndexDBAlert()'
               alert-title='COMMON.INDEX-DB-ALERT.TITLE'
               alert-description='COMMON.INDEX-DB-ALERT.DESCRIPTION'
               alert-action='COMMON.INDEX-DB-ALERT.ACTION'></app-alert>
</main>
<app-toast></app-toast>
