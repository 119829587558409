import {Injectable, Injector} from '@angular/core';
import {Market} from './market-base.class';
import {MarketHuobi} from './market-huobi';
import {MarketApi} from '../api/market';
import {AppPasswordService} from '../app-password/app-password.service';
import {eOttPermission} from 'src/common/request_enums';
import {eActionType} from 'src/common/actions/action-types';
import {AppKeyV2Service} from '../app-password/app-key-v2.service';
import {CryptoService} from '../crypto/crypto.service';
import {stringToUint8Array} from '../utils';
import {MarketMxc} from './market-mxc';
import {environment} from '../../../environments/environment';
import {MarketBithumb} from './market-bithumb';
import {MarketJubi} from './market-jubi';
import {MarketGateIO} from './market-gate';
import {MarketBithumbKor} from './market-bithumb-kor';
import {MarketBinanceGlobal} from './market-binance-global';
import {MarketKraken} from './market-kraken';
import {IOttInfo} from 'src/common/action-interfaces';
import {ActionService} from '../action/action.service';
import {eMarketType} from './eMarketName';

@Injectable({
    providedIn: 'root',
})
export class MarketsFactoryService
{
    private markets: { [name: string]: Market<any> } = {};

    constructor(
        private marketApi: MarketApi,
        private oldPasswordService: AppPasswordService,
        private passwordService: AppKeyV2Service,
        private cryptoService: CryptoService,
        private actionService: ActionService,
        protected injector: Injector
    ) { }

    public get symbolRequiredForOrderHistoryMarkets() { return ['bitmart', 'mxc', 'gate.io', 'bithumb', 'binance_global']; };

    public get predefinedMarkets()
    {
        return {
            [eMarketType.mxc]:          { text: 'EXCHANGE.MARKETS.MXC',                 icon: '/assets/images/exchanges/mxc.svg',           active: true,   instance: MarketMxc},
            [eMarketType.gate]:         { text: 'EXCHANGE.MARKETS.GATE',                icon: '/assets/images/exchanges/gate.svg',          active: true,   instance: MarketGateIO  },
            // [eMarketType.binance_global]:{ text: 'EXCHANGE.MARKETS.BINANCE',  		icon: '/assets/images/exchanges/binance.svg',       active: true,   instance: MarketBinanceGlobal },
            // [eMarketType.huobi]:        { text: 'EXCHANGE.MARKETS.HUOBI',               icon: '/assets/images/exchanges/huobi.svg',         active: true,   instance: MarketHuobi   },
            // [eMarketType.coinbase]:     { text: 'EXCHANGE.MARKETS.COINBASE',            icon: '/assets/images/exchanges/coinbase-pro.svg',  active: false },
            // [eMarketType.okex]:         { text: 'EXCHANGE.MARKETS.OKEX',                icon: '/assets/images/exchanges/okex.svg',          active: false },
            // [eMarketType.kraken]:       { text: 'EXCHANGE.MARKETS.KRAKEN',              icon: '/assets/images/exchanges/kraken.svg',        active: true,   instance: MarketKraken },
            // [eMarketType.bithumb]:      { text: 'EXCHANGE.MARKETS.BITHUMB-GLOBAL',      icon: '/assets/images/exchanges/bithumb.svg',       active: true,   instance: MarketBithumb },
            // [eMarketType.bithumb_kor]:	{ text: 'EXCHANGE.MARKETS.BITHUMB',             icon: '/assets/images/exchanges/bithumb.svg',       active: true,   instance: MarketBithumbKor },
            // //[eMarketType.bitmart]:    { 													  instance: MarketBitmart },
            // [eMarketType.bitstamp]:     { text: 'EXCHANGE.MARKETS.BITSTAMP',            icon: '/assets/images/exchanges/bitstamp.svg',      active: false },
            // [eMarketType.kucoin]:       { text: 'EXCHANGE.MARKETS.KUCOIN',              icon: '/assets/images/exchanges/kucoin.svg',        active: false },
            // [eMarketType.bittrex]:      { text: 'EXCHANGE.MARKETS.BITTREX',             icon: '/assets/images/exchanges/bittrex.svg',       active: false },
            // [eMarketType.gemini]:       { text: 'EXCHANGE.MARKETS.GEMINI',              icon: '/assets/images/exchanges/gemini.svg',        active: false },
            // [eMarketType.poloniex]:     { text: 'EXCHANGE.MARKETS.POLONIEX',            icon: '/assets/images/exchanges/poloniex.svg',      active: false },
            [eMarketType.jubi]:         { text: 'EXCHANGE.MARKETS.JUBI',                icon: '/assets/images/exchanges/jubi.svg',          active: true,   instance: MarketJubi    },
            [eMarketType.hoo]:          { text: 'EXCHANGE.MARKETS.HOO',                 icon: '/assets/images/exchanges/hoo.svg',           active: false },
            // [eMarketType.bkex]:         { text: 'EXCHANGE.MARKETS.BKEX',                icon: '/assets/images/exchanges/bkex.svg',          active: false },
            // [eMarketType.hotbit]:       { text: 'EXCHANGE.MARKETS.HOTBIT',              icon: '/assets/images/exchanges/hotbit.svg',        active: false },
            // [eMarketType.hitbtc]:       { text: 'EXCHANGE.MARKETS.HITBTC',              icon: '/assets/images/exchanges/hitbtc.svg',        active: false },
            // [eMarketType.bibox]:        { text: 'EXCHANGE.MARKETS.BIBOX',               icon: '/assets/images/exchanges/bibox.svg',         active: false },

            // {name: 'bitfinex', text: 'EXCHANGE.MARKETS.BITFINEX', icon: '/assets/images/exchanges/bitfinex.svg', active: false},
            // {name: 'bitmart', text: 'EXCHANGE.MARKETS.BITMART', icon: '/assets/images/exchanges/bitmart.svg', active: true},
            // {name: 'coinbase-pro', text: 'EXCHANGE.MARKETS.COINBASE-PRO', icon: '/assets/images/exchanges/coinbase-pro.svg', active: false},
        };
    }

    public async setupMarket(_market: eMarketType, _settings: any)
    {
        let existSettings;
        let _name = eMarketType[_market];
        let market = this.markets[_name];
        if(market){
            existSettings = market.getSettings();
        }

        const MarketInstance = this.getMarketInstance(_market);
        if (!MarketInstance)
        {
            throw new Error('MARKET_NOT_FOUND');
        }
        market = new MarketInstance(this.injector, _settings);

        await market.checkConnection();

        if(JSON.stringify(_settings) != JSON.stringify(existSettings)){
            await this.setSettings(_name, _settings);
        }

        this.markets[_name] = market;

        await this.marketApi.setActive(_name);
    }

    public async selectMarket(_name: eMarketType){
        await this.marketApi.setActive(_name);
    }

    public async getMarket<T>(_market: eMarketType):Promise<Market<T>>
    {
        let _name = eMarketType[_market];

        if (!!this.markets[_name]) return this.markets[_name];

        const settings = await this.getSettings(_name);
        if (settings == null)
        {
            throw new Error('SETTINGS_NOT_FOUND');
        }

        const MarketInstance = this.getMarketInstance(_market);

        if (!MarketInstance)
        {
            throw new Error('MARKET_NOT_FOUND');
        }

        const market = new MarketInstance(this.injector, settings);

        await market.checkConnection();

        this.markets[_name] = market;

        return <Market<T>><any>market;
    }

    public async getActiveMarket<T>()
    {
        let activeMarketName = await this.marketApi.getActive();
        if (activeMarketName === 'bithumb-kor'){
            activeMarketName = 'bithumb_kor';
            await this.marketApi.setActive(activeMarketName);
        }

        if (!activeMarketName){
            const addedMarkets = await this.getAddedMarkets();
            if(addedMarkets.length > 0){
                activeMarketName = addedMarkets[0];
                await this.marketApi.setActive(activeMarketName);
            }
        }

        if (!activeMarketName){
            return null;
        }

        let market = eMarketType[activeMarketName];
        return await this.getMarket<T>(market);
    }

    public async getAddedMarkets(): Promise<string[]>
    {
        let markets = await this.marketApi.getStoredMarkets();
        if(!markets){
            markets = await this.marketApi.getBackupedMarkets();
        }
        return markets;
    }

    private getMarketInstance(_name: eMarketType)
    {
        return this.predefinedMarkets[_name].instance;
    }

    private async setSettings(_name: string, _settings: Object, encryptedData?)
    {
        let keyId: number;
        if(encryptedData){
            keyId = encryptedData.key_id;
        }
        else{
            const passwordData = await this.passwordService.create();

            const password = passwordData.password;
            const decryptedData = JSON.stringify(_settings);
            encryptedData = await this.cryptoService.encrypt(password, decryptedData);
            keyId = passwordData.key_id;
        }

        const data = [encryptedData[0]['hexEncode'](), encryptedData[1]];

        await this.marketApi.setStored(_name, keyId, data);
        await this.marketApi.setBackup(_name, keyId, data);
    }

    private async getSettings(_name: string)
    {
        let encryptedSettings = await this.marketApi.getStored(_name);
        if (!encryptedSettings ||
            !encryptedSettings['data'] ||
            !encryptedSettings['keyId']
        ){
            encryptedSettings = await this.marketApi.getBackup(_name);
        }

        if (!encryptedSettings ||
            !encryptedSettings['data'] ||
            !encryptedSettings['keyId']
        ){
            if (environment.markets[_name])
            {
                return environment.markets[_name];
            }

            return null;
        }

        const data = encryptedSettings['data'];
        const keyId = +encryptedSettings['keyId'];

        if (!Array.isArray(data) ||
            data.length !== 2
        )
        {
            // restore deprecated data
            if (
                Boolean(data.iv) &&
                Boolean(data.data)
            )
            {
                const password = this.oldPasswordService.getDeprecated();
                const decryptedData = await this.cryptoService.decryptDeprecated(
                    password,
                    stringToUint8Array(data.data),
                    stringToUint8Array(data.iv)
                );

                if (!decryptedData) return null;

                const settings = JSON.parse(decryptedData);

                await this.setSettings(_name, settings, decryptedData);

                return settings;
            }
        }

        const ottInfo = <IOttInfo>{ permission: eOttPermission.keys, key_id: keyId };
        const isKeysExists = await this.passwordService.checkOttExist(ottInfo);
        if(!isKeysExists){
            return null;
        }

        const marketId = eMarketType[_name];
        const marketTitleResource = this.predefinedMarkets[marketId].text;
        const marketIcon = this.predefinedMarkets[marketId].icon;
        const actionInfo = await this.actionService.prepareAction(eActionType.ExchangeLoad, _name, marketTitleResource, marketIcon);
        const password = await this.passwordService.get(ottInfo, actionInfo);
        const decryptedData = await this.cryptoService.decrypt(
            password,
            data[0].hexDecode(),
            data[1]
        );

        if (!decryptedData[0] || !decryptedData[1])
        {
            return null;
        }

        return JSON.parse(decryptedData[0]);
    }
}
