import { ProviderFactoryService } from '../../provider/provider-factory.service';
import { eBSCBlockChainExplorer } from './bsc';
import { eEtherscanBlockChainExplorer } from './etherscan';
import { IBlockChainExplorer } from './types';

export abstract class eBlockChainExplorerFactory
{
    public static CreateEtherExplorer(_providerService: ProviderFactoryService | string): IBlockChainExplorer
    {
        return new eEtherscanBlockChainExplorer(_providerService);
    }

    public static CreateBSCExplorer(_providerService: ProviderFactoryService | string): IBlockChainExplorer
    {
        return new eBSCBlockChainExplorer(_providerService);
    }

    public static CreateBitcoinExplorer(_providerService: ProviderFactoryService): IBlockChainExplorer
    {
        return null;
    }
}
