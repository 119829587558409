import { Injectable, Injector } from '@angular/core';
import { take } from 'rxjs/operators';
import { eUserSetupAllowedKeys } from 'src/common/user_setup';
import { CurrencyApi } from '../api/currency';
import { UserApi } from '../api/user';
import { AuthService } from '../auth/auth.service';
import { CryptoWalletService } from '../crypto-wallet/crypto-wallet.service';
import { NetworksService } from '../networks/networks.service';
import { EtherCurrenciesFactory } from '../wallets/currency';
import { EtherWallet } from '../wallets/wallet.service';


@Injectable({
    providedIn: 'root'
})
export class PostsetupService
{

    constructor(
        private userApi:             UserApi,
        private cryptoWalletService: CryptoWalletService,
        private currencyApi:         CurrencyApi,
        private network:             NetworksService,
        private injector:            Injector,
        private authService:         AuthService
    )
    {
        this.authService.loginState.subscribe(_state => {
            if (_state)
            {
                
            }
        });
    }

    private async checkAddJasmy()
    {
        if (!!await this.userApi.getUserData(eUserSetupAllowedKeys.ENABLE_JASMY)) return;
        if (await this.network.getNetwork('ethereum') !== 'mainnet') return;

        const currencies = await this.currencyApi.get();
        const jasmyCoin = currencies.find(_item => _item.symbol === 'JASMY');

        const wallets = await this.cryptoWalletService.getWallets();

        wallets.map(async (_item) => {
            const contractData = JSON.parse(jasmyCoin.contract);

            const currency = EtherCurrenciesFactory('JASMY', this.injector, _item.wallet, _item.wallet.getAddress(), contractData.mainnet);

            await currency.onSetup.pipe(take(1)).toPromise();

            if (Number(await currency.getBalance()) === 0) return;

            await this.currencyApi.setWalletCurrency(_item.cryptoWallet, _item.wallet.getAddress(), jasmyCoin.id);

            (<EtherWallet>_item.wallet).addCurrencyByContract(contractData, 'JASMY');
        })

        await this.userApi.setUserData({ [eUserSetupAllowedKeys.ENABLE_JASMY]: '1' });
    }
}
