import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';

export interface IEncrypedWallet
{
    id:                 string,
    address:            string,
    encryptedData:      string,
    keyId:              string
}

const STORAGE_FIELD = 'encrypted-wallets';

@Injectable({
  providedIn: 'root'
})
export class EncryptedWalletApi {

    constructor(
        private storage: StorageService
    )
    {
    }

    private async GetWallets() : Promise<IEncrypedWallet[]>
    {
        return await this.storage.get(STORAGE_FIELD) || [];
    }

    private static GetWallet(_wallets: IEncrypedWallet[], _cryptoWalletId: string, _walletAddress: string)
    {
        return _wallets.find(_wallet =>
        {
            return _wallet.id === _cryptoWalletId && _wallet.address === _walletAddress;
        });
    }

    public async get(_cryptoWalletId: string, _walletAddress: string): Promise<IEncrypedWallet>
    {
        try
        {
            const encryptedWallets: IEncrypedWallet[] = await this.GetWallets()

            const encryptedWallet = EncryptedWalletApi.GetWallet(encryptedWallets, _cryptoWalletId, _walletAddress);

            return encryptedWallet;
        }
        catch (error)
        {
            return undefined;
        }
    }

    public async add(_wallet:IEncrypedWallet)
    {
        //todo add "write" transaction

        try
        {
            const encryptedWallets: IEncrypedWallet[] = await this.GetWallets();

            const encryptedWallet = EncryptedWalletApi.GetWallet(encryptedWallets, _wallet.id, _wallet.address);

            if (Boolean(encryptedWallet))
            {
                return;
            }

            encryptedWallets.push(_wallet);

            await this.storage.set(STORAGE_FIELD, encryptedWallets);
        }
        catch (error)
        {
            return undefined;
        }
    }
}
