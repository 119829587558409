import { Injectable } from '@angular/core';
import {StorageService} from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class WebauthnStorage {

  constructor(
    private storage: StorageService
  ) { }

  private async loadData(): Promise<Object> {
    return await this.storage.get('webauthn-data') || {};
  }

  public async set(_userId: string, _id: string): Promise<void> {
    const webauthnData = await this.loadData();

    if (!webauthnData[_userId]) webauthnData[_userId] = [];

    if (webauthnData[_userId].includes(_id)) return;

    webauthnData[_userId].push(_id);

    return await this.storage.set('webauthn-data', webauthnData);
  }

  public async get(_userId: string): Promise<string[]> {
    const webauthnData = await this.loadData();

    if (!webauthnData[_userId]) return [];

    return webauthnData[_userId];
  }
}
