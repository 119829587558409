import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { eAuthV2Result, eShadowAckResultV2, eStartAuthResult } from '../../../common/request_enums';
import { CryptoService } from '../crypto/crypto.service';
import { EventTransportService } from '../event-transport/event-transport.service';
import secrets from './secrets';
import { environment } from '../../../environments/environment';
import { take } from 'rxjs/operators';
import { IActionInfo, IOttInfo } from 'src/common/action-interfaces';
import { nowToTimeStr } from 'src/common/index';
import { WebAuthenticatorService } from '../auth/web-authenticator.service';

@Injectable({
    providedIn: 'root'
})
export class AppKeyV2Service
{
    constructor(
        private http: HttpClient,
        private eventTransport: EventTransportService,
        private webAuthService: WebAuthenticatorService,
        private cryptoService: CryptoService
    )
    {
    }

    public async checkOttExist(ottInfo: IOttInfo): Promise<boolean>
    {
        if (typeof ottInfo.key_id === undefined || !ottInfo.key_id)
        {
            throw new Error('key_id must be defined!');
        }

        const response = await this.http.post('/api/key-v2/check', ottInfo).toPromise();

        const result = <eAuthV2Result>response['result'];
        return result == eAuthV2Result.success;
    }

    public async cancelFor(action: IActionInfo)
    {
        console.debug(`${nowToTimeStr()} >> /api/key-v2/cancel`);
        const cancelRes = await this.http.post('/api/key-v2/cancel', action).toPromise();
        console.debug(`${nowToTimeStr()} << /api/key-v2/cancel createRes:${JSON.stringify(cancelRes)}`);
    }

    public async create(): Promise<{ key_id: number, password: string; }>
    {
        // create key
        console.debug(`${nowToTimeStr()} >> /api/key-v2/create-with-ott`);
        const createRes = await this.http.get('/api/key-v2/create-with-ott').toPromise();
        console.debug(`${nowToTimeStr()} << /api/key-v2/create-with-ott createRes:${JSON.stringify(createRes)}`);

        const result = <eStartAuthResult>createRes['start_auth_result'];
        if (result != eStartAuthResult.ott_hexed)
        {
            throw new Error(`CREATE-FAILED: ${eStartAuthResult[result]}`);
        }

        const createdKeyId = <number>createRes['key_id'];
        const ott_hexed = createRes['ott_hexed'];
        const firstShadow = createRes['shadow'];

        // request second shadow
        const secondShadow = await this.receiveShadowTwo(createdKeyId, ott_hexed);

        // Shamir`s shared secred from keyShadowClient and keyShadowHyper
        const keyHex = secrets.combine([atob(firstShadow), atob(secondShadow)]);
        const key = secrets.hex2str(keyHex);
        return { key_id: createdKeyId, password: key };
    }

    public async get(ottInfo: IOttInfo, actionInfo: IActionInfo): Promise<string>
    {
        const ott_hexed = await this.getOttHexed(ottInfo, actionInfo);

        const firstShadow = await this.receiveShadowOne(ottInfo.key_id);

        // request second shadow from resource server
        const secondShadow = await this.receiveShadowTwo(ottInfo.key_id, ott_hexed);

        // Shamir`s shared secred from keyShadowClient and keyShadowHyper
        const keyHex = secrets.combine([atob(firstShadow), atob(secondShadow)]);
        const key = secrets.hex2str(keyHex);
        return key;
    }

    private async getOttHexed(ottInfo: IOttInfo, actionInfo: IActionInfo): Promise<string>
    {
        const isWebAuthnSupported = await this.webAuthService.isWebAuthenticationSupported();
        if (isWebAuthnSupported)
        {
            actionInfo['isWebAuthnSupported'] = true;
        }
        const authData = { ott: ottInfo, action: actionInfo };

        console.debug(`${nowToTimeStr()} >> /api/key-v2/start-auth ${JSON.stringify(authData)}`);
        const authRes = await this.http.post('/api/key-v2/start-auth', authData).toPromise();
        console.debug(`${nowToTimeStr()} << /api/key-v2/start-auth ${JSON.stringify(authRes)}`);

        const result = <eStartAuthResult>authRes['start_auth_result'];
        if (result == eStartAuthResult.ott_hexed)
        {
            return authRes['ott_hexed'];
        }
        else
        {
            const verificateData = {
                result: result,
                permission: ottInfo.permission,
                key_id: ottInfo.key_id,
                template_id: actionInfo.template_id,
                action_id: actionInfo.action_id,
                start_auth_result: result,
                data: authRes['data']
            };
            this.eventTransport.send('verificate-user', verificateData);
            const { data } = await this.eventTransport.on('user-verification').pipe(take(1)).toPromise();
            if (data.result === 'SUCCESS')
            {
                return data['ott_hexed'];
            }
            if (data.result === 'FAILED')
            {
                throw new Error(data['code'] || 'ORDER-REJECTED');
            }
        }

        throw new Error('NOT_IMPLEMENTED');
    }

    private async receiveShadowOne(keyId): Promise<string>
    {
        // get first shadow from wallet back-end
        const data = { key_id: keyId };

        console.debug(`${nowToTimeStr()} >> /api/key-v2/get-shadow data:${JSON.stringify(data)}`);
        const foundKey = await this.http.post('/api/key-v2/get-shadow', data).toPromise();
        console.debug(`${nowToTimeStr()} << /api/key-v2/get-shadow foundKey:${JSON.stringify(foundKey)}`);

        return foundKey['shadow'];
    }

    private async receiveShadowTwo(_keyId: number, ott_hexed): Promise<any>
    {
        const ott_decrypted = await this.cryptoService.decryptOtt(ott_hexed);

        const data = {
            request_id: 1,
            key_id: _keyId,
            ott: ott_decrypted
        };

        console.debug(`${nowToTimeStr()} >> /key/shadow-req/v2 data:${JSON.stringify(data)}`);
        const response = await fetch(`${environment.hyper_id.resourceServer}/key/shadow-req/v2`, {
            method: 'POST',
            body: JSON.stringify(data)
        });
        const reader = response.body.getReader();
        const body = await reader.read();
        // console.log(`<< /key/shadow-req/v2 response.body:${JSON.stringify(body)}`);

        const textDecoder = new TextDecoder();
        const content = JSON.parse(textDecoder.decode(body.value));

        console.debug(`${nowToTimeStr()} << /key/shadow-req/v2 (${_keyId}) : ${JSON.stringify(content)}`);

        const result = <eShadowAckResultV2>content['result'];
        if (result == eShadowAckResultV2.success)
        {
            return content['shadow'];
        }

        throw new Error(`SHADOW-REQUEST: ${eShadowAckResultV2[result]}`);
    }
}
