import { Observable, ReplaySubject } from 'rxjs';
import { ContractInterface, ethers, Signer, Transaction, UnsignedTransaction, utils } from 'ethers';
import { Injector } from '@angular/core';
import { CurrencyApi } from '../api/currency';
import { eEthereumNetworks, NetworksService } from '../networks/networks.service';
import { ProviderFactoryService } from '../provider/provider-factory.service';
import { AppPasswordService } from '../app-password/app-password.service';
import { Wallet } from './wallet.service';
import { parseToFirstNonZero } from '../utils';
import { TransactionDirection, TransactionStatus } from './types';
import { MarketsFactoryService } from '../markets/markets-factory.service';
import { PricesService } from '../prices/prices.service';
import { Erc20ContractFactory } from '../currencies-factory/erc20-contract-factory.service';
import { eBlockChainExplorerFactory } from './explorers/blockchain-explorer';
import { UtilsService } from './utils.service';
import { TransactionResponse } from "@ethersproject/abstract-provider";


import
    {
        ITransactionResponceEx,
        IBlockChainExplorer,
        ITransactionParsed,
        ITransactionFee,
    } from './explorers/types';
import { ePlatforms } from '../../../common/networks';

const mockCurrencies = {
    WEENUS: {
        name: 'Basic Attention Token',
        symbol: 'BAT',
        prevent_market: true,
    },
    IOT: {
        name: 'Astra IOT',
        symbol: 'IOT',
        prevent_market: true,
    },
    jc3: {
        name: 'JCoin3',
        symbol: 'JC3',
        prevent_market: true,
    },
    jc6: {
        name: 'JCoin6',
        symbol: 'JC6',
        prevent_market: true,
    },
    prevent_market: [
        '0x2b46b3a32984520f47a194a52863a77f29ca83e1',
        '0xaff4481d10270f50f203e0763e2597776068cbc5',
        '0x935099adf26ea8f7e84a175c930d814f05b2d637',
    ],
};

const marketData = {
    JASMY: {
        market: 'mxc',
        pair: 'JASMY_USDT',
        currency: 'usd',
    },
};

export interface ICurrency
{
    getAddress(): string;

    getSymbol(): string;

    getFeeSymbol(): string;

    getFeeUnit(): string;

    getName(): string;

    getPlatform(): string;

    getPrice(
        _currency: string
    ): Promise<{ price: string; price_change: string; }>;

    getFeeLimit(_data: any, _type?): Promise<string | any>;

    getFee(_feeLimit: string, _feeAmount: string): string;

    sendTransaction(_tx: ethers.providers.TransactionRequest, _txInfo: { symbol?: string, value: string, to?: string, icon?: string }): Promise<any>;

    checkTransaction(_to: string, _value: string): Promise<any>;

    getBalance(): Promise<any>;

    getBaseCurrencyBalance(): Promise<any>;

    populateTransaction(
        _transaction: ethers.providers.TransactionRequest,
        _transfer?: boolean
    ): Promise<any>;

    preparePrice(_price: string): { price: any; };

    prepareFee(_price: string, _limit: string): { price: any; limit: any; };

    estimateFeePrice(_to: string, _value: string, _transfer?: boolean): Promise<string>;

    getTransactionByHash(_hash: string): Promise<ITransactionResponceEx>;

    parseTransaction(_txData: ITransactionResponceEx, parseData?: boolean): Promise<ITransactionParsed>;

    getHistory(): Promise<ITransactionResponceEx[]>;

    getTransactionDirection(_tx: ITransactionResponceEx): TransactionDirection;

    getTransactionStatus(_tx: ITransactionResponceEx): TransactionStatus;

    getWallet(): Wallet;

    getContractAddress(): string | null;

    checkFeeAvailability(_feeData: any): Promise<{ result: boolean, amount?: string; }>;

    isFeeRequired(): boolean;
}

export abstract class Currency implements ICurrency
{
    protected name: string;
    protected symbol: string;
    protected baseSymbol: string;
    protected platform: string;
    protected currencyMarketData: any;
    protected address: string;
    protected provider: ethers.providers.BaseProvider;
    protected currencyApi: CurrencyApi;
    protected abi: string;
    protected history: ITransactionResponceEx[] = [];
    protected wallet: Wallet;
    protected networksService: NetworksService;
    protected providerFactoryService: ProviderFactoryService;
    protected passwordService: AppPasswordService;
    protected pricesService: PricesService;

    protected setupStateSubject: ReplaySubject<boolean> = new ReplaySubject(1);

    constructor(
        protected injector: Injector,
        _wallet: Wallet,
        _address: string,
        _symbol: string
    )
    {
        this.wallet = _wallet;
        this.symbol = _symbol;
        this.address = _address;
        this.currencyApi = this.injector.get(CurrencyApi);
        this.networksService = this.injector.get(NetworksService);
        this.providerFactoryService = this.injector.get(ProviderFactoryService);
        this.passwordService = this.injector.get(AppPasswordService);
        this.pricesService = this.injector.get(PricesService);

        this.init();
    }
    public abstract getContractAddress(): string | null;

    public getAddress(): string
    {
        return this.address;
    }

    public getSymbol(): string
    {
        return this.symbol;
    }

    public getFeeSymbol(): string
    {
        return this.baseSymbol;
    }

    public getName(): string
    {
        return this.name;
    }

    public getPlatform(): string
    {
        return this.platform;
    }

    protected abstract init(): Promise<any>;

    public abstract getFeeUnit(): string;

    public abstract getPrice(
        _currency: string
    ): Promise<{ price: string; price_change: string; }>;

    get onSetup(): Observable<boolean>
    {
        return this.setupStateSubject.asObservable();
    }

    public abstract sendTransaction(
        _tx: ethers.providers.TransactionRequest,
        _txInfo: { symbol?: string, value: string, to?: string; }
    ): Promise<any>;

    public abstract checkTransaction(_to: string, _value: string): Promise<any>;

    public abstract getBalance(): Promise<any>;

    public abstract getBaseCurrencyBalance(): Promise<any>;

    public abstract populateTransaction(
        _transaction: UnsignedTransaction,
        _transfer?: boolean
    ): Promise<any>;

    public abstract preparePrice(_price: string): { price: any; };

    public abstract prepareFee(
        _price: string,
        _limit: string
    ): { price: any; limit: any; };

    public abstract getTransactionByHash(_hash: string): Promise<ITransactionResponceEx>;

    public abstract getHistory(): Promise<ITransactionResponceEx[]>;

    public abstract getTransactionDirection(_tx: ITransactionResponceEx): TransactionDirection;

    public abstract getTransactionStatus(_tx: ITransactionResponceEx): TransactionStatus;

    public abstract getFeeLimit(_type?): Promise<string | any>;

    public abstract getFee(_feeLimit: string, _feeAmount: string): string;

    public abstract getWallet(): Wallet;

    public abstract parseTransaction(_txData: ITransactionResponceEx, parseData?: boolean): Promise<ITransactionParsed>;

    public abstract checkFeeAvailability(_feeData: any): Promise<{ result: boolean, amount?: string; }>;

    public abstract isFeeRequired(): boolean;

    public abstract estimateFeePrice(to: string, _value: string, _transfer?: boolean): Promise<string>;
}

export enum eEtherCurrenciesSymbols
{
    ETH = 'ETH',
}

export interface IEthereumContract
{
    address: string;
    abi?: ContractInterface;
}

export function EtherCurrenciesFactory(
    _symbol: string,
    injector: Injector,
    _wallet: Wallet,
    _address: string,
    _contractData?: IEthereumContract
): Currency
{
    switch (_symbol)
    {
        case eEtherCurrenciesSymbols.ETH:
            return new EtherCurrency(injector, _wallet, _address);
            break;
        default:
            return new EtherContractCurrency(
                injector,
                _wallet,
                _address,
                _contractData
            );
    }
}

export class EtherCurrency extends Currency
{
    protected name = 'Ethereum';
    protected signer: Signer;
    protected platform = 'Ethereum';

    protected etherUtils: UtilsService;

    constructor(
        protected injector: Injector,
        _wallet: Wallet,
        _address: string
    )
    {
        super(injector, _wallet, _address, 'ETH');

        this.etherUtils = this.injector.get(UtilsService);

        this.baseSymbol = 'ETH';
    }

    public getContractAddress(): string
    {
        return null;
    }

    protected async init()
    {
        this.provider = await this.providerFactoryService.getProvider(
            'ethereum'
        );

        this.signer = new ethers.VoidSigner(this.address, this.provider as any);

        this.setupStateSubject.next(true);
    }

    public getFeeUnit(): string
    {
        return 'gwei';
    }

    public async getTransactionByHash(_hash: string)
    {
        return await <ITransactionResponceEx><any>this.provider.getTransaction(_hash);
    }

    public async sendTransaction(
        _tx: ethers.providers.TransactionRequest,
        _txInfo: { symbol?: string, value: string, to?: string; }
    ): Promise<any>
    {

        const txInfo = { ..._txInfo, symbol: _txInfo.symbol || this.symbol };

        // TODO: remove on kovan move to EIP 1559
        // if (await this.networksService.getNetwork('ethereum') === 'kovan')
        // {
        //     delete _tx.maxFeePerGas;
        //     delete _tx.maxPriorityFeePerGas;
        // }
        // else
        // {
        //     delete _tx.gasPrice;
        // }

        if (_tx.gasPrice)
        {
            delete _tx.gasPrice;
        }

        delete _tx.maxFeePerGas;
        // delete _tx.maxPriorityFeePerGas;

        try
        {

            return await this.wallet.sendTransaction(_tx, txInfo);
        } catch (error)
        {
            console.error(error);
            throw error;
        }
    }

    public async checkTransaction(_to: string, _value: string): Promise<any>
    {
        const txRequest = this.signer.checkTransaction({
            to: _to,
            value: ethers.utils.parseEther(_value.toString()),
        });

        return txRequest;
    }

    public async getBalance(): Promise<any>
    {
        return await this.getBaseCurrencyBalance();
    }

    public async getBaseCurrencyBalance(): Promise<any>
    {
        const balance = await this.signer.getBalance();

        return utils.formatEther(balance);
    }

    public preparePrice(_price: string): { price: any; }
    {
        return {
            price: utils.parseUnits(_price.split('.')[0], 'gwei'),
        };
    }

    public prepareFee(
        _price: string,
        _limit: string
    ): { price: any; limit: any; }
    {
        return {
            price: utils.parseUnits(_price.split('.')[0], 'gwei'),
            limit: utils.parseUnits(_limit.split('.')[0], 'wei'),
        };
    }

    public async populateTransaction(
        _transaction: UnsignedTransaction,
        _transfer?: boolean
    ): Promise<any>
    {
        const gas = await this.signer.estimateGas(_transaction);

        // TODO: remove on kovan move to EIP 1559
        // if (await this.networksService.getNetwork('ethereum') === 'kovan')
        // {
        //     delete _transaction.maxFeePerGas;
        //     delete _transaction.maxPriorityFeePerGas;
        // }
        // else
        // {
        //     delete _transaction.gasPrice;
        // }

        if (_transaction.gasPrice)
        {
            delete _transaction.gasPrice;
        }

        delete _transaction.maxFeePerGas;

        const tx = await this.signer.populateTransaction({..._transaction, gasLimit: gas});

        return tx;
    }

    public async getPrice(
        _currency = 'usd'
    ): Promise<{ price: string; price_change: string; }>
    {
        return await this.pricesService.getPrice('ETH', _currency, ePlatforms.ETHEREUM);
        // const marketData = await this.getMarketData();
        //
        // if (!marketData) {
        //   return {
        //     price: '0',
        //     price_change: null
        //   };
        // }
        //
        // const result = {
        //   price: marketData['market_data']['current_price'][_currency],
        //   price_change: marketData['market_data']['price_change_percentage_24h_in_currency'][_currency]
        // };
        //
        // return result;
    }

    public async getHistory(): Promise<ITransactionResponceEx[]>
    {
        let history = await this.wallet.getHistory();

        history = history.filter((_tx) =>
        {
            if (_tx.data) return _tx.data === '0x';

            return true;
        });

        this.history = history;

        return history;
    }

    public getTransactionDirection(_tx: ITransactionResponceEx): TransactionDirection
    {
        const address = this.address.toLowerCase();
        const from = _tx.from.toLowerCase();
        const to = _tx.to.toLowerCase();

        if (address === to) return 'to';

        if (address === from) return 'from';

        return 'none';
    }

    public getTransactionStatus(_tx: ITransactionResponceEx): TransactionStatus
    {
        if (_tx.confirmations > 0)
        {
            return 'success';
        }

        return 'processing';
    }

    public async getFeeLimit(
        _data: { to: string; value: string; },
        _type = 'string'
    ): Promise<string | any>
    {
        const txData = {..._data, from: this.address, value: ethers.utils.parseUnits(_data.value)};
        const gas = await this.signer.estimateGas(txData);

        txData['gasLimit'] = gas;

        const tx = await this.signer.populateTransaction(txData);

        if (_type === 'string')
        {
            return ethers.utils.formatUnits(tx.gasLimit, 'wei');
        }
        if (_type === 'BigNumber')
        {
            return tx.gasLimit;
        }

        return tx.gasLimit;
    }

    //todo remove me
    public getFee(_feeLimit: string, _feeAmount: string): string
    {
        const feeBigNumber = ethers.utils.parseUnits(_feeAmount, 'gwei');
        const feeEth = ethers.utils.formatEther(feeBigNumber);

        return parseToFirstNonZero(Number(feeEth) * Number(_feeLimit), 4);
    }

    protected async getMarketData()
    {
        if (!this.currencyMarketData)
        {
            const response = await fetch(
                'https://api.coingecko.com/api/v3/coins/ethereum?tickers=false&community_data=false&developer_data=false',
                {
                    headers: {
                        'Content-type': 'application/json',
                    },
                }
            );

            const data = await response.json();

            if (Boolean(data.error))
            {
                return null;
            }

            this.currencyMarketData = data;
        }

        return this.currencyMarketData;
    }

    public async parseTransaction(_tx: ITransactionResponceEx)
    {
        //calculate fee in USD
        const value = ethers.utils.formatEther(_tx.value);
        const price = (await this.pricesService.getPrice('ETH', 'usd', ePlatforms.ETHEREUM)).price;
        const valuePrice = Number(value) * Number(price);

        let feeData: ITransactionFee = <any>{};

        const gasPrice = _tx.gasPrice || _tx.maxFeePerGas;

        if (gasPrice)
        {

            //gasUsed can be empty, use gas limit instead.
            var gasUsed = _tx.gasUsed ?? _tx.gasLimit;

            if (!ethers.BigNumber.isBigNumber(gasUsed)) gasUsed = ethers.BigNumber.from(gasUsed);

            let feeBN = gasUsed.mul(gasPrice);
            let feeString = parseToFirstNonZero(ethers.utils.formatEther(feeBN), 4);

            const feeAmount = ethers.utils.formatUnits(gasPrice, 'gwei');
            const fiatFee = parseToFirstNonZero(Number(price) * Number(feeString), 2);
            feeData = {
                feeAmount: feeAmount,
                fee: feeString,
                format: 'gwei',
                fiatFee: fiatFee,
            };
        }

        return {
            ..._tx,
            amount: {
                value: ethers.utils.formatEther(_tx.value),
                price: valuePrice,
            },
            fee: feeData,
        };
    }

    public getWallet(): Wallet
    {
        return this.wallet;
    }

    public async checkFeeAvailability(_feeData: number): Promise<{ result: boolean, amount?: string; }>
    {
        const oracleFee = await this.etherUtils.getFee();
        const gasLimit = _feeData;

        const minEth = (Number(oracleFee.propose.amount) * gasLimit) / 10 ** 9;

        const balanceBN = await this.signer.getBalance();

        const balance = utils.formatEther(balanceBN);

        if (Number(balance) < minEth)
        {
            return { result: false, amount: `${minEth} ETH` };
        }

        return { result: true };
    }

    public isFeeRequired(): boolean
    {
        return true;
    }

    public async estimateFeePrice(to: string, _value: string, _transfer: boolean = true): Promise<string>
    {
        const checkedTx = await this.checkTransaction(to, _value);
        const populatedTx = await this.populateTransaction(checkedTx, _transfer);

        return ethers.utils.formatUnits(populatedTx.gasPrice || populatedTx.maxFeePerGas, 'gwei');
    }
}

export class EtherContractCurrency extends EtherCurrency
{
    protected name = '';
    private contract: ethers.Contract;

    private contractData;
    private marketService: MarketsFactoryService;
    private contractFactory: Erc20ContractFactory;

    private readonly explorer: IBlockChainExplorer;

    constructor(
        protected injector: Injector,
        _wallet: Wallet,
        _address: string,
        _contractData: IEthereumContract,
    )
    {
        super(injector, _wallet, _address);

        this.contractData = _contractData;

        this.explorer = eBlockChainExplorerFactory.CreateEtherExplorer(injector.get(ProviderFactoryService));
        this.marketService = this.injector.get(MarketsFactoryService);
    }

    protected async init()
    {
        this.contractFactory = this.injector.get(Erc20ContractFactory);
        this.provider = await this.providerFactoryService.getProvider(
            'ethereum'
        );

        this.signer = new ethers.VoidSigner(this.address, this.provider as any);

        await this.generateContract(this.contractData);

        this.setupStateSubject.next(true);
    }

    public getContractAddress(): string | null
    {
        return this.contract.address;
    }

    public getContractInstance(): ethers.Contract
    {
        return this.contract;
    }

    public async checkTransaction(_to: string, _value: string): Promise<any>
    {
        const decimals = await this.contract.decimals();
        const txRequest = this.signer.checkTransaction({
            to: _to,
            value: ethers.utils.parseUnits(_value, decimals),
        });

        return txRequest;
    }

    public async getBalance(): Promise<any>
    {
        const balance = await this.contract.balanceOf(this.address);

        const decimals = await this.contract.decimals();
        return utils.formatUnits(balance, decimals);
    }

    public async getPrice(
        _currency = 'usd'
    ): Promise<{
        price: string;
        price_change: string;
    }>
    {
        if (!this.contract || !this.contract.address)
        {
            return {
                price: '0',
                price_change: '0',
            };
        }

        return await this.pricesService.getPrice(
            this.symbol.toUpperCase(),
            _currency,
            ePlatforms.ETHEREUM,
            this.contract.address.toLowerCase()
        );

        // const result = {
        //   price: '0',
        //   price_change: '0'
        // };
        //
        // const marketData = await this.getMarketData();
        //
        // if (Boolean(marketData)) {
        //   result.price = marketData['market_data']['current_price'][_currency];
        //   result.price_change = marketData['market_data']['price_change_percentage_24h_in_currency'][_currency];
        // } else {
        //   const prices = JSON.parse(localStorage.getItem('prices'));
        //   const contractPrices = prices[this.symbol] || prices['ANY'];
        //
        //   result.price = contractPrices[_currency] || contractPrices['any'];
        //   result.price_change = contractPrices['change_percentage'];
        // }
        //
        // return result;
    }

    protected async getMarketData()
    {
        if (!this.contract || !this.contract.address)
        {
            return (this.currencyMarketData = false);
        }

        if (
            Boolean(
                mockCurrencies['prevent_market'].includes(
                    this.contract.address.toLowerCase()
                )
            )
        )
        {
            return (this.currencyMarketData = false);
        }

        const marketInfo = marketData[this.symbol.toUpperCase()];

        try
        {
            if (
                !marketInfo &&
                !this.currencyMarketData &&
                this.currencyMarketData !== false
            )
            {
                const response = await fetch(
                    `https://api.coingecko.com/api/v3/coins/ethereum/contract/${this.contract.address}`,
                    {
                        headers: {
                            'Content-type': 'application/json',
                        },
                    }
                );

                const data = await response.json();
                if (Boolean(data.error))
                {
                    return (this.currencyMarketData = false);
                } else
                {
                    this.currencyMarketData = data;
                }
            }

            if (marketInfo != null)
            {
                const market = await this.marketService.getMarket(
                    marketInfo.market
                );

                if (!market)
                {
                    return (this.currencyMarketData = false);
                }

                const prices = await market.getTicker(marketInfo.pair);

                this.currencyMarketData = {
                    market_data: {
                        current_price: {
                            [marketInfo.currency]: prices.sell,
                        },
                        price_change_percentage_24h_in_currency: {
                            [marketInfo.currency]: null,
                        },
                    },
                };
            }
        } catch (error)
        {
            this.currencyMarketData = false;
        }

        return this.currencyMarketData;
    }

    public async populateTransaction(
        _transaction: UnsignedTransaction,
        _transfer = true
    ): Promise<any>
    {
        let unsignedTx = _transaction;
        if (this.contract != null && _transfer)
        {
            unsignedTx = await this.contract.populateTransaction.transfer(
                _transaction.to,
                _transaction.value
            );
        }

        let gas = _transaction.gasLimit;

        if (!gas)
        {
            gas = await this.signer.estimateGas(unsignedTx);
        }
        
        // TODO: remove on kovan move to EIP 1559
        // if (await this.networksService.getNetwork('ethereum') === 'kovan')
        // {
        //     delete unsignedTx.maxFeePerGas;
        //     delete unsignedTx.maxPriorityFeePerGas;
        // }
        // else
        // {
            delete unsignedTx.gasPrice;
            delete unsignedTx.maxFeePerGas;
        // }

        const tx = <any>await this.signer.populateTransaction({...unsignedTx, gasLimit: gas});

        if (!tx.value)
        {
            tx.value = ethers.utils.parseEther('0');
        }

        return tx;
    }

    public async parseTransaction(_tx: ITransactionResponceEx, _parseData = true): Promise<ITransactionParsed>
    {
        const tx = { ..._tx };

        if (!!tx.data && _parseData)
        {

            const parsedTxData = await this.parseTxData(tx);

            tx.value =
                parsedTxData.tokens ||
                parsedTxData.value ||
                parsedTxData.amount ||
                parsedTxData._value ||
                tx.value;
            tx.to = parsedTxData.to || parsedTxData._to || parsedTxData.recipient || tx.to;
        }

        const txRes = await super.parseTransaction(tx);

        if (!!this.contract && !!this.contract.decimals && !!this.symbol)
        {
            let decimals = 18;
            
            try
            {
                decimals = await this.contract.decimals();
            }
            catch (err){
                console.error(err);
            }

            txRes.amount.value = ethers.utils.formatUnits(tx.value, decimals);

            const price = (
                await this.pricesService.getPrice(this.symbol, 'usd', ePlatforms.ETHEREUM)
            ).price;
            const valuePrice = Number(txRes.amount.value) * Number(price);

            txRes.amount.price = valuePrice;
        }

        return txRes;
    }

    public async parseTxData(_txData: ITransactionResponceEx): Promise<any>
    {
        if (this.abi != null)
        {
            try
            {
                const contractInterface = new ethers.utils.Interface(this.abi);

                const data = contractInterface.parseTransaction(<any>_txData);

                return data.args;
            } catch (error)
            {
                console.error(error);

                return _txData;
            }
        }

        return _txData;
    }

    public async getTransactionByHash(_hash: string): Promise<ITransactionResponceEx>
    {
        const tx = await super.getTransactionByHash(_hash);

        const parsedTxData = await this.parseTxData(tx);

        tx.value = parsedTxData.tokens || parsedTxData.value || parsedTxData.amount;
        tx.to = parsedTxData.to || parsedTxData._to || parsedTxData.recipient;

        return tx;
    }

    public async getFeeLimit(
        _data: { to: string; value: string; },
        _type = 'string'
    ): Promise<string | any>
    {
        const decimals = await this.contract.decimals();
        const contractPopulatedTx = await this.contract.populateTransaction.transfer(
            _data.to,
            ethers.utils.parseUnits(_data.value, decimals)
        );

        const gas = await this.signer.estimateGas(contractPopulatedTx);

        contractPopulatedTx['gasLimit'] = gas;

        let tx;
        try
        {
            tx = await this.signer.populateTransaction(contractPopulatedTx);
        } catch (e)
        {
            console.error(e)
            throw e;
        }

        if (_type === 'string')
        {
            return (Number(ethers.utils.formatUnits(tx.gasLimit, 'wei')) * 1.3).toString()
        }
        if (_type === 'BigNumber')
        {
            return tx.gasLimit;
        }

        return tx.gasLimit;
    }

    public async getHistory(): Promise<ITransactionResponceEx[]>
    {
        // TODO: add-wallets-page pages
        let history = await this.explorer.GetHistory(
            this.address,
            this.contract.address,
            { page: 1, maxPageSize: 100 });

        this.history = history;

        return history;
    }

    private async generateContract(_contractData: IEthereumContract)
    {

        const network = await this.networksService.getNetwork('ethereum');
        const { contract, symbol, name, abi } = await this.contractFactory.create(
            _contractData.address,
            eEthereumNetworks[network],
            _contractData.abi
        );

        this.contract = contract;
        this.symbol = symbol;
        this.name = name;
        this.abi = abi;
    }
}
