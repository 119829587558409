import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {eAuthenticationType} from 'src/common/auth_types';
import {nowToTimeStr} from 'src/common/index'
import {ITransactionInfo} from 'src/common/action-interfaces';
import {eMfaTransactionStartV2Result, eStartAuthResult} from 'src/common/request_enums';

@Injectable({
    providedIn: 'root'
})
export class SessionAuthService
{
    constructor(
        private http: HttpClient
    ){
    }

    private isAuthTypeStored(){
        const authTypeFromStorage = localStorage.getItem('auth_type');
        if(typeof authTypeFromStorage !== 'undefined' && authTypeFromStorage){
            return true;
        }

        return false;
    }

    private getAuthTypeStored(){
        const authTypeFromStorage = localStorage.getItem('auth_type');
        if(typeof authTypeFromStorage !== 'undefined' && authTypeFromStorage){
            return <eAuthenticationType>+authTypeFromStorage;
        }
    }

    private updateStorage(authType: eAuthenticationType){
        localStorage.setItem('auth_type', `${authType}`);
    }

    private async requestAuthType(): Promise<eAuthenticationType>
    {
        const responce = await this.http.get(`/api/session/auth-type`).toPromise();
        const authType = <eAuthenticationType>responce['auth_type'];
        this.updateStorage(authType);

        console.log(`${nowToTimeStr()} << /api/session/auth-type < authType:${eAuthenticationType[authType]}`);
        return authType;
    }

    public async getAuthType(forceRequest: boolean = false): Promise<eAuthenticationType>
    {
        let authType:eAuthenticationType;
        if(!forceRequest && this.isAuthTypeStored()){
          authType = this.getAuthTypeStored();
        }else{
          authType = await this.requestAuthType();
        }
        return authType;
    }

    public async setAuthType(authType: eAuthenticationType): Promise<boolean>
    {
        console.log(`${nowToTimeStr()} >> /api/session/auth-type: ${eAuthenticationType[authType]}`);
        const response = await this.http.post(`/api/session/auth-type`, {
            auth_type: authType
        }).toPromise();

        const result = <boolean>response['result'];
        this.updateStorage(authType);

        console.log(`${nowToTimeStr()} << /api/session/auth-type(authType:${eAuthenticationType[authType]}) > ${result}`);
        return result;
    }

    public async startVerificate(info):
        Promise<{start_auth_result: eStartAuthResult, transaction: ITransactionInfo}>
    {
        console.debug(`${nowToTimeStr()} >> /api/action/transaction/start ${JSON.stringify(info)}`);
        const response = await this.http.post('/api/action/transaction/start', info).toPromise();
        const startAuthResult = <eStartAuthResult>response['start_auth_result'];
        console.debug(`${nowToTimeStr()} << /api/action/transaction/start { start_auth_result:${eStartAuthResult[startAuthResult]} }`);

        if(startAuthResult == eStartAuthResult.not_required){
            return {
                start_auth_result: startAuthResult,
                transaction: null
            };
        }

        const data = response['data'];
        const result = <eMfaTransactionStartV2Result>data['result'];
        if (result !== eMfaTransactionStartV2Result.success){
            throw new Error(`TRANSACTION_START_FAILED: ${eMfaTransactionStartV2Result[result]}`);
        }

        return {
            start_auth_result: startAuthResult,
            transaction: <ITransactionInfo>
            {
                transaction_id: <number>data['transaction_id'],
                code:<string>data['code']
            }
        };
    }
}
