import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BackComponent } from './back/back.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArrowComponent } from './arrow/arrow.component';
import { ChartComponent } from './chart/chart.component';
import { AddressShorterPipe } from './pipes/address-shortender.pipe';
import { DividerComponent } from './divider/divider.component';
import { CurrencyListComponent } from './currency-list/currency-list.component';
import { CopyComponent } from './copy/copy.component';
import { TransactionFeeComponent } from './transaction-fee/transaction-fee.component';
import { QrScannerComponent } from './qr-scanner/qr-scanner.component';
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { SearchBoxComponent } from './search-box/search-box.component';
import { CurrencyFilterPipe } from '../private/crypto-wallet/currencies-filter/currency-filter-pipeline';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { AlertComponent } from './alert/alert.component';
import { ShareComponent } from './share/share.component';

@NgModule({
    declarations: [
        BackComponent,
        ArrowComponent,
        ChartComponent,
        AddressShorterPipe,
        DividerComponent,
        CurrencyListComponent,
        CopyComponent,
        ShareComponent,
        TransactionFeeComponent,
        QrScannerComponent,
        ToggleSwitchComponent,
        SearchBoxComponent,
        CurrencyFilterPipe,
        CustomDatePipe,
        AlertComponent
    ],
    imports: [CommonModule, TranslateModule, FormsModule],
    exports: [
        TranslateModule,
        BackComponent,
        ReactiveFormsModule,
        ArrowComponent,
        ChartComponent,
        AddressShorterPipe,
        DividerComponent,
        CurrencyListComponent,
        CopyComponent,
        ShareComponent,
        TransactionFeeComponent,
        QrScannerComponent,
        ToggleSwitchComponent,
        SearchBoxComponent,
        CurrencyFilterPipe,
        CustomDatePipe,
        AlertComponent
    ],
})
export class SharedModule {}
