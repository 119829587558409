export async function sleep(ms:number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function parseAmount(_amount: string, _numbers = 7) {
  const balanceArr = _amount.split('.');

  if (!balanceArr[1]) {
    return _amount;
  }

  balanceArr[1] = balanceArr[1].slice(0, _numbers);

  return balanceArr.join('.');
}

export function parseToFirstNonZero2(_amount: string | number, _minNumbers = 2, _maxNumbers = 7) {
  const amountString = toFixed(Number(_amount), _maxNumbers)//.toFixed(10);
  const balanceArr = amountString.split('.');

  if (!balanceArr[1]) {
    return `${_amount}.00`;
  }

  if (balanceArr[1].length < _minNumbers) {
    for (let i = 0; i < (_minNumbers - balanceArr[1].length); i++) {
      balanceArr[1] += '0';
    }
  }

  // let revertIndex = balanceArr[1].split('').reverse().join('').search(/[1-9]/g);
  let index = balanceArr[1].search(/[1-9]/g) + 1;//revertIndex != -1 ? balanceArr[1].length - revertIndex : 0;

  if (index < _minNumbers) {
    index = _minNumbers;
  }
  if (index > _maxNumbers) {
    index = _maxNumbers;
  }

  balanceArr[1] = balanceArr[1].slice(0, index);

  return balanceArr.join('.');
}

export function parseToFirstNonZero(_value: string | number, _minNumbers = 2, _maxNumbers = 8): string
{
    let maxNumbers = _maxNumbers;

    if (typeof _value === "string") maxNumbers = _value.split('.')[1]?.length || _maxNumbers;

    if (maxNumbers > _maxNumbers) maxNumbers = _maxNumbers;
    if (maxNumbers < _minNumbers) maxNumbers = _minNumbers;

    if (maxNumbers === 0) return parseInt(_value.toString()).toString();

    let result = toFixed(Number(_value), maxNumbers);

    if (!result.includes('.')) return result + '.00';

    while (result.endsWith('0') && result.split('.')[1].length > _minNumbers)
    {
        result = result.slice(0, -1);
    }

    if (result.endsWith('.')) result = result.slice(0, -1);

    return <string>result.toString();
}

export function trimFloatNumberZeros(_amount: string | number, _maxNumbers = 8) {
    const amountString = Number(_amount).toFixed(10);
    const balanceArr = amountString.split('.');

    if (_amount.toString().indexOf('.') === -1) {
      return `${_amount}`;
    }

    let revertIndex = balanceArr[1].split('').reverse().join('').search(/[1-9]/g);
    let index = revertIndex != -1 ? balanceArr[1].length - revertIndex : 0;

    if (index > _maxNumbers) {
      index = _maxNumbers;
    }

    balanceArr[1] = balanceArr[1].slice(0, index);

    const result = balanceArr.join('.');

    if (result.endsWith('.')) return result.slice(0, -1);

    return result;
  }

export function stringToUint8Array(_str: string): Uint8Array {

  const ret = new Uint8Array(_str.length);

  for (var i = 0; i < _str.length; i++) {
    ret[i] = _str.charCodeAt(i);
  }
  return ret;
};

export function uint8ArrayToString(_binArray:Uint8Array): string {
  var str = '';
  for (var i = 0; i < _binArray.length; i++) {
    str += String.fromCharCode(_binArray[i]);
  }
  return str
}

export function toFixed(num, fixed): string
{
    var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    return num.toFixed(fixed).match(re)[0];
}