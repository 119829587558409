import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { EventTransportService } from '../event-transport/event-transport.service';
import { debug } from 'console';

@Injectable({
  providedIn: 'root'
})
export class StorageService
{

  constructor(
    private dbService: NgxIndexedDBService,
    private eventTransport: EventTransportService
  )
  {
      dbService.getAll(environment.idbStoreName).toPromise()
        .catch(e =>
        {
          if (e.includes('objectStore does not exists'))
          {
            this.recoverDB();
          }
        })
  }

  public async get(_key: string): Promise<any>
  {
    try
    {
      return await this.getObservable(_key).pipe(take(1)).toPromise();
    }
    catch (err)
    {
      if (err == 'IndexedDB error: InvalidStateError: A mutation operation was attempted on a database that did not allow mutations.')
      {
        this.eventTransport.send('show-index-db-alert');
      }
      throw err;
    }
  }

  public async del(_key: string): Promise<any>
  {
    try
    {
      return await this.delObservable(_key).pipe(take(1)).toPromise();
    }
    catch (err)
    {
      if (err == 'IndexedDB error: InvalidStateError: A mutation operation was attempted on a database that did not allow mutations.')
      {
        this.eventTransport.send('show-index-db-alert');
      }
      throw err;
    }
  }

  public async set(_key: string, _data: any): Promise<any>
  {
    try
    {
      await this.setObservable(_key, _data).pipe(take(1)).toPromise();
    }
    catch (err)
    {
      if (err == 'IndexedDB error: InvalidStateError: A mutation operation was attempted on a database that did not allow mutations.')
      {
        this.eventTransport.send('show-index-db-alert');
      }
      throw err;
    }
  }

  private getObservable(_key: string): Observable<any>
  {
    return this.dbService.getByKey(environment.idbStoreName, _key).pipe(
      map(_result =>
      {
        if (_result)
        {
          return _result['data'];
        }

        return null;
      })
    );
  }

  private delObservable(_key: string): Observable<any>
  {
    return this.dbService.delete(environment.idbStoreName, _key);
  }

  private setObservable(_key: string, _data: any): Observable<any>
  {
    return this.dbService.update(environment.idbStoreName, { key: _key, data: _data });
  }

  private async recoverDB()
  {
    this.dbService.deleteDatabase();
    location.reload();
  }
}
