import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import WalletConnect from '@walletconnect/client';
import { providers } from 'ethers';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ePlatforms } from '../../../common/networks';
import { CryptoWalletService } from '../crypto-wallet/crypto-wallet.service';
import { ICryptoWallet } from '../crypto-wallet/type';
import { EventTransportService } from '../event-transport/event-transport.service';
import { BscNetworks, NetworksService } from '../networks/networks.service';
import { BSCContractCurrency, BSCCurrency } from '../wallets/bsc-currency';
import {
    EtherContractCurrency,
    EtherCurrency,
    ICurrency
} from '../wallets/currency';

export enum eWalletConnectEvent
{
    SESSION_REQUEST,
    SESSION_CLOSED,
    SESSION_CONNECTED,
    SESSION_CONNECT_FAIL
}

export enum eState
{
    DISCONNECTED,
    CREATING_SESSION,
    WAIT_APPROVE,
    CONNECTING,
    CONNECTED,
}

const CONNECT_TIMEOUT_MS = 20_000;

@Injectable({
    providedIn: 'root',
})
export class WalletConnectService {
    private connector: WalletConnect;
    private eventSubject: Subject<{ event: eWalletConnectEvent; data: any }> = new Subject();

    private timeoutHandle:any;

    private state:eState;

    constructor(
        private networkService: NetworksService,
        @Inject(PLATFORM_ID) private platformId: any,
        private cryptoWalletService: CryptoWalletService,
        private router: Router,
        private injector: Injector,
        private eventTransport: EventTransportService
    ) {
        this.state = eState.DISCONNECTED;
        this.restoreSession();
    }

    public getConnector(): WalletConnect {
        return this.connector;
    }

    public async connect(_uri: string): Promise<WalletConnect>
    {
        this.state = eState.CREATING_SESSION;

        this.timeoutHandle = setTimeout(() =>
        {
            this.state = eState.DISCONNECTED;

            this.eventSubject.next({
                event: eWalletConnectEvent.SESSION_CONNECT_FAIL,
                data: {},
            });
        }, CONNECT_TIMEOUT_MS);

        try
        {
            this.connector = new WalletConnect({ uri: _uri });

            this.subscribeToEvents();

            if (!this.connector.connected)
            {
                // const networkName = await this.networkService.getNetwork(
                //     'ethereum'
                // );
                // const network = providers.getNetwork(networkName);

                await this.connector.createSession();
            }
        }
        catch (e)
        {
            this.state = eState.DISCONNECTED;

            this.ClearConnectTimeout();
            throw e;
        }

        return this.connector;
    }

    public async approveSession(_address: string, _platform: string): Promise<WalletConnect>
    {
        this.state = eState.CONNECTING;

        const networkName   = await this.networkService.getNetwork(<any>_platform.toLowerCase());
        let network         = providers.getNetwork(networkName);

        if (_platform.toLowerCase() === 'bsc')
        {
            network = BscNetworks[networkName];
        }

        if (this.connector)
        {
            try
            {
                await this.connector.approveSession({
                    chainId: network.chainId,
                    accounts: [_address],
                });
            }
            catch (e)
            {
                //reproduced Error: Session currently connected here
                console.error(e);
            }
        }

        return this.connector;
    }

    public rejectSession()
    {
        this.state = eState.DISCONNECTED;

        if (this.connector && this.connector.connected)
        {
            this.connector.rejectSession();
        }

        this.connector = null;
    }

    public killSession()
    {
        this.state = eState.DISCONNECTED;

        if (this.connector)
        {
            this.connector.killSession();
            this.connector = null;
        }
    }

    public GetState()
    {
        return this.state;
    }

    get events(): Observable<{ event: eWalletConnectEvent; data: any }>
    {
        return this.eventSubject.asObservable();
    }

    //https://github.com/WalletConnect/walletconnect-monorepo/issues/340
    //There is currently no way to remove event listeners from the WalletConnect Connector instance.
    //There should be the .off() counterpart to .on().
    private requestCounter = 0;

    private subscribeToEvents()
    {
        if (!this.connector) return;

        let requestCounter = ++this.requestCounter;

        this.connector.on('connect', (error, payload) =>
        {
            if (requestCounter != this.requestCounter
                || (this.state != eState.CONNECTING && this.state != eState.CREATING_SESSION))
            {
                console.log("dispatched obsoleted event");
                return;
            }

            this.ClearConnectTimeout();

            console.log("connect");

            if (this.IsValidConnectSettings())
            {
                this.state = eState.CONNECTED;

                this.eventSubject.next({
                    event: eWalletConnectEvent.SESSION_CONNECTED,
                    data: {},
                });
            }
            else
            {
                console.warn("not valid settings");

                localStorage.removeItem('walletconnect');

                this.state = eState.DISCONNECTED;

                this.eventSubject.next({
                    event: eWalletConnectEvent.SESSION_CONNECT_FAIL,
                    data: {},
                });

                this.killSession();
            }
        });

        this.connector.on('session_request', (error, payload) =>
        {
            if (requestCounter != this.requestCounter || this.state != eState.CREATING_SESSION)
            {
                console.log("dispatched obsoleted event");
                return;
            }

            this.ClearConnectTimeout();

            if (error)
            {
                console.error(error);
                throw error;
            }

            this.state = eState.WAIT_APPROVE;

            const { peerMeta } = payload.params[0];

            this.eventSubject.next({
                event: eWalletConnectEvent.SESSION_REQUEST,
                data: peerMeta,
            });
        });

        this.connector.on('call_request', async (error, payload) =>
        {
            if (requestCounter != this.requestCounter || this.state != eState.CONNECTED)
            {
                console.log("dispatched obsoleted event");
                return;
            }

            if (error)
            {
                console.error(error);
                throw error;
            }

            if (payload.method === 'eth_sendTransaction')
            {
                this.sendTx(payload.id, payload.params[0]);
            }
            else
            {
                this.connector.rejectRequest({
                    id: payload.id,
                    error: {
                        message: `No support ${payload.method}`,
                    },
                });
            }
        });

        this.connector.on('disconnect', (error, payload) =>
        {
            if (requestCounter != this.requestCounter)
            {
                console.log("dispatched obsoleted event");
                return;
            }

            localStorage.removeItem('walletconnect');

            console.log('disconnect');

            if (error)
            {
                console.error(error);
                throw error;
            }

            this.state = eState.DISCONNECTED;

            if (this.connector)
            {
                this.connector.killSession();

                this.connector = null;

                this.eventSubject.next({
                    event: eWalletConnectEvent.SESSION_CLOSED,
                    data: {},
                });
            }
        });
    }

    private ClearConnectTimeout()
    {
        if (this.timeoutHandle != null)
        {
            clearTimeout(this.timeoutHandle);
            this.timeoutHandle = null;
        }
    }

    private restoreSession()
    {
        if (isPlatformServer(this.platformId)) return;

        const walletConnectJSON = localStorage.getItem('walletconnect');

        if (!walletConnectJSON) return;

        try
        {
            const session = JSON.parse(walletConnectJSON);

            this.connector = new WalletConnect({ session });

            this.subscribeToEvents();

            this.state = eState.CONNECTED;
        }
        catch (e)
        {
            this.state = eState.DISCONNECTED;

            this.connector = null;
            this.ClearConnectTimeout();
        }
    }

    private IsValidConnectSettings()
    {
        //reproduced null here
        return this.connector.peerMeta != null;
    }

    private async sendTx(_id: number, _tx: any)
    {
        const tx = _tx;

        const wallets = await this.cryptoWalletService.getWallets();
        const platform = this.networkService.getPlatformByChainId(this.connector.chainId);

        let BaseCurrencyInstance;
        let TokenCurrencyInstance;

        const walletData = wallets.find((_data) => 
        {
            return _data.wallet.getAddress().toLowerCase() === _tx.from.toLowerCase()
                   && _data.wallet.getPlatform().toLowerCase() === platform.toLowerCase();
        });

        const isPrivateData = await this.cryptoWalletService.isPrivateDataExist(
            walletData.cryptoWallet
        );

        if (!isPrivateData)
        {
            const cryptoWallet: ICryptoWallet = await this.cryptoWalletService.getCryptoWalletById(
                walletData.cryptoWallet
            );

            let url = `/wallets/import/${cryptoWallet.id}`;

            if (cryptoWallet.phrase)
            {
                url = `/wallets/import/multi-coin/${cryptoWallet.id}`;
            }

            this.router.navigate([url], {
                queryParamsHandling: 'merge',
            });

            this.connector.rejectRequest({
                id: _id,
                error: {
                    message: 'No wallet private key',
                },
            });

            return;
        }

        switch (platform)
        {
            case ePlatforms.ETHEREUM:
                BaseCurrencyInstance = EtherCurrency;
                TokenCurrencyInstance = EtherContractCurrency;
                break;
            case ePlatforms.BSC:
                BaseCurrencyInstance = BSCCurrency;
                TokenCurrencyInstance = BSCContractCurrency;
                break;
        }


        let currency;

        if (!!tx.data)
        {
            currency = new TokenCurrencyInstance(
                this.injector,
                walletData.wallet,
                tx.from,
                {
                    address: tx.to,
                }
            );
        }
        else
        {
            currency = new BaseCurrencyInstance(
                this.injector,
                walletData.wallet,
                tx.from
            );
        }

        await currency.onSetup.pipe(take(1)).toPromise();

        if (tx.gas)
        {
            tx.gasLimit = tx['gasLimit'] || tx.gas;
            delete tx.gas
        }

        const preparedTx = tx;

        const res = await currency.parseTransaction(preparedTx, true);

        this.eventTransport.send('confirm-transaction', {
            tx: { ...res },
            preparedTx: preparedTx,
            currency: currency,
        });

        const result = await this.eventTransport
            .on('transaction-confirmation')
            .pipe(take(1))
            .toPromise();

        if (result.data.result === 'SUCCESS')
        {
            this.connector.approveRequest({
                id: _id,
                result: result.data.hash,
            });
        }
        else if (result.data.result === 'FAILED')
        {
            this.connector.rejectRequest({
                id: _id,
                error: {
                    message: 'Failed',
                },
            });
        }
        else
        {
            this.connector.rejectRequest({
                id: _id,
                error: {
                    code: 4001,
                    message: 'user rejected transaction',
                },
            });
        }
    }
}
