import { Inject, Injectable } from '@angular/core';

export enum eTheme
{
  light,
  dark
}

export enum eThemeOptions
{
  auto,
  light,
  dark
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService
{

  private currentTheme: eTheme;
  private currentThemeOption: eThemeOptions;

  constructor(

  )
  {
    this.setupTheme();
  }

  public setTheme(_option: eThemeOptions)
  {
    if (_option === Number(eThemeOptions.auto))
    {
      this.currentTheme = this.getAutoTheme();
    } else
    {
      this.currentTheme = Number(_option - 1);
    }

    this.currentThemeOption = _option;

    localStorage.setItem('theme', _option.toString());

    this.acceptTheme();
  }

  public getThemeOption(): eThemeOptions
  {
    return this.currentThemeOption;
  }

  private acceptTheme()
  {
    if (this.currentTheme === eTheme.dark)
    {
      // document.documentElement.style['filter'] = 'invert(1) hue-rotate(180deg)';
      document.documentElement.classList.add('theme-dark');
    } else
    {
      // document.documentElement.style['filter'] = '';
      document.documentElement.classList.remove('theme-dark');
    }
  }

  private setupTheme()
  {
    const themeOption: eThemeOptions = Number(localStorage.getItem('theme'));

    if (!themeOption || themeOption === Number(eThemeOptions.auto))
    {
      this.currentTheme = this.getAutoTheme();
    } else
    {
      this.currentTheme = Number(themeOption - 1);
    }

    this.currentThemeOption = themeOption;

    this.acceptTheme();
  }

  private getAutoTheme(): eTheme
  {

    if (navigator.userAgent.indexOf("Firefox") != -1) return eTheme.light;

    if (window.matchMedia('(prefers-color-scheme: dark)').matches) return eTheme.dark;

    return eTheme.light;
  }
}
