import {HttpClient} from '@angular/common/http';
import {
  IServiceAPIBackupLoad, IServiceAPIBackupLoadAck,
} from 'src/common/backup_common';
import {
  BACKUP_SERVICE_API,
  SERVICE_BACKUP_METHODS,
  GetOttV2
} from './backup_common';
import {Injectable} from '@angular/core';
import {CryptoService} from '../crypto/crypto.service';

export interface IBackup {
  id: number;
  backup_id: string;
  backup_desc: string;
  createdDate: Date;
}

@Injectable({
  providedIn: 'root'
})
export class eCreateLoadFlow {

  private constructor(
    private http: HttpClient,
    private cryptoService: CryptoService){
  }

  public async LoadBackup(_keyId: number, _backupId: string) {
    const responce = await this.ProcessState_LoadReq(_keyId, _backupId);    
    if(!responce) return null;
    return JSON.parse(responce);
  }

  private async ProcessState_LoadReq(_keyId:number, _backupId: string)
  {
      try
      {
          var challengePlain = await GetOttV2(this.http, _keyId);
          var ott = await await this.cryptoService.decryptOtt(challengePlain);
          return await this.ProcessState_Load(ott, _backupId);
      }
      catch (err)
      {
          console.error(err);
          return null;
      }
  }

  private async ProcessState_Load(_challengePlain: string, _backupId: string) {
    let body: IServiceAPIBackupLoad =
      {
        backup_ids: [_backupId],
        challenge: _challengePlain,
      };

    try {
      const response = <IServiceAPIBackupLoadAck>await this.http.post(`${BACKUP_SERVICE_API}${SERVICE_BACKUP_METHODS.load}`, body).toPromise();

        //returns first element
      return response.backups[0].backup.data;
    } catch (error) {
      console.error(error);

      return null;
    }
  }
}
