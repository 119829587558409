import { Injectable } from '@angular/core';
import {WebAuthenticatorService} from '../auth/web-authenticator.service';
import {AuthService} from '../auth/auth.service';
import {fromEvent, Subscription} from 'rxjs';
import {throttleTime} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {RoutesService} from '../routes/routes.service';
import {Location} from '@angular/common';
import {MfaService} from '../mfa/mfa.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private lastActivity: number;
  private timeout: number = null;

  private subscription: Subscription = new Subscription();

  constructor(
    private webAuthService: WebAuthenticatorService,
    private authService: AuthService,
    private location: Location,
    private routesService: RoutesService,
    private mfaService: MfaService
  ) {
    this.authService.lockState.subscribe(_state => {
      if (_state) this.stopListening();
      else        this.listenActivity();
    });
  }

  private resetActivity(){
    this.lastActivity = Date.now();
    //if(!environment.production){
      // console.log(`>> lastActivity = ${this.lastActivity}`);
    //}
  }

  public listenActivity()
  {
    this.resetActivity();
    console.log('start session');
    window.addEventListener('storage', e =>
    {
      if(e.key == 'is_locked' && e.newValue == 'true')
      {
        console.log("is_locked == true")
      }
    });
    this.startTimer();
    this.detectActivity();
  }

  public stopListening() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
      this.timeout = undefined;
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private detectActivity() {
    this.subscription.add(fromEvent(window, 'mousemove').pipe(throttleTime(200)).subscribe(() => {
      if (this.timeout) this.resetActivity();
    }));

    this.subscription.add(fromEvent(window, 'keypress').pipe(throttleTime(200)).subscribe(() => {
      if (this.timeout) this.resetActivity();
    }));
  }

  private startTimer() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }

    let timeout_ms = environment.session.inactivity_timeout_for_lock;
    if(Boolean(this.lastActivity)) timeout_ms += this.lastActivity - Date.now();
    console.log(`>> new timeout:${timeout_ms} ms`);

    this.timeout = window.setTimeout(() => {
      if(this.lastActivity + environment.session.inactivity_timeout_for_lock < Date.now())
      {
        console.log('lock session');
        this.lockSession();
      }else{
        console.log('continue session');
        this.startTimer();
      }
    }, timeout_ms);
  }

  public async lockSession()
  {
    this.stopListening();
    let isLockAvailable: boolean = false;

    const mfaAvailability = await this.mfaService.isMfaAvailable();
    if (mfaAvailability){
      isLockAvailable = true;
    } else {
      const isWebAuthnSupported = await this.webAuthService.isWebAuthenticationSupported();
      if(isWebAuthnSupported)
      {
        this.webAuthService.skipAuthentication();
        isLockAvailable = true;
      }
    }

    if (isLockAvailable) {
      this.routesService.saveRoute('preAuthenticate', this.location.path());
      await this.authService.lock();
    }else{
       await this.authService.logOut();
    }
  }
}
