import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import {take} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {EventTransportService} from '../event-transport/event-transport.service';
import { ePlatforms } from '../../../common/networks';

export enum eEthereumNetworks  {
  mainnet = 'mainnet',
  kovan = 'kovan',
  ropsten = 'ropsten',
}

export const BscNetworks = {
  mainnet: {chainId: 56},
  testnet: {chainId: 97},
}

export type Platform = 'ethereum' | 'bsc';

export type Networks = keyof typeof eEthereumNetworks | keyof typeof BscNetworks

@Injectable({
  providedIn: 'root'
})
export class NetworksService {

  private networks: {
    ethereum: keyof typeof eEthereumNetworks,
    bsc:      keyof typeof BscNetworks
  } = {
    ethereum: null,
    bsc:      null
  }

  constructor(
    private storage: StorageService,
    private eventTransport: EventTransportService
  ) { }

  public async setNetwork(_platform: Platform, _network: string) {
    const storageNetworks = await this.storage.get('networks') || {};

    // todo: refactor any
    this.networks[_platform] = <any>_network;

    storageNetworks[_platform] = _network;

    await this.storage.set('networks', storageNetworks);

    this.eventTransport.send('NETWORK_CHANGE', {[_platform]: _network});
  }

  public async getNetwork(platform: Platform) {

    if (!this.networks || !this.networks[platform]) {
      const storageNetworks = await this.storage.get('networks') || {};

      if (!storageNetworks[platform]) {
        await this.setNetwork(platform, environment.networks[platform]);
      } else {
        this.networks[platform] = storageNetworks[platform];
      }
    }

    return this.networks[platform];
  }

  public getPlatformByChainId(_chainId: number): ePlatforms
  {
    switch (_chainId)
    {
      case 1:
      case 42:
        return ePlatforms.ETHEREUM;
      case 56:
      case 97:
        return ePlatforms.BSC;
    }
  }
}
