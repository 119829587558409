import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { UserService } from '../user/user.service';
import {HttpClient} from "@angular/common/http";
import { Tutorials } from '../tutorial/tutorial.service';
import { eUserSetupAllowedKeys } from 'src/common/user_setup';

@Injectable({
  providedIn: 'root'
})

export class UserApi {

  constructor(
    private storage: StorageService,
    private userService: UserService,
    private http: HttpClient
  ) {

  }

  public async getUserData(_key: eUserSetupAllowedKeys): Promise<any> {
    try {
      const response = await this.http.get(`/api/user-data/${_key}`).toPromise();

      return response;
    } catch (error) {
      return undefined;
    }
    // const { id } = await this.userService.dataPromise;
    //
    // const users = await this.storage.getOption('users').toPromise();
    //
    // return Array.isArray(users) ? (users.find(({ user_id }) => user_id === id) || {}) : {};
  }

  public async setUserData(_data: {[key in eUserSetupAllowedKeys]?: string}): Promise<any> {
    try {
      const response = await this.http.post(`/api/user-data`, _data).toPromise();

      return response;
    } catch (error) {
      return undefined;
    }
    // const { id } = await this.userService.dataPromise;
    //
    // let usersData: IStorageUser[] = (await this.storage.getOption('users').toPromise()) || [];
    //
    // let userData: IStorageUser = usersData.find(({ user_id }) => user_id === id);
    //
    // if (!userData) {
    //   userData = {
    //     user_id: id,
    //     data: {}
    //   };
    // }
    //
    // userData.data = {
    //   ...userData.data,
    //   ..._data
    // };
    //
    // usersData = usersData.filter((_userData) => _userData.user_id !== userData.user_id);
    //
    // usersData = [...usersData, userData];
    //
    // this.storage.setOption('users', usersData);

    // return { result: 'success' };
  }
}
