import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {StorageService} from '../storage/storage.service';
import {UserService} from '../user/user.service';
import {eCreateBackupFlow} from '../backup/backup_save';
import {eCreateLoadFlow} from '../backup/backup_load';
import {IMarketModel} from 'src/server/api/market/market-model';

@Injectable({
  providedIn: 'root'
})
export class MarketApi {

  constructor(
    private storage: StorageService,
    private http: HttpClient,
    private userService: UserService,
    private backupCreateService: eCreateBackupFlow,
    private backupLoadService: eCreateLoadFlow
  ) { }

  public async getStored(_name: string): Promise<Object> {
    const userData = await this.userService.dataPromise;
    const userId = userData.id;

    const data = await this.storage.get('markets') || {};

    if (!data[userId]) return null;

    return data[userId][_name];
  }

  public async setStored(_name: string, _keyId: number, _data: any): Promise<void> {
    const userData = await this.userService.dataPromise;
    const userId = userData.id;

    const storageData = await this.storage.get('markets') || {};

    if (!storageData[userId]) storageData[userId] = {};

    storageData[userId][_name] = { keyId: _keyId, data: _data };

    await this.storage.set('markets', storageData);
  }

  public async getBackup(_name: string): Promise<any> {
    // load market info
    const response = await this.http.get(`/api/market-info?name=${_name}`).toPromise();
    if (!response || !response['length']) return null;

    const marketInfo = <IMarketModel>response[0];
    if(!marketInfo || !marketInfo.key_id || !marketInfo.backup_id) return null;

    // get backup
    const keyId = marketInfo.key_id;
    const backupId = marketInfo.backup_id;
    const backup = await this.backupLoadService.LoadBackup(keyId, backupId);
    if(!backup) return null;
    
    await this.setStored(_name, keyId, backup);

    return  { data: backup, keyId: keyId };
  }

  public async setBackup(_name: string, _keyId: number, _data: any): Promise<void> {
    try{
      // try to create backup
      const description = {type: 'market', name: _name};
      const backupId = await this.backupCreateService.create(_keyId, description, _data);
      
      // save market info
      const marketInfo = { name: _name, key_id: _keyId, backup_id: backupId };
      const response = await this.http.post(`/api/market-info`, marketInfo).toPromise();

      const isSuccess = response['result'] == 'success';
    }
    catch(err){
      console.warn(`CREATE_BACKUP_FAILED: ${JSON.stringify(err)}`);
    }
  }

  public async getStoredMarkets(): Promise<string[]> {
    const userData = await this.userService.dataPromise;
    const userId = userData.id;

    const data = await this.storage.get('markets') || {};

    if (!data[userId]) return [];

    return Object.keys(data[userId]);
  }

  public async getBackupedMarkets(): Promise<string[]> {
    const response = <{name: string}[]>await this.http.get(`/api/market-info`).toPromise();

    if (!response || !response['length']) return [];

    return response.map((v) => v.name);
  }

  public async getActive(): Promise<string> {
    const userData = await this.userService.dataPromise;
    const userId = userData.id;

    const data = await this.storage.get('active-market') || {};
    return data[userId];
  }

  public async setActive(_name: string): Promise<void> {
    const userData = await this.userService.dataPromise;
    const userId = userData.id;

    const storageData = await this.storage.get('active-market') || {};

    storageData[userId] = _name;
    await this.storage.set('active-market', storageData);
  }
}
