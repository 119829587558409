import { providers } from "ethers";

export class CustomFallbackProvider extends providers.FallbackProvider
{
  private cache = null;
  constructor(arrayProviders, ...args)
  {
    super(arrayProviders, ...args)
  }
  
  async detectNetwork()
  {
    if(this.cache == null)
    {
      this.cache = await super.detectNetwork()
    }
    return this.cache
  }
}