import {
    IOrderInfo,
    ISymbol,
    ITicker,
    Market,
    OrderState,
} from './market-base.class';
import { IMarketSettingsToken } from "./IMarketSettingsToken";
import HmacSHA512 from 'crypto-js/hmac-sha512';
import Sha512 from 'crypto-js/sha512';

export class MarketGateIO extends Market<IMarketSettingsToken> {
    protected name: string = 'Gate.io';
    protected icon: string = '/assets/images/exchanges/gate.svg';

    private accessUrl: string = 'https://api.gateio.ws';

    private proxyApi: string = '/api/market/gate';

    private currencies: string[] = [];
    private symbols: ISymbol[] = [];

    private prefferedCurrencies: string[] = ['USDT', 'JASMY', 'ETH'];

    private balances: { [key: string]: string } = {};

    public async checkConnection(): Promise<boolean> {
        const queryString = this.createQueryString();
        const timestamp = Math.round(Date.now() / 1000).toString();
        const endpoint = '/api/v4/spot/accounts';

        const preSignedText = this.getPreSignedText(
            'GET',
            endpoint,
            queryString,
            timestamp
        );

        const signature = this.getQuerySignature(preSignedText);

        const url = `${this.accessUrl}${endpoint}`;

        try {
            const response = <any>await this.http
                .post(this.proxyApi, {
                    url,
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        KEY: this.settings.access_key,
                        Timestamp: timestamp,
                        SIGN: signature,
                    },
                })
                .toPromise();

            response.forEach(
                (_curr) =>
                    (this.balances[_curr.currency.toLowerCase()] =
                        _curr.available || '0.00')
            );

            return true;
        } catch (error) {
            console.error(error);

            throw error;
        }
    }

    public async getCurrencies(): Promise<string[]> {
        if (this.currencies.length > 0) {
            return this.currencies;
        }

        const endpoint = '/api/v4/spot/currency_pairs';

        const url = `${this.accessUrl}${endpoint}`;

        try {
            const response = <any>await this.http
                .post(this.proxyApi, {
                    url,
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .toPromise();

            const currenciesSet: Set<string> = new Set();
            response
                .filter(({ trade_disabled }) => !trade_disabled)
                .forEach((_symbol) => {
                    currenciesSet.add(_symbol['base']);
                    currenciesSet.add(_symbol['quote']);
                });
            // const currencies = response.filter(({trade_disabled}) => !trade_disabled).map(({ currency }) => currency);

            this.currencies = Array.from(currenciesSet);

            return this.currencies;
        } catch (error) {
            console.error(error);

            throw error;
        }
    }

    public async getSymbols(_currency: string = ''): Promise<ISymbol[]> {
        const cachedSymbols = this.symbols.filter((_symbol) =>
            _symbol.symbol.toLowerCase().includes(_currency.toLowerCase())
        );

        if (cachedSymbols.length > 0) {
            return cachedSymbols;
        }

        const endpoint = '/api/v4/spot/currency_pairs';

        const url = `${this.accessUrl}${endpoint}`;

        try {
            const response = <any>await this.http
                .post(this.proxyApi, {
                    url,
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .toPromise();

            this.symbols = response
                .filter((_symbol) => _symbol.trade_status === 'tradable')
                .map(
                    (_symbol): ISymbol => {
                        return {
                            symbol: _symbol['id'],
                            base_currency: _symbol['base'],
                            quote_currency: _symbol['quote'],
                            precision: Math.max(
                                _symbol['precision'],
                                _symbol['amount_precision']
                            ).toString(),
                        };
                    }
                );

            return this.symbols.filter((_symbol) =>
                _symbol.symbol.toLowerCase().includes(_currency.toLowerCase())
            );
        } catch (error) {
            console.error(error);

            throw error;
        }
    }

    public async getBalance(_currency: string): Promise<string> {
        if (Object.keys(this.balances).length != 0) {
            return this.balances[_currency.toLowerCase()] || '0.00';
        }

        const queryString = this.createQueryString();
        const timestamp = Math.round(Date.now() / 1000).toString();
        const endpoint = '/api/v4/spot/accounts';

        const preSignedText = this.getPreSignedText(
            'GET',
            endpoint,
            queryString,
            timestamp
        );

        const signature = this.getQuerySignature(preSignedText);

        const url = `${this.accessUrl}${endpoint}`;

        try {
            const response = <any>await this.http
                .post(this.proxyApi, {
                    url,
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        KEY: this.settings.access_key,
                        Timestamp: timestamp,
                        SIGN: signature,
                    },
                })
                .toPromise();

            if (response.length === 0) return '0.00';

            response.forEach(
                (_curr) =>
                    (this.balances[_curr.currency.toLowerCase()] =
                        _curr.available || '0.00')
            );

            return this.balances[_currency.toLowerCase()] || '0.00';
        } catch (error) {
            console.error(error);

            throw error;
        }
    }

    public async getTicker(_symbol: string): Promise<ITicker> {
        const queryString = this.createQueryString({
            currency_pair: _symbol.toUpperCase(),
        });
        const endpoint = '/api/v4/spot/tickers';

        const url = `${this.accessUrl}${endpoint}?${queryString}`;

        try {
            const response = <any>await this.http
                .post(this.proxyApi, {
                    url,
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .toPromise();

            return {
                buy: response[0]['last'],
                sell: response[0]['last'],
            };
        } catch (error) {
            console.error(error);

            throw error;
        }
    }

    public getOrderInfoUrl(
        _orderId: string,
        _symbol?: string
    ): { url: string; data: any } {
        const endpoint = `/api/v4/spot/orders/${_orderId}`;

        const timestamp = Math.round(Date.now() / 1000).toString();

        const queryString = this.createQueryString({ currency_pair: _symbol });

        const preSignedText = this.getPreSignedText(
            'GET',
            endpoint,
            queryString,
            timestamp
        );

        const signature = this.getQuerySignature(preSignedText);

        return {
            url: `${this.accessUrl}${endpoint}?${queryString}`,
            data: {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    KEY: this.settings.access_key,
                    Timestamp: timestamp,
                    SIGN: signature,
                },
            },
        };
    }

    public async getOrderInfo(
        _orderId: string,
        _symbol: string
    ): Promise<IOrderInfo> {
        const endpoint = `/api/v4/spot/orders/${_orderId}`;

        const timestamp = Math.round(Date.now() / 1000).toString();

        const queryString = this.createQueryString({ currency_pair: _symbol });

        const preSignedText = this.getPreSignedText(
            'GET',
            endpoint,
            queryString,
            timestamp
        );

        const signature = this.getQuerySignature(preSignedText);

        const url = `${this.accessUrl}${endpoint}?${queryString}`;

        try {
            const response = <any>await this.http
                .post(this.proxyApi, {
                    url,
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        KEY: this.settings.access_key,
                        Timestamp: timestamp,
                        SIGN: signature,
                    },
                })
                .toPromise();

            return this.parseOrderInfo(response);
        } catch (error) {
            console.error(error);

            throw error;
        }
    }

    public async getOrdersHistory(_symbol): Promise<IOrderInfo[]> {
        if (!_symbol) {
            return [];
        }

        const endpoint = `/api/v4/spot/orders`;

        const timestamp = Math.round(Date.now() / 1000).toString();

        const queryString = this.createQueryString({
            currency_pair: _symbol,
            status: 'finished',
        });

        const preSignedText = this.getPreSignedText(
            'GET',
            endpoint,
            queryString,
            timestamp
        );

        const signature = this.getQuerySignature(preSignedText);

        const url = `${this.accessUrl}${endpoint}?${queryString}`;

        try {
            const response = <any>await this.http
                .post(this.proxyApi, {
                    url,
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        KEY: this.settings.access_key,
                        Timestamp: timestamp,
                        SIGN: signature,
                    },
                })
                .toPromise();

            return response.map((_orderData) =>
                this.parseOrderInfo(_orderData)
            );
        } catch (error) {
            console.error(error);

            throw error;
        }
    }

    public async createOrder(
        _symbol: string,
        _amount: string,
        _type: 'buy' | 'sell'
    ): Promise<string> {
        const endpoint = `/api/v4/spot/orders`;
        const ticker = await this.getTicker(_symbol);
        let price = _type === 'sell' ? ticker.sell : ticker.buy;
        const pricePercent = Number(price) * 0.01;

        price = (_type === 'sell'
            ? Number(price) - pricePercent
            : Number(price) + pricePercent
        ).toString();

        let amount =
            _type === 'sell'
                ? Number(_amount)
                : Number(_amount) / Number(price);

        const body = {
            currency_pair: _symbol.toUpperCase(),
            price,
            side: _type,
            amount: amount.toString(),
            time_in_force: 'ioc',
            quantity: amount.toString(),
        };

        const timestamp = Math.round(Date.now() / 1000).toString();

        const queryString = this.createQueryString();

        const preSignedText = this.getPreSignedText(
            'POST',
            endpoint,
            queryString,
            timestamp,
            JSON.stringify(body)
        );

        const signature = this.getQuerySignature(preSignedText);

        const url = `${this.accessUrl}${endpoint}`;

        try {
            const response = <any>await this.http
                .post(this.proxyApi, {
                    url,
                    method: 'POST',
                    body,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        KEY: this.settings.access_key,
                        Timestamp: timestamp,
                        SIGN: signature,
                    },
                })
                .toPromise();

            if (response['status'] === 'closed') {
                this.balances = {};
                return response['id'];
            }

            if (response['message']) {
                throw new Error(response['message']);
            }

            throw new Error('ORDER_NOT_FOUND');
        } catch (error) {
            // console.error(error);

            throw error;
        }
    }

    private parseOrderInfo(_orderInfo: any): IOrderInfo {
        const sendAmount =
            _orderInfo['side'] === 'buy'
                ? _orderInfo['fill_price']
                : _orderInfo['amount'];
        const getAmount =
            _orderInfo['side'] === 'buy'
                ? _orderInfo['amount']
                : _orderInfo['fill_price'];
        const currencies = this.coinsService.parseExchangeSymbol(
            _orderInfo['currency_pair']
        );

        return {
            orderId: _orderInfo['id'],
            sendAmount: sendAmount,
            getAmount: getAmount,
            fee: _orderInfo['fee'],
            finishedAt: _orderInfo['update_time'].toString(),
            price: _orderInfo['price'],
            state: this.parseOrderState(_orderInfo['status']),
            type: _orderInfo['side'],
            symbol: _orderInfo['currency_pair'],
            currencySend:
                _orderInfo['side'] === 'buy' ? currencies[1] : currencies[0],
            currencyGet:
                _orderInfo['side'] === 'buy' ? currencies[0] : currencies[1],
        };
    }

    private parseOrderState(_orderStatus: string): OrderState {
        if (['open'].includes(_orderStatus)) {
            return 'processing';
        }

        if (['closed'].includes(_orderStatus)) {
            return 'complete';
        }

        if (['cancelled'].includes(_orderStatus)) {
            return 'canceled';
        }

        return 'failed';
    }

    private parseOrderResponse(_response: any) {
        if (Boolean(_response['message'])) {
            return _response['message'];
        }

        if (_response['code'] === 30004) {
            return 'EXCHANGE.ERRORS.INSUFFICIENT-FUNDS';
        }

        return 'FORM.ERRORS.SMTH-WENT-WRONG';
    }

    private createQueryString(_params: Object = {}) {
        const defaultParams = {};

        const params = { ...defaultParams, ..._params };

        if (Object.keys(params).length === 0) return '';

        const ordered = Object.keys(params).sort();

        let result = '';

        ordered.forEach((_key) => {
            result += `&${_key}=${params[_key]}`;
        });

        return result.slice(1);
    }

    private getPreSignedText(
        _method: string,
        _endpoint: string,
        _queryParams: string,
        _timestamp: number | string,
        _payload?: string
    ) {
        const hash = Sha512(_payload || '').toString();
        return `${_method}\n${_endpoint}\n${_queryParams}\n${hash}\n${_timestamp}`;
    }

    private getQuerySignature(_query: string) {
        const hash = HmacSHA512(_query, this.settings.secret_key);

        return hash.toString();
    }

    private symbolsToCurrencies(_symbols: string[]): string[] {
        const currenciesSet: Set<string> = new Set();

        _symbols.forEach((_symbol) => {
            const currencies = this.coinsService.parseExchangeSymbol(_symbol);
            currenciesSet.add(currencies[0]);
            currenciesSet.add(currencies[1]);
        });

        let currencies = Array.from(currenciesSet);

        currencies = currencies.sort((a, b) => {
            const indexA = this.prefferedCurrencies.indexOf(a);
            const indexB = this.prefferedCurrencies.indexOf(b);
            if (indexA === -1) return 0;

            if (indexA < indexB) return -1;

            if (indexA !== -1 && indexB === -1) return -1;

            if (indexA > indexB) return 1;

            return 0;
        });

        return currencies;
    }

    private parseSymbol(_symbolData: any): ISymbol {
        const currencies = this.coinsService.parseExchangeSymbol(
            _symbolData.symbol
        );
        return {
            symbol: _symbolData.symbol,
            base_currency: currencies[0],
            quote_currency: currencies[1],
            precision: _symbolData['quantity_scale'],
        };
    }
}
