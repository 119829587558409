<div class="page-content page-row">
    <div class="content">
        <div class="close" (click)="onClose.emit()">
            <img
                src="/assets/images/icons/close.svg"
                alt=""
                class="close-icon"
            />
        </div>

        <p class="text text--size-l text--extra-bold text--center alert__title">
            {{ title | translate }}
        </p>
        <p class="text" style="margin-top: 16px">
            {{ description | translate }}
        </p>

        <ng-content select="[noticeContent]"></ng-content>

        <div class="actions" style="margin-top: 24px">
            <button *ngIf="action" class="btn" (click)="onAction.emit()">
                {{ action | translate }}
            </button>
            <button *ngIf="close" class="btn" (click)="onClose.emit()">
                {{ close | translate }}
            </button>
        </div>
    </div>
</div>
