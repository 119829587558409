import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import { ProviderFactoryService} from './services/provider/provider-factory.service';
import { TranslateService} from '@ngx-translate/core';
import { NgxIndexedDBService} from 'ngx-indexed-db';
import { CryptoWalletService} from "./services/crypto-wallet/crypto-wallet.service";
// @ts-ignore
import { version} from '../../package.json';
import {isPlatformServer, registerLocaleData} from "@angular/common";
import { WebAuthenticatorService} from './services/auth/web-authenticator.service';
import { ThemeService} from './services/theme/theme.service';
import { SessionService} from './services/session/session.service';
import { AppPasswordService} from './services/app-password/app-password.service';
import { WalletConnectService } from './services/wallet-connect/wallet-connect.service';
import { PostsetupService } from './services/postsetup/postsetup.service';
import { ConfigService} from '../app/services/config/config.service';
import { StorageService} from '../app/services/storage/storage.service';
import { CryptoService} from '../app/services/crypto/crypto.service';
import { eVersionService } from './services/version/version-service';
import { take } from 'rxjs/operators';
import { EventTransportService } from './services/event-transport/event-transport.service';
import localeEn from '@angular/common/locales/en';
import localeZh from '@angular/common/locales/zh';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import { Subscription } from 'rxjs';
import { LoggerService } from './services/logger/logger.service';

const locales = {
    en: localeEn,
    zh: localeZh,
    ja: localeJa,
    ko: localeKo
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit
{
    private eventSubscription: Subscription;
    public showIndexDBAlert: boolean;
    public showUpdateAlert:    boolean;
    public alertDescription:   string;

    constructor(
        private provider: ProviderFactoryService,
        private translateService: TranslateService,
        private idb: NgxIndexedDBService,
        private cryptoWallet: CryptoWalletService,
        @Inject(PLATFORM_ID) private platformId: Object,
        private webAuthnService: WebAuthenticatorService,
        private themeService: ThemeService,
        private passwordService: AppPasswordService,
        private sessionService: SessionService,
        private postsetupService: PostsetupService,
        private walletConnect: WalletConnectService,
        private eventTransport: EventTransportService,
        private versionService: eVersionService,
    	private config: ConfigService,
	    private storage: StorageService,
    	private cryptoService: CryptoService,
        private logger: LoggerService
    ) {
        console.log('SugoiWallet v.' + version);
    	this.setupLanguages();
    	// this.setupClientKeys();
    }

  async ngOnInit()
  {
    this.eventSubscription = this.eventTransport.on('show-index-db-alert').subscribe(async (args) => {
      this.showIndexDBAlert = true;
    });

    if (isPlatformServer(this.platformId)) return;

	  this.setupLanguages();

    localStorage.setItem('exchange', JSON.stringify({}))
    this.versionService.updateAvailable.subscribe(this.OnUpdateAvailable.bind(this));

    this.versionService.CheckUpdate();
  }

    private async OnUpdateAvailable(_version: string)
    {
        let walletName = await this.translateService.get("WALLET.ON-TESTNET")
            .pipe(take(1)).toPromise();

        this.alertDescription = await this.translateService.get("COMMON.UPDATE-ALERT.DESCRIPTION", {
            new_version: _version,
            app_version: walletName + " " + version,
        }).pipe(take(1)).toPromise();

        this.showUpdateAlert = true;
    }

    public async UpdateToLastVersion()
    {
        this.HideUpdateAlert();

        try
        {
            await this.versionService.Update();
            document.location.reload();
        }
        catch (err)
        {
            //todo show something
        }
    }

    public HideUpdateAlert()
    {
        this.showUpdateAlert = false;
    }

    public HideIndexDBAlert()
    {
      this.showIndexDBAlert = false;
    }

  private setupLanguages() {
    const japanese = location.href.includes('japanese');

    const langs = ['en', 'zh', 'ko'];

    if (japanese) langs.push('ja');

    this.translateService.addLangs(langs);
    this.translateService.setDefaultLang('en');

    let language = 'en';

    const langFromStorage = localStorage.getItem('language');

    language = langFromStorage;

    if (!langFromStorage) {
      navigator.languages.some(_navLang => {
        const lang = langs.find(_lang => _navLang.startsWith(_lang));

        if (lang) {
          language = lang;

          return true;
        }

        return false;
      });
    }

    language = language || 'en';

    if (!langs.includes(language)) language = 'en';

    if (japanese) language = 'ja';

    this.translateService.use(language);

    localStorage.setItem('language', language);

    registerLocaleData(locales[language]);

    this.translateService.onLangChange.subscribe(({lang}) => {
      localStorage.setItem('language', lang);
      registerLocaleData(locales[language]);
    });
  }

//   private async setupClientKeys()
//   {
//     const startDate = Date.now();

//     const config = await this.config.get();
//     const clientKeyId = config.client_key.id;
//     const keyNamedCurve = config.client_key.named_curve;
//     const keyExpiration = config.client_key.expiration_min;

//     let expirationDate;
//     const storedJwkKey = await this.storage.get(clientKeyId);

//     if(storedJwkKey)
//     {
//       expirationDate = await this.storage.get('userkey_expiration_date');

//       if (expirationDate && startDate < expirationDate && typeof storedJwkKey === "string") return;

//       await this.storage.del(clientKeyId);
//       await this.storage.del('userkey_named_curve');
//       await this.storage.del('userkey_expiration_date');
//       await this.storage.del('userkey_publicBase64');
//       await this.storage.del('userkey_generated_ms');
//       await this.storage.del('userkey_error');
//     }

//     expirationDate = startDate + keyExpiration * 60 * 1000;

//     try
//     {
//         const keyPair = this.cryptoService.generateEcKey2(keyNamedCurve);

//         this.storage.set('userkey_named_curve', keyNamedCurve);

//         const pubKeyBase64 = this.cryptoService.exportKeyToBase642(keyPair.publicKey);

//         this.storage.set('userkey_expiration_date', expirationDate);
//         this.storage.set('userkey_publicBase64', pubKeyBase64);
//         this.storage.set('userkey_generated_ms', Date.now() - startDate);

//         const privKeyBase64 = this.cryptoService.exportKeyToBase642(keyPair.privateKey);

//         this.cryptoService.importKeyFromBase642(privKeyBase64)

//         this.storage.set(clientKeyId, privKeyBase64);

//         console.debug(`setupClientKeys complete(${JSON.stringify(privKeyBase64)})`);
//     } catch (error) {
//       this.storage.set('userkey_error', error);
//       this.logger.error('App Component > setupClientKeys', error.toString());
//     }
//   }
}
