//NOTE: carefully change names, we store them in local storage.
export enum eMarketType
{
    bibox = 'bibox',
    binance_global = 'binance_global',
    bithumb = 'bithumb',
    bithumb_kor = 'bithumb_kor',
    bitfinex = 'bitfinex',
    bitmart = 'bitmart',
    bitstamp = 'bitstamp',
    bittrex = 'bittrex',
    bkex = 'bkex',
    coinbase = 'coinbase',
    gate = 'gate',
    gemini = 'gemini',
    hitbtc = 'hitbtc',
    hoo = 'hoo',
    hotbit = 'hotbit',
    huobi = 'huobi',
    jubi = 'jubi',
    kraken = 'kraken',
    kucoin = 'kucoin',
    mxc = 'mxc',
    poloniex = 'poloniex',
    okex = 'okex'
}
