import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class LoggerService
{

    constructor(
        private http: HttpClient
    )
    {

    }

    public log(..._args: string[])
    {
        let message = `[User-Agent: ${navigator.userAgent}] --- Message: `;

        _args.forEach(_item => message += _item + ', ')

        message.slice(0, -2);

        this.http.post('/api/client-logs', { msg: message}).toPromise();
    }

    public error(..._args: string[])
    {
        let message = `[User-Agent: ${navigator.userAgent}] --- Message: `;

        _args.forEach(_item => message += _item + ', ')

        message.slice(0, -2);

        this.http.post('/api/client-logs/error', { msg: message }).toPromise();
    }
}
