import { Injectable, Injector } from '@angular/core';
import { ICryptoWallet } from './type';
import { Observable, ReplaySubject } from 'rxjs';
import { EtherWallet, Wallet } from '../wallets/wallet.service';
import { CryptoWalletApi } from '../api/crypto-wallet';
import { WalletsApi } from '../api/wallets';
import { UserService } from '../user/user.service';
import { map, take } from 'rxjs/operators';
import { AppKeyV2Service } from '../app-password/app-key-v2.service';
import { CryptoService } from '../crypto/crypto.service';
import { ICurrency } from '../wallets/currency';
import { eCreateBackupFlow } from '../backup/backup_save';
import { eCreateLoadFlow } from '../backup/backup_load';
import { UserApi } from '../api/user';
import { eActionTemplate, eOttPermission } from 'src/common/request_enums';
import { IActionInfo, IOttInfo } from 'src/common/action-interfaces';
import { eActionType } from 'src/common/actions/action-types';
import { ActionService } from '../action/action.service';
import { EventTransportService } from '../event-transport/event-transport.service';
import { eUserSetupAllowedKeys } from 'src/common/user_setup';
import { BSCWallet } from '../wallets/wallet-bsc.service';
import { ePlatforms } from '../../../common/networks';

export interface IPrivateData
{
    type: 'private-key' | 'phrase' | 'json';
    data: string;
}

@Injectable({
    providedIn: 'root'
})
export class CryptoWalletService
{

    private cryptoWallets: ICryptoWallet[] = [];
    private cryptoWalletsSubject: ReplaySubject<ICryptoWallet[]> = new ReplaySubject(1);
    private wallets: { cryptoWallet: string, wallet: Wallet; }[] = [];
    private walletsSubject: ReplaySubject<{ cryptoWallet: string, wallet: Wallet; }[]> = new ReplaySubject(1);

    private isInitialised: ReplaySubject<boolean> = new ReplaySubject(1);

    private isWalletsLoading = false;

    constructor(
        private injector: Injector,
        private cryptoWalletsApi: CryptoWalletApi,
        private walletsApi: WalletsApi,
        private userService: UserService,
        private passwordService: AppKeyV2Service,
        private cryptoService: CryptoService,
        private backupCreateService: eCreateBackupFlow,
        private backupLoadService: eCreateLoadFlow,
        private actionService: ActionService,
        private eventTransport: EventTransportService,
        private userApi: UserApi
    )
    {
        this.userService.data.pipe(take(1)).subscribe(() =>
        {
            this.init();
        });
    }

    private async init()
    {
        const cwData = await this.cryptoWalletsApi.getCryptoWallets();

        await this.setupCryptoWallets(cwData);

        await this.loadWallets();

        //todo: remove
        await this.userApi.setUserData({ [eUserSetupAllowedKeys.FILTER_TOKENS_SETUP]: '1' });

        this.isInitialised.next(true);
    }

    private async setupCryptoWallets(_cryptoWallets: ICryptoWallet[])
    {
        this.cryptoWallets = _cryptoWallets;
        this.cryptoWalletsSubject.next(this.cryptoWallets);
    }

    public async createNewCryptoWallet(_name: string, _base = false): Promise<string>
    {
        if (!this.isCryptoWalletNameUnique(_name)) throw new Error("NOT_UNIQUE");

        const phrase = Wallet.generatePhrase();

        const address = EtherWallet.getAddressByPhrase(phrase);

        const cryptoWallet: ICryptoWallet = <ICryptoWallet>await this.cryptoWalletsApi.setCryptoWallet(_name, true, false, _base);
        if (!cryptoWallet) throw new Error("NOT_CREATED");

        try
        {
            const passwordData = await this.passwordService.create();
            await this.cryptoWalletsApi.setCryptoWalletKeyId(cryptoWallet.id, +passwordData.key_id);

            cryptoWallet.key_id = +passwordData.key_id;

            this.cryptoWallets.push(cryptoWallet);
            this.cryptoWalletsSubject.next(this.cryptoWallets);

            const privateData: IPrivateData = { type: 'phrase', data: phrase };

            await this.localBackup(cryptoWallet.id, privateData, passwordData);
            await this.createBackup(cryptoWallet.key_id, cryptoWallet.id);
            await this.createBaseWallets(cryptoWallet.id, address, phrase, true);

            return phrase;
        }
        catch (error)
        {
            this.cryptoWalletsApi.deleteCryptoWallet(cryptoWallet.id);
            throw new Error("NOT_CREATED");
        }
    }

    public async importByAddress(_name: string, _address: string, _platform?: ePlatforms)
    {
        if (!this.isCryptoWalletNameUnique(_name)) throw new Error("NOT_UNIQUE");

        const checkedAddress = EtherWallet.checkAddress(_address)

        const cryptoWallet = await this.cryptoWalletsApi.setCryptoWallet(_name, false, false, false, _platform) as ICryptoWallet;
        if (!cryptoWallet) throw new Error("NOT_IMPORT");

        try
        {
            this.cryptoWallets.push(cryptoWallet);
            this.cryptoWalletsSubject.next(this.cryptoWallets);

            if (_platform)
            {
                const wallet = await this.createWalletByAddress(cryptoWallet.id, _address, _platform, true);
            }
            else
            {
                const ethWallet = await this.createWalletByAddress(cryptoWallet.id, _address, ePlatforms.ETHEREUM, true);
                const bscWallet = await this.createWalletByAddress(cryptoWallet.id, _address, ePlatforms.BSC, true);
            }

            return cryptoWallet.id;
        }
        catch (error)
        {
            this.cryptoWalletsApi.deleteCryptoWallet(cryptoWallet.id);
            throw new Error("NOT_IMPORT");
        }
    }

    public async importByPhrase(_name: string, _phrase: string, _platform?: ePlatforms): Promise<string>
    {
        if (!this.isCryptoWalletNameUnique(_name)) throw new Error("NOT_UNIQUE");

        const address = EtherWallet.getAddressByPhrase(_phrase);

        const cryptoWallet: ICryptoWallet = <ICryptoWallet>await this.cryptoWalletsApi.setCryptoWallet(_name, true, false, false, _platform);
        if (!cryptoWallet) throw new Error("NOT_IMPORT");

        try
        {
            this.cryptoWallets.push(cryptoWallet);
            this.cryptoWalletsSubject.next(this.cryptoWallets);

            const privateData: IPrivateData = { type: 'phrase', data: _phrase };

            await this.localBackup(cryptoWallet.id, privateData);

            if (_platform)
            {
                const wallet = await this.createWalletByPhrase(cryptoWallet.id, address, _platform, _phrase, true);
            }
            else
            {
                await this.createBaseWallets(cryptoWallet.id, address, _phrase, true);
            }

            return cryptoWallet.id;
        }
        catch (error)
        {
            this.cryptoWalletsApi.deleteCryptoWallet(cryptoWallet.id);
            throw new Error("NOT_IMPORT");
        }
    }

    public async importByPrivateKey(_name: string, _privateKey: string, _platform: ePlatforms): Promise<string>
    {
        if (!this.isCryptoWalletNameUnique(_name)) throw new Error("NOT_UNIQUE");

        const address = EtherWallet.getAddressByPrivateKey(_privateKey);

        const cryptoWallet: ICryptoWallet = <ICryptoWallet>await this.cryptoWalletsApi.setCryptoWallet(_name, false, false, false, _platform);
        if (!cryptoWallet) throw new Error("NOT_IMPORT");

        try
        {
            this.cryptoWallets.push(cryptoWallet);
            this.cryptoWalletsSubject.next(this.cryptoWallets);

            const privateData: IPrivateData = { type: 'private-key', data: _privateKey };

            await this.localBackup(cryptoWallet.id, privateData);

            if (_platform)
            {
                const wallet = await this.createWalletByPrivateKey(cryptoWallet.id, address, _platform, _privateKey);
            }
            else
            {
                const ethWallet = await this.createWalletByPrivateKey(cryptoWallet.id, address, ePlatforms.ETHEREUM, _privateKey);
                const bscWallet = await this.createWalletByPrivateKey(cryptoWallet.id, address, ePlatforms.BSC, _privateKey);
            }

            return cryptoWallet.id;
        }
        catch (error)
        {
            this.cryptoWalletsApi.deleteCryptoWallet(cryptoWallet.id);
            throw new Error("NOT_IMPORT");
        }
    }

    public async importByEncryptedJson(_name: string, _json: string, _password: string, _platform: ePlatforms): Promise<string>
    {
        if (!this.isCryptoWalletNameUnique(_name)) throw new Error("NOT_UNIQUE");

        const address = await EtherWallet.getAddressByEncryptedJson(_json, _password);

        const cryptoWallet: ICryptoWallet = <ICryptoWallet>await this.cryptoWalletsApi.setCryptoWallet(_name, false, false, false, _platform);
        if (!cryptoWallet) throw new Error("NOT_IMPORT");

        try
        {
            this.cryptoWallets.push(cryptoWallet);
            this.cryptoWalletsSubject.next(this.cryptoWallets);

            const privateData: IPrivateData = { type: 'json', data: JSON.stringify({ json: _json, password: _password }) };

            await this.localBackup(cryptoWallet.id, privateData);

            if(_platform)
            {
                const wallet = await this.createWalletByEncryptedJson(cryptoWallet.id, address, _platform, _json, _password);
            }
            else
            {
                const etWallet = await this.createWalletByEncryptedJson(cryptoWallet.id, address, ePlatforms.ETHEREUM, _json, _password);
                const bscWallet = await this.createWalletByEncryptedJson(cryptoWallet.id, address, ePlatforms.BSC, _json, _password);
            }

            return cryptoWallet.id;
        }
        catch (error)
        {
            this.cryptoWalletsApi.deleteCryptoWallet(cryptoWallet.id);
            throw new Error("NOT_IMPORT");
        }
    }

    private isCryptoWalletNameUnique(_name: string): boolean
    {
        return !this.cryptoWallets.some(({ name }) => name.toLowerCase() === _name.toLowerCase());
    }

    private async createBaseWallets(__cryptoWalletId: string, _address: string, _phrase: string, _initCurrencies: boolean = false): Promise<any>
    {
        await this.createWalletByPhrase(__cryptoWalletId, _address, ePlatforms.ETHEREUM, _phrase, _initCurrencies);
        await this.createWalletByPhrase(__cryptoWalletId, _address, ePlatforms.BSC, _phrase, _initCurrencies);
    }

    private async createWalletByPhrase(_cryptoWalletId: string, _address: string, _platform: ePlatforms, _phrase: string, _initCurrencies: boolean = false): Promise<Wallet>
    {
        let walletInstance;

        switch (_platform)
        {
            case ePlatforms.BSC:
                walletInstance = BSCWallet;
                break;
            // case ePlatforms.ETHEREUM:
            default:
                walletInstance = EtherWallet;
                break;
        }

        const wallet = new walletInstance(this.injector, this, _cryptoWalletId, _address, _initCurrencies);

        this.wallets.push({ cryptoWallet: _cryptoWalletId, wallet });
        this.walletsSubject.next(this.wallets);

        await this.walletsApi.setWallet({
            crypto_wallet: _cryptoWalletId,
            address: wallet.getAddress(),
            platform: _platform
        });

        return wallet;
    }

    private async createWalletByPrivateKey(_cryptoWalletId: string, _address: string, _platform: ePlatforms, _privateKey: string): Promise<Wallet>
    {
        let walletInstance;

        switch (_platform)
        {
            case ePlatforms.BSC:
                walletInstance = BSCWallet;
                break;
            // case ePlatforms.ETHEREUM:
            default:
                walletInstance = EtherWallet;
                break;
        }

        const wallet = new walletInstance(this.injector, this, _cryptoWalletId, _address, true);

        this.wallets.push({ cryptoWallet: _cryptoWalletId, wallet });
        this.walletsSubject.next(this.wallets);

        await this.walletsApi.setWallet({
            crypto_wallet: _cryptoWalletId,
            address: wallet.getAddress(),
            platform: _platform
        });

        return wallet;
    }

    private async createWalletByEncryptedJson(_cryptoWalletId: string, _address: string, _platform: ePlatforms, _json: string, _password: string): Promise<Wallet>
    {
        let walletInstance;

        switch (_platform)
        {
            case ePlatforms.BSC:
                walletInstance = BSCWallet;
                break;
            // case ePlatforms.ETHEREUM:
            default:
                walletInstance = EtherWallet;
                break;
        }

        const wallet = new walletInstance(this.injector, this, _cryptoWalletId, _address, true);

        this.wallets.push({ cryptoWallet: _cryptoWalletId, wallet });
        this.walletsSubject.next(this.wallets);

        await this.walletsApi.setWallet({
            crypto_wallet: _cryptoWalletId,
            address: wallet.getAddress(),
            platform: _platform
        });

        return wallet;
    }

    private async createWalletByAddress(_cryptoWalletId: string, _address: string, _platform: ePlatforms, _initCurrencies: boolean = false): Promise<Wallet>
    {
        let walletInstance;

        switch (_platform)
        {
            case ePlatforms.BSC:
                walletInstance = BSCWallet;
                break;
            // case ePlatforms.ETHEREUM:
            default:
                walletInstance = EtherWallet;
                break;
        }

        const wallet = new walletInstance(this.injector, this, _cryptoWalletId, _address, _initCurrencies);

        this.wallets.push({ cryptoWallet: _cryptoWalletId, wallet });
        this.walletsSubject.next(this.wallets);

        await this.walletsApi.setWallet({
            crypto_wallet: _cryptoWalletId,
            address: wallet.getAddress(),
            platform: _platform
        });

        return wallet;
    }

    public getCryptoWallets(): Observable<ICryptoWallet[]>
    {
        return this.cryptoWalletsSubject.asObservable();
    }

    public async getCryptoWalletById(_id: string): Promise<ICryptoWallet>
    {
        const cryptoWallets = await this.cryptoWalletsSubject.pipe(take(1)).toPromise();
        return cryptoWallets.find(({ id }) => id === _id);
    }

    public async getBaseCryptoWallet(): Promise<ICryptoWallet>
    {
        const cryptoWallets = await this.cryptoWalletsSubject.pipe(take(1)).toPromise();

        let baseCryptoWallet = cryptoWallets.find(_cryptoWallet => _cryptoWallet.base);

        if (Boolean(baseCryptoWallet))
        {
            return baseCryptoWallet;
        }

        const sortedCryptoWallets = cryptoWallets.filter(_cryptoWallet => _cryptoWallet.phrase).sort((_firstWallet, _secondWallet) =>
        {
            const firstWalletDate = new Date(_firstWallet['createdAt']);
            const secondWalletDate = new Date(_secondWallet['createdAt']);

            if (firstWalletDate > secondWalletDate)
            {
                return 1;
            }
            if (firstWalletDate < secondWalletDate)
            {
                return -1;
            }

            return 0;
        });

        if (sortedCryptoWallets.length > 0)
        {
            return sortedCryptoWallets[0];
        }

        return cryptoWallets[0];
    }

    public async addExistingWalletByJsonKeyload(_cryptoWalletId: string, _address: string, _json: string, _password: string): Promise<any>
    {
        await EtherWallet.getAddressByEncryptedJson(_json, _password);

        const walletData = this.wallets.find(_data => _data.cryptoWallet === _cryptoWalletId && _data.wallet.getAddress() === _address);
        const platform = walletData.wallet.getPlatform();

        let walletInstance;

        switch (platform)
        {
            case ePlatforms.ETHEREUM:
                walletInstance = EtherWallet;
                break;
            case ePlatforms.BSC:
                walletInstance = BSCWallet;
                break;
            default:
                walletInstance = EtherWallet;
        }

        const addressFromPrivateData = await walletInstance.getAddressByEncryptedJson(_json, _password);

        if (addressFromPrivateData.toLowerCase() !== walletData.wallet.getAddress().toLowerCase())
        {
            throw new Error('ADDRESS_MISMATCH');
            return;
        }

        const privateData: IPrivateData = { type: 'json', data: JSON.stringify({ json: _json, password: _password }) };

        await this.localBackup(_cryptoWalletId, privateData);
    }

    public async addExistingWalletByPrivateKey(_cryptoWalletId: string, _address: string, _privateKey: string): Promise<any>
    {
        await EtherWallet.getAddressByPrivateKey(_privateKey);

        const walletData = this.wallets.find(_data => _data.cryptoWallet === _cryptoWalletId && _data.wallet.getAddress() === _address);
        const platform = walletData.wallet.getPlatform();

        let walletInstance;

        switch (platform)
        {
            case ePlatforms.ETHEREUM:
                walletInstance = EtherWallet;
                break;
            case ePlatforms.BSC:
                walletInstance = BSCWallet;
                break;
            default:
                walletInstance = EtherWallet;
        }

        const addressFromPrivateData = await walletInstance.getAddressByPrivateKey(_privateKey);

        if (addressFromPrivateData.toLowerCase() !== walletData.wallet.getAddress().toLowerCase())
        {
            throw new Error('ADDRESS_MISMATCH');
            return;
        }

        const privateData: IPrivateData = { type: 'private-key', data: _privateKey };

        await this.localBackup(_cryptoWalletId, privateData);
    }

    public async addExistingWalletByPhrase(_cryptoWalletId: string, _phrase: string): Promise<void>
    {
        const address = await EtherWallet.getAddressByPhrase(_phrase);

        const walletData = this.wallets.find(_data => _data.cryptoWallet === _cryptoWalletId && _data.wallet.getAddress() === address);
        if (!walletData)
        {
            throw new Error('ADDRESS_MISMATCH');
        }

        const platform = walletData.wallet.getPlatform();

        let walletInstance;

        switch (platform)
        {
            case ePlatforms.ETHEREUM:
                walletInstance = EtherWallet;
                break;
            case ePlatforms.BSC:
                walletInstance = BSCWallet;
                break;
            default:
                walletInstance = EtherWallet;
        }

        const addressFromPrivateData = await walletInstance.getAddressByPhrase(_phrase);

        if (addressFromPrivateData.toLowerCase() !== walletData.wallet.getAddress().toLowerCase())
        {
            throw new Error('ADDRESS_MISMATCH');
        }

        const privateData: IPrivateData = { type: 'phrase', data: _phrase };

        await this.localBackup(_cryptoWalletId, privateData);
    }

    public async getWallets(_cryptoWalletId?: string): Promise<{ cryptoWallet: string, wallet: Wallet; }[]>
    {
        await this.isInitialised.pipe(take(1)).toPromise();

        return this.walletsSubject.pipe(map(_data =>
        {
            if (!Boolean(_cryptoWalletId))
            {
                return _data;
            }

            return _data.filter(_walletData => _walletData.cryptoWallet === _cryptoWalletId);
        })).pipe(take(1)).toPromise();
    }

    public async getWalletsByCryptoWalletId(_id: string): Promise<Wallet[]>
    {
        const walletsData = await this.walletsApi.getWallets(_id);

        const wallets: Wallet[] = walletsData.map((_wallet) =>
        {
            if (_wallet.platform === ePlatforms.ETHEREUM)
            {
                return new EtherWallet(this.injector, this, _id, _wallet.address);
            }

            if (_wallet.platform === ePlatforms.BSC)
            {
                return new BSCWallet(this.injector, this, _id, _wallet.address);
            }
        });

        return wallets;
    }

    //todo optimize?
    public async getCurrenciesForWalletId(cryptoWalletId: string): Promise<ICurrency[]>
    {
        const allCurrencies = await this.getCurrencies();
        return allCurrencies.filter(entry => entry.cryptoWalletId == cryptoWalletId).map(entry => entry.currency);
    }

    public async getCurrencies(): Promise<{ cryptoWalletId: string, address: string, currency: ICurrency; }[]>
    {
        await this.isInitialised.pipe(take(1)).toPromise();

        const currencies: { cryptoWalletId: string, address: string, currency: ICurrency; }[] = [];

        await Promise.all(this.wallets.map(async (_walletData, index) =>
        {
            await _walletData.wallet.isAllCurrenciesLoaded().pipe(take(1)).toPromise();

            const walletCurrencies = await _walletData.wallet.getCurrencies().pipe(take(1)).toPromise();

            walletCurrencies.forEach(_currency =>
            {
                currencies.push({
                    cryptoWalletId: _walletData.cryptoWallet,
                    address: _walletData.wallet.getAddress(),
                    currency: _currency
                });
            });
        }));

        return currencies;
    }

    public async getWalletByAddress(_cryptoWalletId: string, _address: string, _platform: string = 'ethereum'): Promise<Wallet>
    {
        await this.isInitialised.pipe(take(1)).toPromise();

        const walletData = this.wallets.find(_data =>
            _data.cryptoWallet === _cryptoWalletId
            && _data.wallet.getAddress().toLowerCase() === _address.toLowerCase()
            && _platform.toLowerCase() === _data.wallet.getPlatform().toLowerCase()
        );

        if (!walletData)
        {
            return null;
        }

        return walletData.wallet;
    }

    public async getWalletByAddressWithoutCryptoWallet(_address: string, _platform: ePlatforms): Promise<Wallet>
    {
        await this.isInitialised.pipe(take(1)).toPromise();

        const walletData = this.wallets.find(_data =>
            _data.wallet.getAddress().toLowerCase() === _address.toLowerCase()
            && _platform.toLowerCase() === _data.wallet.getPlatform().toLowerCase()
        );

        if (!walletData)
        {
            return null;
        }

        return walletData.wallet;
    }

    public async getWalletByPlatform(_cryptoWalletId: string, _platform: ePlatforms): Promise<Wallet>
    {
        await this.isInitialised.pipe(take(1)).toPromise();

        const walletData = this.wallets.find(_data =>
        {
            return _data.cryptoWallet === _cryptoWalletId && _data.wallet.getPlatform() === _platform;
        });

        if (!walletData)
        {
            return null;
        }

        return walletData.wallet;
    }

    public async getCurrency(_cryptoWalletId: string, _symbol: string): Promise<ICurrency>
    {
        await this.isInitialised.pipe(take(1)).toPromise();

        const walletsData = this.wallets.filter(_data => _data.cryptoWallet === _cryptoWalletId);

        let currency: ICurrency;

        await Promise.all(walletsData.map(async ({ wallet }) =>
        {
            const walletCurrency = await wallet.getCurrencyBySymbol(_symbol);

            if (walletCurrency)
            {
                currency = walletCurrency;
            }
        }));

        return currency;
    }

    private async localBackup(_cryptoWalletId: string, _encryptedData: IPrivateData, passwordData_?: { key_id: number, password: string; })
    {
        const cryptoWallet = await this.getCryptoWalletById(_cryptoWalletId);

        let passwordData = passwordData_;

        let isKeysExists = false;
        const keyId = cryptoWallet.key_id;

        const ottInfo = <IOttInfo>{ permission: eOttPermission.keys, key_id: keyId };
        if (typeof keyId !== undefined && keyId)
        {
            isKeysExists = await this.passwordService.checkOttExist(ottInfo);
        }

        if (isKeysExists && !passwordData)
        {

            const actionInfo = await this.actionService.prepareAction(eActionType.BackupLoad, _cryptoWalletId, cryptoWallet.name);

            passwordData = { key_id: cryptoWallet.key_id, password: await this.passwordService.get(ottInfo, actionInfo) };
        }

        if (!passwordData || !passwordData.key_id)
        {

            passwordData = await this.passwordService.create();

            await this.cryptoWalletsApi.setCryptoWalletKeyId(_cryptoWalletId, +passwordData.key_id);
        }

        const encryptedData = await this.cryptoService.encrypt(passwordData.password, JSON.stringify(_encryptedData));

        await this.cryptoWalletsApi.setPrivateDataToLocal(_cryptoWalletId, [
            encryptedData[0]['hexEncode'](),
            encryptedData[1]
        ]);

        this.cryptoWallets = this.cryptoWallets.map(_cryptoWallet =>
        {
            if (_cryptoWallet.id !== _cryptoWalletId)
            {
                return _cryptoWallet;
            }

            return {
                ..._cryptoWallet,
                key_id: +passwordData.key_id
            };
        });

        this.cryptoWalletsSubject.next(this.cryptoWallets);
    }

    public async createBackup(_keyId: number, _cryptoWalletId: string): Promise<any>
    {
        const data = await this.getLocalEncryptedData(_cryptoWalletId);
        if (!data)
        {
            return null;
        }

        try
        {
            const description = { type: 'crypto_wallet', id: _cryptoWalletId };
            const backupId = await this.backupCreateService.create(_keyId, description, data);

            await this.cryptoWalletsApi.setCryptoWalletBackupId(_cryptoWalletId, backupId);

            this.cryptoWallets = this.cryptoWallets.map(_cryptoWallet =>
            {
                if (_cryptoWallet.id !== _cryptoWalletId)
                {
                    return _cryptoWallet;
                }

                return {
                    ..._cryptoWallet,
                    backup_id: backupId,
                    backup: true
                };
            });

            this.cryptoWalletsSubject.next(this.cryptoWallets);

            return backupId;
        } catch (error)
        {
            console.error(error);

            return null;
        }
    }

    public async revokeBackup(_cryptoWalletId: string): Promise<void>
    {
        await this.cryptoWalletsApi.revokeBackup(_cryptoWalletId);

        this.cryptoWallets = this.cryptoWallets.map(_cryptoWallet =>
        {
            if (_cryptoWallet.id !== _cryptoWalletId)
            {
                return _cryptoWallet;
            }

            return {
                ..._cryptoWallet,
                backup_id: null,
                backup: false
            };
        });

        this.cryptoWalletsSubject.next(this.cryptoWallets);
    }

    private async getBackup(_cryptoWalletId: string)
    {
        const backupIds = await this.cryptoWalletsApi.getBackupIds(_cryptoWalletId);

        const backupId = backupIds ? backupIds.backup_id : null;
        if (!backupId)
        {
            return null;
        }

        const backup = await this.backupLoadService.LoadBackup(backupIds.key_id, backupId);
        return backup;
    }

    private async loadBackup(_cryptoWalletId: string)
    {
        const cryptoWallet = await this.getCryptoWalletById(_cryptoWalletId);
        if (!cryptoWallet) return false;

        const backupData = await this.getBackup(_cryptoWalletId);
        if (!backupData)
        {
            return false;
        }

        await this.cryptoWalletsApi.setPrivateDataToLocal(_cryptoWalletId, backupData);

        return backupData;
    }

    public async isLocalEncryptedDataExist(_id: string): Promise<boolean>
    {
        try
        {
            let data = await this.cryptoWalletsApi.getPrivateDataFromLocal(_id);
            if (!data)
            {
                return false;
            }

            if (Boolean(data['data']))
            {
                await this.cryptoWalletsApi.setPrivateDataToLocal(_id, data['data']);
                data = data['data'];
            }

            if (Array.isArray(data) && data.length === 2)
            {
                return true;
            }
        } catch (error)
        {
            console.error(error);
        }
        return false;
    }

    private async getLocalEncryptedData(_id: string): Promise<any>
    {
        let data = await this.cryptoWalletsApi.getPrivateDataFromLocal(_id);
        if (!data)
        {
            return null;
        }

        if (Boolean(data['data']))
        {
            await this.cryptoWalletsApi.setPrivateDataToLocal(_id, data['data']);

            data = data['data'];
        }

        if (!Array.isArray(data) || data.length !== 2)
        {
            return null;
        }

        return data;
    }

    public async getPrivateData(_id: string, _ottInfo: IOttInfo, _actionInfo: IActionInfo)
    {
        if (typeof _ottInfo.key_id === 'undefined' || !_ottInfo.key_id)
        {
            return null;
        }

        let data = await this.cryptoWalletsApi.getPrivateDataFromLocal(_id);

        if (Boolean(data['data']))
        {
            await this.cryptoWalletsApi.setPrivateDataToLocal(_id, data['data']);

            data = data['data'];
        }

        if (!data || !Array.isArray(data) || data.length !== 2)
        {
            return null;
        }

        const password = await this.passwordService.get(_ottInfo, _actionInfo);
        if (!password)
        {
            return null;
        }

        const decryptedData = await this.cryptoService.decrypt(password, data[0]['hexDecode'](), data[1]);
        if (!decryptedData[0] || !decryptedData[1])
        {
            return null;
        }

        return JSON.parse(decryptedData[0]);
    }

    public async isPrivateDataExist(_id: string): Promise<boolean>
    {
        if (await this.isLocalEncryptedDataExist(_id))
        {
            return true;
        }

        const data = await this.loadBackup(_id);
        return Boolean(data);
    }

    public async getPhrase(_id?: string)
    {
        const cryptoWallet = await this.getCryptoWalletById(_id);
        const ottInfo = <IOttInfo>{ permission: eOttPermission.keys, key_id: cryptoWallet.key_id };

        const actionInfo = await this.actionService.prepareAction(eActionType.ExportPhrase, _id, cryptoWallet.name);
        const privateData = await this.getPrivateData(_id, ottInfo, actionInfo);
        if (!privateData)
        {
            return null;
        }

        if (privateData['type'] === 'phrase')
        {
            return privateData['data'];
        }

        return null;
    }

    private async loadWallets()
    {
        if (this.isWalletsLoading === true)
        {
            return;
        }

        this.isWalletsLoading = true;

        this.wallets = [];

        for (let i = 0; i < this.cryptoWallets.length; i++)
        {
            const cryptoWalletId = this.cryptoWallets[i].id;
            const keyId: number = +this.cryptoWallets[i].key_id;
            const wallets = await this.walletsApi.getWallets(cryptoWalletId);

            if (wallets.length === 0)
            {
                let phrase;
                if (this.cryptoWallets[i].backup_id)
                {
                    const actionInfo = <IActionInfo>{ template_id: eActionTemplate.action, action_id: `load_wallet:${cryptoWalletId}` };
                    const ottInfo = <IOttInfo>{ permission: eOttPermission.keys, key_id: keyId };
                    try
                    {
                        const privateData = await this.getPrivateData(cryptoWalletId, ottInfo, actionInfo);
                        if (!!privateData && privateData['type'] === 'phrase')
                        {
                            phrase = privateData['data'];
                        }
                    }
                    catch (error)
                    {
                        console.log("🚀 ~ file: crypto-wallet.service.ts ~ line 815 ~ error", error);
                    }
                }

                if (typeof phrase === 'undefined') phrase = Wallet.generatePhrase();
                const address = Wallet.getAddressByPhrase(phrase);
                const privateData: IPrivateData = { type: 'phrase', data: phrase };

                await this.localBackup(cryptoWalletId, privateData);
                await this.createBackup(keyId, cryptoWalletId);
                await this.createBaseWallets(cryptoWalletId, address, phrase);
            }
            else
            {
                for (const _wallet of wallets)
                {
                    if (this.wallets.some(_oldWalletData =>
                        _oldWalletData.cryptoWallet === cryptoWalletId
                        && _oldWalletData.wallet.getAddress() === _wallet.address
                        && _oldWalletData.wallet.getPlatform().toLowerCase() === _wallet.platform.toLowerCase())
                    ) { }

                    else if (_wallet.platform.toLowerCase() === ePlatforms.ETHEREUM.toLowerCase())
                    {
                        const wallet = new EtherWallet(this.injector, this, cryptoWalletId, _wallet.address);

                        this.walletsPush(cryptoWalletId, wallet);
                    }
                    else if (_wallet.platform.toLowerCase() === ePlatforms.BSC.toLowerCase())
                    {
                        const wallet = new BSCWallet(this.injector, this, cryptoWalletId, _wallet.address);

                        this.walletsPush(cryptoWalletId, wallet);
                    }
                }
            }

        }
        this.isInitialised.next(true);
        this.isWalletsLoading = false;
    }

    private walletsPush(cryptoWalletId_, wallet)
    {
        this.wallets.push({ cryptoWallet: cryptoWalletId_, wallet });
        this.walletsSubject.next(this.wallets);
    }

    public async deleteCryptoWallet(_cryptoWallet: ICryptoWallet)
    {
        try
        {
            const walletId = _cryptoWallet.id;
            const walletKeyId = _cryptoWallet.key_id;
            const actionInfo = await this.actionService.prepareAction(eActionType.WalletRemove, walletId, _cryptoWallet.name);
            this.eventTransport.send('verificate-user', actionInfo);
            const { data } = await this.eventTransport.on('user-verification').pipe(take(1)).toPromise();
            if (data && data.result !== 'SUCCESS')
            {
                this.eventTransport.send('toast', { message: 'VERIFICATION.NOT-VERIFIED', type: 'error' });
                throw new Error(`${data.result}`);
            }

            await this.cryptoWalletsApi.deleteCryptoWallet(walletId);

            this.cryptoWalletsApi.delPrivateDataFromLocal(walletId);

            this.cryptoWallets = this.cryptoWallets.filter(cryptoWallet => cryptoWallet.id !== walletId);
            this.cryptoWalletsSubject.next(this.cryptoWallets);

            this.wallets = this.wallets.filter(({ cryptoWallet }) => cryptoWallet !== walletId);
            this.walletsSubject.next(this.wallets);
        }
        catch (error)
        {
            console.error(error);
            throw error;
        }
    }

    public async createWalletByPlatform(_cryptoWalletId: string, _platform: ePlatforms): Promise<Wallet>
    {
        const cryptoWallet = await this.getCryptoWalletById(_cryptoWalletId);

        if (!cryptoWallet.phrase)
        {
            throw new Error('CANNOT_ADD_WALLET');
        }

        const actionInfo = await this.actionService.prepareAction(eActionType.WalletUpdate, _cryptoWalletId, cryptoWallet.name);
        const ottInfo = <IOttInfo>{ permission: eOttPermission.keys, key_id: cryptoWallet.key_id };

        const privateData = await this.getPrivateData(_cryptoWalletId, ottInfo, actionInfo);
        const phrase = privateData.data;
        const address = EtherWallet.getAddressByPhrase(phrase);
        return await this.createWalletByPhrase(_cryptoWalletId, address, _platform, phrase, false);
    }
}
