import HmacSHA256 from 'crypto-js/hmac-sha256';
import {
    CurrencyNames,
    IOrderInfo,
    ISymbol,
    ITicker,
    Market,
    OrderState
} from './market-base.class';
import { IMarketSettingsToken } from "./IMarketSettingsToken";

export class MarketJubi extends Market<IMarketSettingsToken> {
  protected name: string = 'Jubi';
  protected icon: string = '/assets/images/exchanges/jubi.svg';

  private accessUrl: string = 'https://api.jbex.com';

  private proxyApi: string = '/api/market/jubi';

  private currencies: string[] = [];
  private symbols: ISymbol[] = [];

  private brokerInfo: any;

  private balances: { asset: string; free: string }[] = [];

  private tickers: {[key: string]: ITicker} = {};

  private prefferedCurrencies: string[] = ['USDT', 'JASMY', 'ETH'];
  private currenciesName: {[name: string]: CurrencyNames } = {
    JASMY1: 'JASMY',
  };

  //#region Market abstract implementations

  public async checkConnection(): Promise<boolean> {
    try {
        await this.getBalance('ETHUSDT');

        return true;
    } catch (error) {
      console.error(error);

      throw error;
    }
  }

  public async getCurrencies(): Promise<string[]> {
    if (this.currencies.length == 0) {
      await this.loadCurrencies();
    }

    const displayCurrencies = this.currencies.map((c) => this.getCurrencyDisplay(c));
    return displayCurrencies;
  }

  public async getSymbols(_currency: string = ''): Promise<ISymbol[]> {
    const cachedSymbols = this.symbols.filter((_symbol) =>
      _symbol.symbol.toLowerCase().includes(_currency.toLowerCase())
    );

    if (cachedSymbols.length > 0) {
      return cachedSymbols;
    }

    const endpoint = '/open/api/v2/market/symbols';

    const url = `${this.accessUrl}${endpoint}`;

    try {
      if (!this.brokerInfo) {
        const response = await this.http
          .post(this.proxyApi, {
            url,
            method: 'GET',
          })
          .toPromise();

        this.brokerInfo = response;
      }

      this.symbols = this.brokerInfo.symbols.map((_symbol) => {
        const basePrecision = (_symbol.baseAssetPrecision.split('.')[1] || '').length;
        const quotePrecision = (_symbol.quotePrecision.split('.')[1] || '').length;

        return {
          symbol: _symbol.symbol,
          base_currency: this.getCurrencyDisplay(_symbol.baseAsset),
          quote_currency: this.getCurrencyDisplay(_symbol.quoteAsset),
          precision: Math.max(basePrecision, quotePrecision),
        };
      });

      return this.symbols.filter((_symbol) =>
        _symbol.symbol.toLowerCase().includes(_currency.toLowerCase())
      );
    } catch (error) {
      console.error(error);

      throw error;
    }
  }

  public async getBalance(_currency: string): Promise<string> {
    const queryString = this.createQueryString({ timestamp: Date.now() });
    const endpoint = '/openapi/v1/account';

    const preSignedText = this.getPreSignedText(queryString);

    const signature = this.getQuerySignature(preSignedText);

    const url = `${this.accessUrl}${endpoint}?${queryString}&signature=${signature}`;

    try {
      if (this.balances.length === 0) {
        const response = await this.http
          .post(this.proxyApi, {
            url,
            method: 'GET',
            headers: {
              'X-BH-APIKEY': this.settings.access_key,
            },
          })
          .toPromise();

        if (!response['balances']) throw new Error(response['msg'])

        this.balances = response['balances'];
      }

      const wallet = this.balances.find(
        (_balance) => _balance.asset.toUpperCase() === _currency.toUpperCase()
      );

        if (Boolean(wallet)) {
          return wallet['free'];
        }

        return '0.00';
    } catch (error) {
      console.error(error);

      throw error;
    }
  }

  public async getTicker(_symbol: string): Promise<ITicker> {
    const queryString = this.createQueryString();

    const endpoint = '/openapi/quote/v1/ticker/bookTicker';

    const url = `${this.accessUrl}${endpoint}`;

    const symbol = _symbol === 'JASMY1USDT' ? 'JASMYUSDT' : _symbol;

    try {
      if (Object.keys(this.tickers).length === 0) {
        const response = await this.http
        .post(this.proxyApi, {
          url,
          method: 'GET',
        })
        .toPromise();

        (<Array<any>>response).forEach(_ticker => {
          this.tickers[_ticker.symbol] = {
            buy: _ticker.bidPrice,
            sell: _ticker.askPrice
          }
        })
      }

      return this.tickers[symbol];
    }
    catch (error) {
      console.error(error);
      throw error;
    }
  }

  public getOrderInfoUrl(_orderId: string): { url: string; data: any; } {
    const endpoint = '/openapi/v1/order';

    const queryString = this.createQueryString({ orderId: _orderId, timestamp: Date.now() });

    const preSignedText = this.getPreSignedText(queryString);

    const signature = this.getQuerySignature(preSignedText);

    const url = `${this.accessUrl}${endpoint}?${queryString}&signature=${signature}`;

    return {
        url,
        data: {
            apiKey: this.settings.access_key,
            headers: {
              'X-BH-APIKEY': this.settings.access_key,
            }
        }
    };
  }

  public async getOrderInfo(
    _orderId: string,
    _symbol: string
  ): Promise<IOrderInfo> {
    const orderInfo = this.getOrderInfoUrl(_orderId);

    try {
      const response = await this.http
        .post(this.proxyApi, {
          url: orderInfo.url,
          method: 'GET',
          headers: {
            ...orderInfo.data.headers
          }
        })
        .toPromise();

      if (!!response && response['orderId']) {
        const orderData = response;

        if (!orderData) {
          throw new Error('ORDER_NOT_FOUND');
        }

        return this.parseOrderInfo(orderData);
      } else {
        throw new Error(response['message']);
      }
    } catch (error) {
      console.error(error);

      throw error;
    }
  }

  public async getOrdersHistory(): Promise<IOrderInfo[]> {
    const endpoint = '/openapi/v1/historyOrders';

    const queryString = this.createQueryString({timestamp: Date.now()});

    const preSignedText = this.getPreSignedText(queryString);

    const signature = this.getQuerySignature(preSignedText);

    const url = `${this.accessUrl}${endpoint}?${queryString}&signature=${signature}`;

    try {
      const response = await this.http
        .post(this.proxyApi, {
          url,
          method: 'GET',
          headers: {
            'X-BH-APIKEY': this.settings.access_key,
          }
        })
        .toPromise();

      if (Array.isArray(response)) {
        const ordersData = response;

        return ordersData.map((_orderData) => this.parseOrderInfo(_orderData));
      } else {
        throw new Error(response['message']);
      }
    }
    catch (error) {
      console.error(error);

      throw error;
    }
  }

  public async createOrder(
    _symbol: string,
    _amount: string,
    _type: 'buy' | 'sell'
  ): Promise<string>
  {
    const endpoint = '/openapi/v1/order';
    const timestamp = Date.now();

    const body = {
      symbol: _symbol.toUpperCase(),
      side: _type.toUpperCase(),
      type: 'MARKET',
      quantity: _amount,
      timestamp: timestamp
    };

    if (_symbol.toUpperCase().includes('USDT') && _type === 'buy') {
        body.quantity = Number(body.quantity).toFixed(2);
    }

    const queryString = this.createQueryString(body);

    const preSignedText = this.getPreSignedText(queryString);

    const signature = this.getQuerySignature(preSignedText);

    const url = `${this.accessUrl}${endpoint}?${queryString}&signature=${signature}`;

    try {
      const response = await this.http
        .post(this.proxyApi, {
          url,
          method: 'POST',
          body: body,
          headers: {
            'X-BH-APIKEY': this.settings.access_key,
          }
        })
        .toPromise();

        this.balances = [];

      if (!!response['orderId']) {
        this.registerOrder(response['orderId']);

        return response['orderId'];
      } else {
        throw new Error(this.parseOrderResponse(response));
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  //#endregion

  private async registerOrder(_orderId: string): Promise<void>
  {
      const requestData = this.getOrderInfoUrl(_orderId);

      await this.http.post(`${this.proxyApi}/register-order`, requestData).toPromise();
  }

  public getCurrencyDisplay(_currency: string): CurrencyNames
  {
      const currencyDisplay = this.currenciesName[_currency] || super.getCurrencyDisplay(_currency);

      return currencyDisplay;
  }

  private parseOrderInfo(_orderInfo: any): IOrderInfo {
    // TODO: check and change
    const sendAmount =
      _orderInfo['side'] === 'BUY'
        ? _orderInfo['origQty']
        : _orderInfo['origQty'];
    const getAmount =
      _orderInfo['side'] === 'BUY'
        ? _orderInfo['executedQty']
        : _orderInfo['cummulativeQuoteQty'];

    const currencies = this.coinsService.parseExchangeSymbol(
      _orderInfo['symbol']
    );

    return {
      orderId: _orderInfo['orderId'],
      sendAmount: sendAmount,
      getAmount: getAmount,
      fee: null,
      finishedAt: _orderInfo['time'].toString(),
      price: _orderInfo['avgPrice'],
      state: this.parseOrderState(_orderInfo['status']),
      type: _orderInfo['side'].toLowerCase(),
      symbol: _orderInfo['symbol'],
      currencySend:
        _orderInfo['side'] === 'BUY' ? currencies[1] : currencies[0],
      currencyGet:
        _orderInfo['side'] === 'BUY' ? currencies[0] : currencies[1],
    };
  }

  private parseOrderState(_orderStatus: string): OrderState {
    if (['NEW'].includes(_orderStatus)) {
      return 'processing';
    }

    if (['FILLED', 'PARTIALLY_FILLED'].includes(_orderStatus)) {
      return 'complete';
    }

    if (['CANCELED', 'PENDING_CANCEL'].includes(_orderStatus)) {
      return 'canceled';
    }

    return 'failed';
  }

  private parseOrderResponse(_response: any) {
    if (Boolean(_response['msg'])) {
      return _response['msg'];
    }

    return 'FORM.ERRORS.SMTH-WENT-WRONG';
  }

  private createQueryString(_params: Object = {}) {
    const defaultParams = {};

    const params = { ...defaultParams, ..._params };

    const ordered = Object.keys(params).sort();

    let result = '';

    ordered.forEach((_key) => {
      result += `&${_key}=${params[_key]}`;
    });

    return result.slice(1);
  }

  private getPreSignedText(_queryParams: string) {
    return `${_queryParams}`;
  }

  private getQuerySignature(_query: string) {
    const hash = HmacSHA256(_query, this.settings.secret_key);

    return hash.toString();
  }

  private symbolsToCurrencies(_symbols: string[]): string[] {
    const currenciesSet: Set<string> = new Set();

    _symbols.forEach((_symbol) => {
      const currencies = this.coinsService.parseExchangeSymbol(_symbol);
      currenciesSet.add(currencies[0]);
      currenciesSet.add(currencies[1]);
    });

    let currencies = Array.from(currenciesSet);

    currencies = currencies.sort((a, b) => {
      const indexA = this.prefferedCurrencies.indexOf(a);
      const indexB = this.prefferedCurrencies.indexOf(b);
      if (indexA === -1) return 0;

      if (indexA < indexB) return -1;

      if (indexA !== -1 && indexB === -1) return -1;

      if (indexA > indexB) return 1;

      return 0;
    });

    return currencies;
  }

  private async loadCurrencies(){
    const endpoint = '/openapi/v1/brokerInfo';

    const url = `${this.accessUrl}${endpoint}`;

    try {
      if (!this.brokerInfo) {
        const response = await this.http
          .post(this.proxyApi, {
            url,
            method: 'GET',
          })
          .toPromise();

        this.brokerInfo = response;
      }

      const currenciesSet: Set<string> = new Set();

      this.brokerInfo.symbols.forEach((_symbol) => {
        currenciesSet.add(_symbol.baseAsset);
        currenciesSet.add(_symbol.quoteAsset);
      });

      this.currencies = Array.from(currenciesSet);

    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
