import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ITransportEvent } from './types';
import { filter, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventTransportService {

  private eventsStream: Subject<ITransportEvent> = new Subject<ITransportEvent>();

  constructor() {
  }

  public send(_name: string, _data?: any) {
    const event: ITransportEvent = { name: _name };

    if (Boolean(_data)) {
      event.data = _data;
    }

    this.eventsStream.next(event);
  }

  public on(_name: string | string[]): Observable<ITransportEvent> {
    if (typeof _name === 'string') {
      return this.eventsStream.asObservable().pipe(
        filter(({ name }) => name === _name)
      );
    }

    return this.eventsStream.asObservable().pipe(
      filter(({ name }) => _name.includes(name))
    );
  }

  public onOnce(_name: string | string[]): Promise<ITransportEvent> {
    if (typeof _name === 'string') {
      return this.eventsStream.asObservable().pipe(
        filter(({ name }) => name === _name)
      ).pipe(take(1)).toPromise();
    }

    return this.eventsStream.asObservable().pipe(
      filter(({ name }) => _name.includes(name))
    ).pipe(take(1)).toPromise();
  }
}
