import { ethers } from 'ethers';
import { environment } from '../../../../environments/environment';
import { IProviderFactoryService } from '../../provider/IProviderFactoryService';
import { eBlockLookup, ePageLookup, eRejectFailReason, IBlockChainExplorer, IGasOracle, ITransactionResponceEx } from './types';

interface IBSCResponce<T>
{
    status:     string;
    message:    string;

    result:     T;
}

interface IBSCHistoryNodeResponce
{
    timeStamp:      number;
    value:          string;
    from:           string;
    to:             string;
    hash:           string;
    input:          string;
    confirmations:  string;
    blockNumber:    string;

    gas:            string;
    gasPrice:       string;
    gasUsed:        string;
}

interface IBSCHistoryResponce extends IBSCResponce<IBSCHistoryNodeResponce[]>
{
}

interface IBSCABIResponce extends IBSCResponce<string>
{
}

interface IBSCBlockResponce extends IBSCResponce<string>
{
}

interface IBSCEstimatationResponce extends IBSCResponce<string>
{
}

interface IBSCGasOracleResponceResult
{
    SafeGasPrice:       string,
    ProposeGasPrice:    string,
    FastGasPrice:       string,
}

interface IBSCGasOracleResponce extends IBSCResponce<IBSCGasOracleResponceResult>
{
}

enum eBSCModule
{
    PROXY       = "proxy",
    ACCOUNT     = "account",
    CONTRACT    = "contract",
    GAS_TRACKER = "gastracker"
}

enum eBSCAction
{
    ETH_BLOCKNUMBER = "eth_blockNumber",
    GASORACLE       = "gasoracle",
    GASESTIMATE     = "gasestimate",
    TOKENTX         = "tokentx",
    TXLIST          = "txlist"
}

function ParseWei(_value: string)
{
    return ethers.utils.parseUnits(_value, 'wei');
}

export class eBSCBlockChainExplorer implements IBlockChainExplorer
{
    private providerFactoryService: IProviderFactoryService | string;//todo remove me. pass only etherscanApi to ctor 

    constructor(_providerService: IProviderFactoryService | string)
    {
        this.providerFactoryService = _providerService;
    }

    //#region Public Methods
    public async GetEstimatationOfConfirmTime(gasPrice: string): Promise<number>
    {
        let action = `${eBSCAction.GASESTIMATE}&gasprice=${gasPrice}`;
        let res = (<IBSCEstimatationResponce>await this.Fetch(eBSCModule.GAS_TRACKER, action));
        return Number(res.result);
    }

    public async GetBlockNumber(): Promise<number>
    {
        let res = (<IBSCBlockResponce>await this.Fetch(eBSCModule.PROXY, eBSCAction.ETH_BLOCKNUMBER));
        return Number(res.result);
    }

    public async GetHistory(
        _address:           string,
        _contractAddress:   string,
        _lookSettings:      ePageLookup | eBlockLookup): Promise<ITransactionResponceEx[]>
    {

        let action = _contractAddress != null ? `${eBSCAction.TOKENTX}&contractaddress=${_contractAddress}`
                                              : eBSCAction.TXLIST;

        let url = `${action}`
                + `&address=${_address}`
                + `&sort=desc`;

        if (_lookSettings instanceof ePageLookup)
        {
            url += `&page=${_lookSettings.page}&offset=${_lookSettings.page}`;
        }
        else
        {
            url += `&startblock=${_lookSettings.startBlock}&endblock=${_lookSettings.endBlock}`;
        }

        let res = <IBSCHistoryResponce>await this.Fetch(eBSCModule.ACCOUNT, url);

        let history = res.result.map((_tx) =>
        {
            return {
                from:           _tx.from,
                to:             _tx.to,
                hash:           _tx.hash,
                data:           _tx.input,

                gasLimit:       ParseWei(_tx.gas),
                gasUsed:        ParseWei(_tx.gasUsed),
                gasPrice:       ParseWei(_tx.gasPrice),

                timestamp:      Number(_tx.timeStamp),
                confirmations:  Number(_tx.confirmations),
                value:          ParseWei(_tx.value),
                blockNumber:    Number(_tx.blockNumber),
            };
        });

        return history;
    }

    public async GetContractABI(_address: string): Promise<string>
    {
        let url = `getabi&address=${_address}`;
        return (<IBSCABIResponce>await this.Fetch(eBSCModule.CONTRACT, url)).result;
    }

    public async GetGasOracle(): Promise<IGasOracle>
    {
        let apiRes = (<IBSCGasOracleResponce>await this.Fetch(eBSCModule.GAS_TRACKER, eBSCAction.GASORACLE)).result;

        return {
            proposeGasPrice: apiRes.ProposeGasPrice,
            fastGasPrice:    apiRes.FastGasPrice,
            safeGasPrice:    apiRes.SafeGasPrice,
        }
    }
    //#endregion Public Methods

    private ParseFailReason<T>(_responce: IBSCResponce<T>)
    {
        //todo check http return code

        const S_OK = "1";

        // NOTE: Etherscan for empty history returns next:
        // >>>Status: "0", message: "No transactions found", result: Array(0)}
        // Here "0" is rather normal status, so if _responce.result is string,
        // then it must be error message. Or no??

        //todo use npm styring check
        if (_responce.status !== S_OK && typeof((<any>_responce).result) === "string")
        {
            if ((<any>_responce).result.toLowerCase() === 'max rate limit reached')
            {
                throw eRejectFailReason.FAIL_BY_LIMIT_REACHED;
            }
            else
            {
                throw eRejectFailReason.FAIL;
            }
        }
    }
    
    private async Fetch(_module:string, _action: string): Promise<any>
    {
        let api: string = null;

        //todo
        if (typeof this.providerFactoryService === 'string' || this.providerFactoryService instanceof String)
        {
            api = <string>this.providerFactoryService;
        }
        else
        {
            api = await this.providerFactoryService.getApi('bsc');
        }

        let url = `${api}?module=${_module}&action=${_action}&apikey=${environment.bscExplorerApi}`;
        return fetch(url).then(async res =>
        {
            var data = <IBSCResponce<any>>await res.json();
            this.ParseFailReason(data);
            return data;
        });
    }
}
