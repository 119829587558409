// ******************************************************************
// requests_docs.txt from Z:\VO\resource_server\requests_docs.txt

export enum eActionTemplate {
    authorization   = 1,
    transaction     = 2,
    exchange        = 3,
    action          = 4
};

export enum eMfaAvailabilityCheckResult {
    fail_by_token_invalid				= -5,
    fail_by_token_expired				= -4,
    fail_by_access_denied				= -3,
    fail_by_invalid_parameters			= -2,
    fail_by_service_temporary_not_valid = -1,
    success								= 0
};

export enum eMfaTransactionStartV2Result {
    fail_by_user_device_lost			= -9,
    fail_by_template_not_found			= -8,
    fail_by_user_device_not_found		= -7,
    fail_by_client_not_found			= -6,
    fail_by_token_invalid				= -5,
    fail_by_token_expired				= -4,
    fail_by_access_denied				= -3,
    fail_by_invalid_parameters			= -2,
    fail_by_service_temporary_not_valid = -1,
    success								= 0
};

export enum eMfaTransactionStatusGetResult {
    fail_by_transaction_not_found		= -6,
    fail_by_token_invalid				= -5,
    fail_by_token_expired				= -4,
    fail_by_access_denied				= -3,
    fail_by_invalid_parameters			= -2,
    fail_by_service_temporary_not_valid = -1,
    success								= 0
};

export enum eMfaTransactionStatus {
    pending		= 0,
    completed   = 1,
    expired		= 2,
    failed		= 3
};

export enum eMfaTransactionCancelResult {
    fail_by_already_completed			= -10,
    fail_by_transaction_completed		= -9,
    fail_by_transaction_expired			= -8,
    fail_by_transaction_failed			= -7,
    fail_by_transaction_not_found		= -6,
    fail_by_token_invalid				= -5,
    fail_by_token_expired				= -4,
    fail_by_access_denied				= -3,
    fail_by_invalid_parameters			= -2,
    fail_by_service_temporary_not_valid = -1,
    success								= 0
};

export enum eRsUserDataGetResult {
    fail_by_invalid_parameters				= -5,
    fail_by_service_temporary_not_valid 	= -4,
    fail_by_access_denied					= -3,
    fail_by_token_expired					= -2,
    fail_by_token_invalid					= -1,
    success								    = 0
};

export enum eRsUserDataSetResult {
    fail_by_invalid_parameters				= -5,
    fail_by_service_temporary_not_valid 	= -4,
    fail_by_access_denied					= -3,
    fail_by_token_expired					= -2,
    fail_by_token_invalid					= -1,
    success								    = 0
};

export enum eAuthResult {
  not_completed = -1,
  accept = 0,
  reject = 1
  // TODO: another results can be added there
};

export enum eOttPermission {
    keys	= 0,
    backup	= 1
};

export enum eStartAuthResult {
    not_required        = -2,
    not_supported       = -1,
    ott_hexed           = 0,
    webauthn            = 1,
    mfa                 = 2
};

export enum eRsAuthResult {
    fail_by_invalid_parameters				= -7,
    fail_by_service_temporary_not_valid		= -6,
    fail_by_access_denied					= -5,
    fail_by_token_expired					= -4,
    fail_by_token_invalid					= -3,
    fail_by_session_not_found				= -2,
    fail_by_session_expired					= -1,
    success									= 0
};

export enum eAuthV2Result {
    fail_by_key_invalid                 = -100,
    fail_by_invalid_parameters			= -8,
    fail_by_service_temporary_not_valid = -7,
    fail_by_access_denied				= -6,
    fail_by_token_expired				= -5,
    fail_by_token_invalid				= -4,
    fail_by_key_not_found				= -3,
    fail_by_session_not_found			= -2,
    fail_by_session_expired				= -1,
    success                             = 0
};

export enum eShadowAckResultV2 {
    fail_by_key_not_found				= -6,
    fail_by_service_temporary_not_valid = -5,
    fail_by_invalid_input_parameters	= -4,
    fail_by_access_denied				= -3,
    fail_by_token_expired				= -2,
    fail_by_token_invalid				= -1,
    success							    = 0
};

export enum eRsKeyLoadResult {
    fail_by_invalid_parameters				= -6,
    fail_by_key_info_not_found				= -5,
    fail_by_service_temporary_not_valid 	= -4,
    fail_by_access_denied					= -3,
    fail_by_token_expired					= -2,
    fail_by_token_invalid					= -1,
    success								    = 0
};

export enum eRsKeyCreateResult {
    fail_by_invalid_parameters				= -6,
    fail_by_key_info_not_found				= -5,
    fail_by_service_temporary_not_valid	    = -4,
    fail_by_access_denied					= -3,
    fail_by_token_expired					= -2,
    fail_by_token_invalid					= -1,
    success								    = 0
};

export enum eKeyCreateV2Result {
    fail_by_invalid_parameters			= -5,
    fail_by_service_temporary_not_valid = -4,
    fail_by_access_denied				= -3,
    fail_by_token_expired				= -2,
    fail_by_token_invalid				= -1,
    success								= 0
};
