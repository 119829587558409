import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EventTransportService} from '../../services/event-transport/event-transport.service';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit, OnDestroy {

  @ViewChild('toastEl')         toastEl: ElementRef;
  @ViewChild('toastWrapper')    toastWrapper: ElementRef;

  public icons = {
    warning: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12" y2="16"></line></svg>`,
    success: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>`,
    info: `<svg viewBox="0 0 32 32" width="32" height="32" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-width="6.25%"><path d="M16 14 L16 23 M16 8 L16 10" /><circle cx="16" cy="16" r="14" /></svg>`,
    error: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-triangle"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12" y2="17"></line></svg>`
  };

  public position = 'south';
  public textAlign = 'center';
  public link = '';
  public message = '';
  public timeout = 5000;
  public el = document.body;
  public rounded = false;
  public type = 'default';
  public debug = false;
  public edge = false;
  public icon = true;
  public closeOnClick = true;

  private prevToast: boolean = false;

  private eventTransportSubscription: Subscription;

  private timer: number;

  constructor(
    private eventTransport: EventTransportService,
    private translate: TranslateService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.eventTransportSubscription = this.eventTransport.on('toast').subscribe(({data}) => {
      this.create(data);
    });
  }

  ngOnDestroy() {
    if (Boolean(this.eventTransportSubscription)) {
      this.eventTransportSubscription.unsubscribe();
    }
  }

  public async create({
                  message = '',
                  position = 'bottom',
                  timeout = 3000,
                  type = 'default',
                  debug = false,
                  icon = true,
                  closeOnClick = true,
                  textAlign = 'center',
                  link = ''
                } = {}) {

    if (this.prevToast) {
      this.destroy();
    }

    this.message = message;
    this.link = link;
    this.position = position;
    this.timeout = timeout;
    this.closeOnClick = closeOnClick;
    this.type = type;
    this.textAlign = textAlign;

    this.message = await this.translate.get(this.message).toPromise();

    if (type) {
      if (icon) {
        this.message = `<span class="native-toast-icon-${type}">${this.icons[type] || ''}</span>${this.message}`
      }
    }

    this.toastEl.nativeElement.innerHTML = '';

    const messageElement = document.createElement('div')
    messageElement.className = 'native-toast-message'
    messageElement.innerHTML = this.message;
    [messageElement].forEach(el => {
      this.toastEl.nativeElement.appendChild(el)
    })

    this.prevToast = true

    this.show();

    if (!debug && timeout) {
      this.hide()
    }
  }

    public onClick()
    {
        if (!!this.link)
        {
            this.router.navigate([this.link]);
        }

        if (this.closeOnClick)
        {
            this.destroy();
        }
    }

  show() {
    setTimeout(() => {
      this.toastWrapper.nativeElement.classList.add('toast-wrapper-shown');
    }, 300);
  }

  hide() {
    if (Boolean(this.timer)) {
      window.clearTimeout(this.timer);
    }

    this.timer = window.setTimeout(() => {
      this.destroy();
    }, this.timeout);
  }

  destroy() {
    if (!this.toastEl) {
      return;
    }

    this.toastWrapper.nativeElement.classList.remove('toast-wrapper-shown');
  }

}
