import {IOrderInfo, ISymbol, ITicker, Market, OrderState} from './market-base.class';
import { IMarketSettingsToken } from "./IMarketSettingsToken";
import HmacSHA256 from 'crypto-js/hmac-sha256';

export class MarketMxc extends Market<IMarketSettingsToken> {

  protected name: string = 'MXC';
  protected icon: string = '/assets/images/exchanges/mxc.svg';

  private accessUrl: string = 'https://www.mexc.com';

  private proxyApi: string = '/api/market/mxc';

  private currencies: string[] = [];
  private symbols: ISymbol[] = [];

  private prefferedCurrencies: string[] = ['USDT', 'JASMY', 'ETH'];

  private allowedSymbols: string[] = [
    "DASH_USDT",
    "EOS_USDT",
    "BTC_USDT",
    "BCH_USDT",
    "ONT_USDT",
    "ETH_USDT",
    "ZEC_USDT",
    "LTC_USDT",
    "TRX_USDT",
    "XLM_USDT",
    "DOGE_USDT",
    "SERO_USDT",
    "OMG_USDT",
    "GRIN_USDT",
    "VSYS_USDT",
    "ARPA_USDT",
    "ALGO_USDT",
    "BNB_USDT",
    "HT_USDT",
    "IRIS_USDT",
    "JASMY_USDT"
  ];

  public async checkConnection(): Promise<boolean> {
      try {
        await this.getBalance('ETH');
        return true;
      } catch (e) {
          throw e;
      }
    // const queryString = this.createQueryString();
    // const endpoint = '/open/api/v2/common/ping';

    // const preSignedText = this.getPreSignedText('GET', endpoint, queryString);

    // // const signature = this.getQuerySignature(preSignedText);

    // const url = `${this.accessUrl}${endpoint}?${queryString}`;

    // try {
    //   const response = await this.http.post(this.proxyApi, {
    //     url,
    //     method: 'GET'
    //   }).toPromise();

    //   if (response['code'] === 200) {
    //     return true;
    //   }

    //   return false;
    // } catch (error) {
    //   console.error(error);

    //   throw error;
    // }
  }

  public async getCurrencies(): Promise<string[]> {
    if (this.currencies.length > 0) {
      return this.currencies;
    }

    const queryString = this.createQueryString();
    const endpoint = '/open/api/v2/market/symbols';

    const preSignedText = this.getPreSignedText('GET', endpoint, queryString);

    // const signature = this.getQuerySignature(preSignedText);

    const url = `${this.accessUrl}${endpoint}?${queryString}`;

    try {
      const response = await this.http.post(this.proxyApi, {
          url,
          method: 'GET'
        }).toPromise();

      if (response['code'] === 200) {
        const symbols = response['data']
          .map(({symbol}) => symbol)
          .filter(_symbol => this.allowedSymbols.includes(_symbol));

        return this.symbolsToCurrencies(symbols);
      } else {
        throw new Error(response['message']);
      }

      return [];
    } catch (error) {
      console.error(error);

      throw error;
    }
  }

  public async getSymbols(_currency: string = ''): Promise<ISymbol[]> {
    const cachedSymbols = this.symbols.filter(_symbol => _symbol.symbol.toLowerCase().includes(_currency.toLowerCase()));

    if (cachedSymbols.length > 0) {
      return cachedSymbols;
    }

    const queryString = this.createQueryString();
    const endpoint = '/open/api/v2/market/symbols';

    const preSignedText = this.getPreSignedText('GET', endpoint, queryString);

    // const signature = this.getQuerySignature(preSignedText);

    const url = `${this.accessUrl}${endpoint}?${queryString}`;

    try {
      const response = await this.http.post(this.proxyApi, {
          url,
          method: 'GET'
        }).toPromise();

      if (response['code'] === 200) {
        return response['data']
          .filter(({symbol}) => this.allowedSymbols.includes(symbol))
          .filter(_symbol => _symbol.symbol.toLowerCase().includes(_currency.toLowerCase()) && _symbol.state === 'ENABLED')
          .map(_symbol => this.parseSymbol(_symbol));
      } else {
        throw new Error(response['message']);
      }

      return [];
    } catch (error) {
      console.error(error);

      throw error;
    }
  }

  public async getBalance(_currency: string): Promise<string> {
    const queryString = this.createQueryString();
    const endpoint = '/open/api/v2/account/info';

    const preSignedText = this.getPreSignedText('GET', endpoint, queryString);

    const signature = this.getQuerySignature(preSignedText);

    const url = `${this.accessUrl}${endpoint}?${queryString}&sign=${signature}`;

    try {
      const response = await this.http.post(this.proxyApi, {
          url,
          method: 'GET'
        }).toPromise();

      if (response['code'] === 200) {
        const wallet = response['data'][_currency.toUpperCase()];

        if (Boolean(wallet)) {
          return wallet['available'];
        }

        return '0.00';
      } else {
        throw new Error(response['message'] || response['msg']);
      }

      return '0.00';
    } catch (error) {
      console.error(error);

      throw error;
    }
  }

  public async getTicker(_symbol: string): Promise<ITicker> {
    const queryString = this.createQueryString({symbol: _symbol.toUpperCase()});
    const endpoint = '/open/api/v2/market/ticker';

    const preSignedText = this.getPreSignedText('GET', endpoint, queryString);

    // const signature = this.getQuerySignature(preSignedText);

    const url = `${this.accessUrl}${endpoint}?${queryString}`;

    try {
      const response = await this.http.post(this.proxyApi, {
          url,
          method: 'GET'
        }).toPromise();

      if (response['code'] === 200) {
        return {
          buy: response['data'][0]['ask'],
          sell: response['data'][0]['bid'],
        };
      } else {
        throw new Error(response['message']);
      }

      return null;
    } catch (error) {
      console.error(error);

      throw error;
    }
  }

  public getOrderInfoUrl(_orderId: string): {url: string, data: any} {
    const endpoint = '/open/api/v2/order/deal_detail';

    const queryString = this.createQueryString({order_id: _orderId});

    const preSignedText = this.getPreSignedText('GET', endpoint, queryString);

    const signature = this.getQuerySignature(preSignedText);

    return {url: `${this.accessUrl}${endpoint}?${queryString}&sign=${signature}`, data: null};
  }

  public async getOrderInfo(_orderId: string, _symbol: string): Promise<IOrderInfo> {
    const endpoint = '/open/api/v2/order/deal_detail';

    const queryString = this.createQueryString({order_id: _orderId});

    const preSignedText = this.getPreSignedText('GET', endpoint, queryString);

    const signature = this.getQuerySignature(preSignedText);

    const url = `${this.accessUrl}${endpoint}?${queryString}&sign=${signature}`;

    try {
      const response = await this.http.post(this.proxyApi, {
          url,
          method: 'GET'
        }).toPromise();

      if (response['code'] === 200) {

        const orderData = response['data'];

        if (!orderData || orderData.length === 0) {
          throw new Error('ORDER_NOT_FOUND')
        }

        return this.parseOrderInfo(orderData[0]);
      } else {
        throw new Error(response['message']);
      }

      return null;
    } catch (error) {
      console.error(error);

      throw error;
    }
  }

  public async getOrdersHistory(_symbol): Promise<IOrderInfo[]> {
    if (!_symbol) {
      return [];
    }

    const endpoint = '/open/api/v2/order/deals'//'/open/api/v2/order/list';

    const queryString = this.createQueryString({
      symbol: _symbol.toUpperCase(),
      // states: 'FILLED',
      // trade_type: 'ALL'
    });

    const preSignedText = this.getPreSignedText('GET', endpoint, queryString);

    const signature = this.getQuerySignature(preSignedText);

    const url = `${this.accessUrl}${endpoint}?${queryString}&sign=${signature}`;

    try {
      const response = await this.http.post(this.proxyApi, {
          url,
          method: 'GET'
        }).toPromise();

      if (response['code'] === 200) {

        const ordersData = response['data'];

        return ordersData.map(_orderData => this.parseOrderInfo(_orderData));
      } else {
        throw new Error(response['message']);
      }

      return null;
    } catch (error) {
      console.error(error);

      throw error;
    }
  }

  public async createOrder(_symbol: string, _amount: string, _type: 'buy' | 'sell'): Promise<string> {
    const endpoint = '/open/api/v2/order/place';
    const ticker = await this.getTicker(_symbol);
    const price = _type === 'sell' ? ticker.sell : ticker.buy;
    let amount = _type === 'sell' ? Number(_amount) : (Number(_amount) / Number(price));

    if (_type !== 'sell') {
      amount = amount * 0.99;
    }

    const body = {
      symbol: _symbol.toUpperCase(),
      price: _type === 'sell' ? ticker.sell : ticker.buy,
      trade_type: _type === 'sell' ? 'ASK' : 'BID',
      order_type: 'IMMEDIATE_OR_CANCEL',
      quantity: amount.toString()
    };

    const queryString = this.createQueryString();

    const preSignedText = this.getPreSignedText('POST', endpoint, queryString);

    const signature = this.getQuerySignature(preSignedText);

    const url = `${this.accessUrl}${endpoint}?${queryString}&sign=${signature}`;

    try {
      const response = await this.http.post(this.proxyApi, {
          url,
          method: 'POST',
          body: body
        }).toPromise();

      if (response['code'] === 200) {
        return response['data'];
      } else {
        throw new Error(this.parseOrderResponse(response));
      }
    } catch (error) {
      // console.error(error);

      throw error;
    }
  }

  private parseOrderInfo(_orderInfo: any): IOrderInfo {
    const sendAmount = _orderInfo['trade_type'] === 'BID' ? _orderInfo['amount'] : _orderInfo['quantity'];
    const getAmount = _orderInfo['trade_type'] === 'BID' ? _orderInfo['quantity'] : _orderInfo['amount'];
    const currencies = this.coinsService.parseExchangeSymbol(_orderInfo['symbol']);

    return {
      orderId: _orderInfo['order_id'],
      sendAmount: sendAmount,
      getAmount: getAmount,
      fee: null,
      finishedAt: _orderInfo['create_time'].toString(),
      price: _orderInfo['price'],
      state: 'complete',
      type: _orderInfo['trade_type'],
      symbol: _orderInfo['symbol'],
      currencySend: _orderInfo['trade_type'] === 'BID' ? currencies[1] : currencies[0],
      currencyGet: _orderInfo['trade_type'] === 'BID' ? currencies[0] : currencies[1]
    };
  }

  private parseOrderState(_orderStatus: string): OrderState {
    if (['NEW'].includes(_orderStatus)) {
      return 'processing';
    }

    if (['FILLED', 'PARTIALLY_FILLED'].includes(_orderStatus)) {
      return 'complete';
    }

    if (['CANCELED', 'PARTIALLY_CANCELED'].includes(_orderStatus)) {
      return 'canceled';
    }

    return 'failed';
  }

  private parseOrderResponse(_response: any) {
    if (Boolean(_response['message'])) {
      return _response['message'];
    }

    if (_response['code'] === 30004) {
      return 'EXCHANGE.ERRORS.INSUFFICIENT-FUNDS';
    }

    return 'FORM.ERRORS.SMTH-WENT-WRONG';
  }

  private createQueryString(_params: Object = {}) {
    const defaultParams = {
      api_key: this.settings.access_key,
      req_time: Math.round(Date.now() / 1000)
    };

    const params = {...defaultParams, ..._params};

    const ordered = Object.keys(params).sort();

    let result = '';

    ordered.forEach(_key => {
      result += `&${_key}=${params[_key]}`;
    })

    return result.slice(1);
  }

  private getPreSignedText(_method: string, _endpoint: string, _queryParams: string) {
    return `${_method}\n${_endpoint}\n${_queryParams}`;
  }

  private getQuerySignature(_query: string) {
    const hash = HmacSHA256(_query, this.settings.secret_key);

    return hash.toString();
  }

  private symbolsToCurrencies(_symbols: string[]): string[] {
    const currenciesSet: Set<string> = new Set();

    _symbols.forEach(_symbol => {
      const currencies = this.coinsService.parseExchangeSymbol(_symbol);
      currenciesSet.add(currencies[0]);
      currenciesSet.add(currencies[1]);
    });

    let currencies = Array.from(currenciesSet);

    currencies = currencies.sort((a, b) => {
      const indexA = this.prefferedCurrencies.indexOf(a);
      const indexB = this.prefferedCurrencies.indexOf(b);
      if (indexA === -1) return 0;

      if (indexA < indexB) return -1;

      if (indexA !== -1 && indexB === -1) return -1;

      if (indexA > indexB) return 1;

      return 0;
    });

    return currencies;
  }

  private parseSymbol(_symbolData: any): ISymbol {
    const currencies = this.coinsService.parseExchangeSymbol(_symbolData.symbol);
    return {
      symbol: _symbolData.symbol,
      base_currency: currencies[0],
      quote_currency: currencies[1],
      precision: _symbolData['quantity_scale']
    };
  }
}
