import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { SHA3 } from 'sha3';
import secrets from './secrets';
import {environment} from '../../../environments/environment';

interface IKeyCreateResponce
{
    id: string,
    shadow: string,
}

interface IKeyAddResponce
{
    shadow: string,
}

@Injectable({
  providedIn: 'root'
})
export class AppPasswordService {

  private readonly TEMP_PASSWORD = '/;PLjzx)n5h-<)pBj~!."=G==*aZ>e%,';

  constructor(
    private http: HttpClient
  ) {
  }

  public async create(): Promise<{
    keyID: string,
    password: string
  }> {
    //create verifier
    const verifier = this.createVerifier();
    // create verifier hash
    const verifierHash = await this.verifierToBase64Url(verifier);//await this.verifierToBase64Url(verifier);
    // receive keyID, keyShadowClient
    const response  = <IKeyCreateResponce>await this.http.post('/api/key/create', {challenge: verifierHash}).toPromise();
    // send verificator to hyperid public endpoint
    const secondShadow = await this.receiveShadow(response.id, verifier);

    // Shamir`s shared secred from keyShadowClient and keyShadowHyper
    const keyHex = secrets.combine([atob(response.shadow), atob(secondShadow.shadow)]);
    const key = secrets.hex2str(keyHex);

    return {keyID: response.id, password: key};
  }

  public async get(_keyID: string): Promise<string> {
    const verifier = this.createVerifier();
    // create verifier hash
    const verifierHash = await this.verifierToBase64Url(verifier);//await this.verifierToBase64Url(verifier);
    // receive keyID, keyShadowClient
    const response = <IKeyAddResponce>await this.http.post('/api/key/load', {challenge: verifierHash, key_id: _keyID}).toPromise();
    // send verificator to hyperid public endpoint
    const secondShadow = await this.receiveShadow(_keyID, verifier);

    // Shamir`s shared secred from keyShadowClient and keyShadowHyper
    const keyHex = secrets.combine([atob(response.shadow), atob(secondShadow.shadow)]);
    const key = secrets.hex2str(keyHex);

    return key;
  }

  public getDeprecated() {
    return this.TEMP_PASSWORD;
  }

  private async verifierToBase64Url(str:string) {
    const hash = new SHA3(512);

    hash.update(str);

    return hash.digest('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/g, '');
  }

  private async receiveShadow(_keyId:string, _challenge:string): Promise<any> {
    const data = {"id":Number(_keyId),"verifier":_challenge, "request_id": 1};

    // const response = await this.http.post(`${environment.hyper_id.resourceServer}/key/shadow-req`, data, {headers: {}}).toPromise();
    const response = await fetch(`${environment.hyper_id.resourceServer}/key/shadow-req`, {
      method: 'POST',
      body: JSON.stringify(data)
    });

    const responseData = await response.json();

    return responseData;
  }

  private createVerifier(length = 64) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
