import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import {HttpClient} from "@angular/common/http";
import { ICurrency } from '../../../server/api/currencies/currency-model';
import { ICurrencyAddRequest } from '../../../common/ICurrencyAddRequest';

export interface IStorageCurrency {
  symbol: string;
  name: string;
  icon: string;
  contract?: {
    [network: string]: {
      address: string,
      abi: string
    }
  };
}

@Injectable({
  providedIn: 'root'
})
export class CurrencyApi {

  constructor(
    private storage: StorageService,
    private http: HttpClient
  ) { }

    //Gets all currencies
    public async get(_walletAddress?: string): Promise<ICurrency[]>
    {
        try
        {
            return <ICurrency[]>await this.http.get('/api/currency').toPromise();
        }
        catch (error)
        {
            return [];
        }
        // const currencies = await this.storage.getOption('currencies').toPromise();
        //
        // return currencies[_walletAddress] || [];
    }

    public async getWalletCurrency(_cryptoWalletId: string, _walletAddress: string, _platform: string): Promise<IStorageCurrency[]>
    {
        try
        {
            const walletCurrencies = <{ currency: IStorageCurrency }[]>await this.http.get(`/api/wallet-currency/${_cryptoWalletId}/${_platform}/${_walletAddress}`).toPromise();

            return walletCurrencies.map(({ currency }) => currency);
        }
        catch (error)
        {
            return [];
        }
    }

    public async removeWalletCurrency(_cryptoWalletId: string,_currencyId: number)
    {
        try
        {
            return await this.http.post(`/api/wallet-currency/remove`, {
                cryptoWalletId: _cryptoWalletId,
                currencyId: _currencyId
            }).toPromise();
        }
        catch (error)
        {
            return undefined;
        }
    }

  public async setWalletCurrency(_cryptoWalletId: string, _walletAddress: string, _currencyId: number) {
    try {
      return await this.http.post(`/api/wallet-currency`, {
        cryptoWalletId: _cryptoWalletId,
        walletAddress: _walletAddress,
        currencyId: _currencyId
      }).toPromise();
    } catch (error) {
      return undefined;
    }
  }

    public async add(name: string, symbol: string, platform: string, contract: string): Promise<ICurrency>
    {
        let body: ICurrencyAddRequest =
        {
            symbol,
            name,
            platform,
            contract
        };

        try
        {
            const result = <ICurrency>await this.http.post(`/api/currency/add`, body).toPromise();
            return result;
        }
        catch (error)
        {
            return undefined;
        }
    }

  public async createBaseWalletCurrencies(_cryptoWalletId: string, _walletAddress: string, _platform: string) {
    try {
      const walletCurrencies = <{currency: IStorageCurrency}[]>await this.http.post(`/api/wallet-currency/create-base-currencies`, {
        cryptoWalletId: _cryptoWalletId,
        walletAddress: _walletAddress,
        platform: _platform
      }).toPromise();

      return walletCurrencies.map(({currency}) => currency);
    } catch (error) {
      return undefined;
    }
  }
}
