import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { HttpClient } from "@angular/common/http";

export interface IStorageWallet
{
  crypto_wallet: string;
  address: string;
  platform: string;
}

@Injectable({
  providedIn: 'root'
})
export class WalletsApi
{

  constructor(
    private storage: StorageService,
    private http: HttpClient
  ) { }

  public async getWallets(_cryptoWalletId: string): Promise<IStorageWallet[]>
  {
    try
    {
      const response = <IStorageWallet[]>await this.http.get(`/api/wallet/list/${_cryptoWalletId}`).toPromise();
      // console.debug(`${nowToTimeStr()} << /api/wallet/list/${_cryptoWalletId} => ${JSON.stringify(response)}`);

      return response;
    }
    catch (error)
    {
      console.error(error);
      return [];
    }

    // const wallets: IStorageWallet[] = await this.storage.getOption('wallets').toPromise();
    //
    // return Array.isArray(wallets) ? wallets.filter(({ crypto_wallet }) => crypto_wallet === _cryptoWalletId) : [];
  }

  public async setWallet(_wallet: IStorageWallet)
  {
    try
    {
      const response = await this.http.post(`/api/wallet`, {
        cryptoWalletId: _wallet.crypto_wallet,
        address: _wallet.address,
        platform: _wallet.platform
      }).toPromise();

      return response;
    }
    catch (error)
    {
      return undefined;
    }
    // let wallets: IStorageWallet[] = (await this.storage.getOption('wallets').toPromise()) || [];
    //
    // wallets = wallets.filter(wallet => wallet.address !== _wallet.address && wallet.crypto_wallet !== _wallet.crypto_wallet);
    //
    // wallets  = [...wallets, _wallet];
    //
    // return this.storage.setOption('wallets', wallets);
  }
}
