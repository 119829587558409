import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { Subject, Subscription } from "rxjs";
import { IVersionResponce } from "../../../common/IVersionResponce";
// @ts-ignore
import {version} from '../../../../package.json';

@Injectable({
  providedIn: 'root'
})
export class eVersionService
{
    public updateAvailable: Subject<string> = new Subject();

    constructor(private swUpdate: SwUpdate, private httpClient: HttpClient)
    {
    
    }

    private availableSubscrition:Subscription;

    ngOnDestroy()
    {
        this.availableSubscrition?.unsubscribe();
    }

    private async OnUpdateAvailable()
    {
        let responce = <IVersionResponce>await this.httpClient.get('/api/version').toPromise();
        if (responce.version === version)
        {
            return;
        }

        //we dont check less/bigger. ask @ZHUK
        this.updateAvailable.next(responce.version);
    }

    public Update():Promise<void>
    {
        return this.swUpdate.activateUpdate();
    }

    public CheckUpdate()
    {
        this.availableSubscrition = this.swUpdate.available.subscribe(this.OnUpdateAvailable.bind(this));

        if (this.swUpdate.isEnabled)
        {
            this.swUpdate.checkForUpdate();
        }
    }
}
