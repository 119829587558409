import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {sleep} from '../utils';
import {eMfaTransactionStatus, eAuthResult} from '../../../common/request_enums';
import {IActionInfo} from 'src/common/action-interfaces';
import {eActionType} from 'src/common/actions/action-types';
import { ConfigService } from '../config/config.service';
import { environment } from 'src/environments/environment';
import { ActionService } from '../action/action.service';
import { nowToTimeStr } from 'src/common/index';
import { IVerificationResult } from 'src/common/auth_types';

@Injectable({
  providedIn: 'root'
})
export class MfaService {

  private mfaAvailable: boolean = null;

  private canceledForCode;

  constructor(
    private http: HttpClient,
    private actionService: ActionService,
    private configService: ConfigService
  ) {
  }

  public async isMfaAvailable() {
    if (this.mfaAvailable === null) {
      const config = await this.configService.get();
      const mfa = (config.authentication.mfa === undefined) ?
            environment.authentication.mfa :
            config.authentication.mfa;
  
      this.mfaAvailable = mfa && await this.availabilityCheck();
    }

    return this.mfaAvailable;
  }

  public async verificateFor(actionBody?: IActionInfo): Promise<IVerificationResult>
  {
    if(!actionBody) actionBody = await this.actionService.prepareAction(eActionType.Authorization);
    this.canceledForCode = undefined;

    console.log(`${nowToTimeStr()} >> checkVerificationStatus ${JSON.stringify(actionBody)}`);
    const result = await this.checkVerificationStatus(actionBody);
    console.log(`${nowToTimeStr()} << checkVerificationStatus > eAuthResult:${eAuthResult[result.authResult]}`);

    return result;
  }

  public stopVerification(actionCode) {
    try {
      this.http.post('/api/mfa/transaction/cancel', {code: actionCode}).toPromise();
      this.canceledForCode = actionCode;
    } catch (error) {
      console.error(error);
    }
  }

  private async availabilityCheck(): Promise<boolean>
  {
    try
    {
      const response = await this.http.get('/api/mfa/availability-check').toPromise();
      return Boolean(response);
    } catch (error)
    {
      console.error(error);
      return false;
    }
  }


  private async checkVerificationStatus(actionBody): Promise<IVerificationResult>
  {
    let response;
    let status: eMfaTransactionStatus;
    let authResult: eAuthResult;
    let transactionId: number;

    try{
      response = await this.http.post('/api/mfa/transaction/status-get', actionBody).toPromise();
      status = <eMfaTransactionStatus>response['transaction_status'];
      authResult = <eAuthResult>response['transaction_complete_result'];
      transactionId = Number(response['transaction_id']);
    }
    catch(error){
      status = eMfaTransactionStatus.failed;
      authResult = eAuthResult.not_completed;
    }

    if (status == eMfaTransactionStatus.pending) {
      await sleep(1500);
      
      if (this.canceledForCode && this.canceledForCode == response['code'])
      {
        this.canceledForCode = undefined;
        return { authResult: eAuthResult.reject } as IVerificationResult;
      }
      return this.checkVerificationStatus(actionBody);
    }

    return { authResult, transactionId } as IVerificationResult;
  }

  public async waitForOtt(actionInfo: IActionInfo): Promise<any>
  {
      let status: eMfaTransactionStatus;
      try{
          console.debug(`>> /api/key-v2/mfa-auth-check ${JSON.stringify(actionInfo)}`);
          const response = await this.http.post('/api/key-v2/mfa-auth-check', {action: actionInfo}).toPromise();
          console.debug(`<< /api/key-v2/mfa-auth-check ${JSON.stringify(response)}`);
          status = <eMfaTransactionStatus>response['transaction_status'];

          if (status == eMfaTransactionStatus.pending) {
              await sleep(1500);
              if(this.canceledForCode && this.canceledForCode == response['code']){
                this.canceledForCode = undefined;
                return {
                  transaction_status: eMfaTransactionStatus.completed,
                  transaction_complete_result: eAuthResult.reject
                };
              }
              return this.waitForOtt(actionInfo);
          }

          return response;
      }
      catch(error){
          console.error(error);
          throw error;
      }
  }  
}
