import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { ethers } from "ethers";
import { NetworksService } from "../networks/networks.service";
import { sleep } from "../utils";
import { ProviderFactoryService } from '../provider/provider-factory.service';
import { eBlockChainExplorerFactory } from './explorers/blockchain-explorer';
import { IBlockChainExplorer } from './explorers/types';
import { HttpClient } from '@angular/common/http';

export interface IFee
{
  amount: string;
  priorityAmount: string;
  time: number;
}

export interface IOracleFee
{
  safe: IFee,
  propose: IFee,
  fast: IFee
}

@Injectable({
  providedIn: 'root'
})
export class UtilsService
{

  private readonly explorer: IBlockChainExplorer;

  constructor(
    private networksService: NetworksService,
    private providerFactory: ProviderFactoryService,
    private http: HttpClient
  )
  {
    this.explorer = eBlockChainExplorerFactory.CreateEtherExplorer(providerFactory);
  }

  public async getFee()
  {
    const gasOracle = await this.getGasOracle();

    const fee: IOracleFee = {
      safe: {
        amount: Math.round(Number(gasOracle.safeGasPrice.toString())).toString(),
        priorityAmount: '1',
        time: 0//await this.getEstimationOfConfirmTime(Math.round(Number(gasOracle.safeGasPrice.toString()) * 1.05).toString())
      },
      propose: {
        amount: Math.round(Number(gasOracle.proposeGasPrice.toString())).toString(),
        priorityAmount: Math.round(Math.max(1, Number(gasOracle.proposeGasPrice) - Number(gasOracle.safeGasPrice))).toString(),
        time: 0//await this.getEstimationOfConfirmTime(Math.round(Number(gasOracle.proposeGasPrice.toString().toString()) * 1.05).toString())
      },
      fast: {
        amount: Math.round(Number(gasOracle.fastGasPrice.toString())).toString(),
        priorityAmount: Math.round(Number(gasOracle.fastGasPrice) - Number(gasOracle.safeGasPrice)).toString(),
        time: 0//await this.getEstimationOfConfirmTime(Math.round(Number(gasOracle.fastGasPrice.toString().toString()) * 1.1).toString())
      }
    }

    return fee;
  }

  private async getGasOracle()
  {
      const response = <any>await this.http.get(`https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=${environment.etherScanApi}`).toPromise();
        console.log(response)
      return {
        safeGasPrice: response.result.SafeGasPrice,
        proposeGasPrice: response.result.ProposeGasPrice,
        fastGasPrice: response.result.FastGasPrice,
      };
  }

  public gweiToWei(_gwei: string)
  {
    const gasBigNumber = ethers.utils.parseUnits(_gwei.toString(), 'gwei');
    return ethers.utils.formatUnits(gasBigNumber, 'wei').split('.')[0];
  }

  public gweiToEther(_gwei: string)
  {
    const gasBigNumber = ethers.utils.parseUnits(_gwei.toString(), 'gwei');
    return ethers.utils.formatEther(gasBigNumber);
  }

  public formatUints(_number, uint?: string)
  {
    return ethers.utils.formatUnits(_number, uint);
  }

  public parseUints(_number: string, uint?: string)
  {
    return ethers.utils.parseUnits(_number, uint);
  }

  private async getEstimationOfConfirmTime(_gas: string)
  {
    try
    {
      const wei = this.gweiToWei(_gas);

      // avoid etherscan server rate limit (5 requests per sec)
      await sleep(300);

      return await this.explorer.GetEstimatationOfConfirmTime(wei);
    }
    catch (e)
    {
      return 0;
    }
  }
}
