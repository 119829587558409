import { ContractInterface, ethers, Signer, UnsignedTransaction, utils } from 'ethers';
import { Injector } from '@angular/core';
import { BscNetworks } from '../networks/networks.service';
import { ProviderFactoryService } from '../provider/provider-factory.service';
import { Wallet } from './wallet.service';
import { parseToFirstNonZero } from '../utils';
import { TransactionDirection, TransactionStatus } from './types';
import { MarketsFactoryService } from '../markets/markets-factory.service';
import { eBlockChainExplorerFactory } from './explorers/blockchain-explorer';
import { UtilsService } from './utils.service';


import
{
    ITransactionResponceEx,
    IBlockChainExplorer,
    ITransactionParsed,
    ITransactionFee,
} from './explorers/types';
import { Currency } from './currency';
import { Bep20ContractFactory } from '../currencies-factory/bep20-contract-factory.service';
import { ePlatforms } from '../../../common/networks';

export enum eBSCCurrenciesSymbols
{
    BNB = 'BNB',
}

export interface IBSCContract
{
    address: string;
    abi?: ContractInterface;
}

export function BSCCurrenciesFactory(
    _symbol: string,
    injector: Injector,
    _wallet: Wallet,
    _address: string,
    _contractData?: IBSCContract
): Currency
{
    switch (_symbol)
    {
        case eBSCCurrenciesSymbols.BNB:
            return new BSCCurrency(injector, _wallet, _address);
            break;
        default:
            return new BSCContractCurrency(
                injector,
                _wallet,
                _address,
                _contractData
            );
    }
}

export class BSCCurrency extends Currency
{
    protected name = 'BNB';
    protected signer: Signer;
    protected platform = 'BSC';

    protected etherUtils: UtilsService;

    constructor(
        protected injector: Injector,
        _wallet: Wallet,
        _address: string
    )
    {
        super(injector, _wallet, _address, 'BNB');

        this.etherUtils = this.injector.get(UtilsService);

        this.baseSymbol = 'BNB';
    }

    public getContractAddress(): string
    {
        return null;
    }

    protected async init()
    {
        this.provider = await this.providerFactoryService.getProvider('bsc');

        this.signer = new ethers.VoidSigner(this.address, this.provider as any);

        this.setupStateSubject.next(true);
    }

    public getFeeUnit(): string
    {
        return 'gwei';
    }

    public async getTransactionByHash(_hash: string)
    {
        return await <ITransactionResponceEx><any>this.provider.getTransaction(_hash);
    }

    public async sendTransaction(
        _txData: ethers.providers.TransactionRequest,
        _txInfo: { symbol: string, value: string, to?: string; }
    ): Promise<any>
    {
        try
        {
            const txInfo = { ..._txInfo, symbol: _txInfo.symbol || this.symbol };

            delete _txData.maxFeePerGas;
            delete _txData.maxPriorityFeePerGas;

            return await this.wallet.sendTransaction(_txData, txInfo);
        } catch (error)
        {
            console.error(error);
            throw error;
        }
    }

    public async checkTransaction(_to: string, _value: string): Promise<any>
    {
        const txRequest = this.signer.checkTransaction({
            to: _to,
            value: ethers.utils.parseEther(_value.toString()),
        });

        return txRequest;
    }

    public async getBalance(): Promise<any>
    {
        return this.getBaseCurrencyBalance();
    }

    public async getBaseCurrencyBalance(): Promise<any>
    {
        const balance = await this.signer.getBalance();

        return utils.formatEther(balance);
    }

    public preparePrice(_price: string): { price: any; }
    {
        return {
            price: utils.parseUnits(_price.split('.')[0], 'gwei'),
        };
    }

    public prepareFee(
        _price: string,
        _limit: string
    ): { price: any; limit: any; }
    {
        return {
            price: utils.parseUnits(_price.split('.')[0], 'gwei'),
            limit: utils.parseUnits(_limit.split('.')[0], 'wei'),
        };
    }

    public async populateTransaction(
        _transaction: UnsignedTransaction,
        ...args: any[]
    ): Promise<any>
    {
        delete _transaction.maxPriorityFeePerGas;

        const gas = await this.signer.estimateGas(_transaction);

        const tx = await this.signer.populateTransaction({..._transaction, gasLimit: gas});

        return tx;
    }

    public async getPrice(
        _currency = 'usd'
    ): Promise<{ price: string; price_change: string; }>
    {
        return await this.pricesService.getPrice('BNB', _currency, ePlatforms.BSC);
    }

    public async getHistory(): Promise<ITransactionResponceEx[]>
    {
        let history = await this.wallet.getHistory();

        history = history.filter((_tx) =>
        {
            if (_tx.data) return _tx.data === '0x';

            return true;
        });

        this.history = history;

        return history;
    }

    public getTransactionDirection(_tx: ITransactionResponceEx): TransactionDirection
    {
        const address = this.address.toLowerCase();
        const from = _tx.from.toLowerCase();
        const to = _tx.to.toLowerCase();

        if (address === to) return 'to';

        if (address === from) return 'from';

        return 'none';
    }

    public getTransactionStatus(_tx: ITransactionResponceEx): TransactionStatus
    {
        if (_tx.confirmations > 0)
        {
            return 'success';
        }

        return 'processing';
    }

    public async getFeeLimit(
        _data: { to: string; value: string; },
        _type = 'string'
    ): Promise<string | any>
    {
        const tx = await this.signer.populateTransaction({
            from: this.address,
            to: _data.to,
            value: ethers.utils.parseUnits(_data.value),
        });

        if (_type === 'string')
        {
            return ethers.utils.formatUnits(tx.gasLimit, 'wei');
        }
        if (_type === 'BigNumber')
        {
            return tx.gasLimit;
        }

        return tx.gasLimit;
    }

    //todo remove me
    public getFee(_feeLimit: string, _feeAmount: string): string
    {
        const feeBigNumber = ethers.utils.parseUnits(_feeAmount, 'gwei');
        const feeEth = ethers.utils.formatEther(feeBigNumber);

        return parseToFirstNonZero(Number(feeEth) * Number(_feeLimit), 4);
    }

    public async parseTransaction(_tx: ITransactionResponceEx)
    {
        //calculate fee in USD
        const value = ethers.utils.formatEther(_tx.value);
        const price = (await this.pricesService.getPrice('BNB', 'usd', ePlatforms.BSC)).price;
        const valuePrice = Number(value) * Number(price);

        let feeData: ITransactionFee = <any>{};

        if (_tx.gasPrice)
        {

            //gasUsed can be empty, use gas limit instead.
            let gasUsed = _tx.gasUsed ?? _tx.gasLimit;

            if (!gasUsed)
            {
                gasUsed = (await this.populateTransaction(_tx, false)).gasLimit;
            }

            if (!ethers.BigNumber.isBigNumber(gasUsed)) gasUsed = ethers.BigNumber.from(gasUsed);

            let feeBN = gasUsed.mul(_tx.gasPrice);
            let feeString = parseToFirstNonZero(ethers.utils.formatEther(feeBN), 4);

            const feeAmount = ethers.utils.formatUnits(_tx.gasPrice, 'gwei');
            const fiatFee = parseToFirstNonZero(Number(price) * Number(feeString), 2);
            feeData = {
                feeAmount: feeAmount,
                fee: feeString,
                format: 'gwei',
                fiatFee: fiatFee,
            };
        }

        return {
            ..._tx,
            amount: {
                value: ethers.utils.formatEther(_tx.value),
                price: valuePrice,
            },
            fee: feeData,
        };
    }

    public getWallet(): Wallet
    {
        return this.wallet;
    }

    public async checkFeeAvailability(_feeData: number): Promise<{ result: boolean, amount?: string; }>
    {
        const oracleFee = await this.etherUtils.getFee();
        const gasLimit = _feeData;

        const minEth = (Number(oracleFee.propose.amount) * gasLimit) / 10 ** 9;

        const balanceBN = await this.signer.getBalance();

        const balance = utils.formatEther(balanceBN);

        if (Number(balance) < minEth)
        {
            return { result: false, amount: `${minEth} BNB` };
        }

        return { result: true };
    }

    public isFeeRequired(): boolean
    {
        return false;
    }

    public async estimateFeePrice(to: string, _value: string, _transfer: boolean = true): Promise<string>
    {
        return '10';
        const checkedTx = await this.checkTransaction(to, _value);
        const populatedTx = await this.populateTransaction(checkedTx, _transfer);

        return ethers.utils.formatUnits(populatedTx.gasPrice, 'gwei');
    }
}


export class BSCContractCurrency extends BSCCurrency
{
    protected name = '';
    private contract: ethers.Contract;

    private contractData;
    private marketService: MarketsFactoryService;
    private contractFactory: Bep20ContractFactory;

    private readonly explorer: IBlockChainExplorer;

    constructor(
        protected injector: Injector,
        _wallet: Wallet,
        _address: string,
        _contractData: IBSCContract,
    )
    {
        super(injector, _wallet, _address);

        this.contractData = _contractData;

        this.explorer = eBlockChainExplorerFactory.CreateBSCExplorer(injector.get(ProviderFactoryService));
        this.marketService = this.injector.get(MarketsFactoryService);
    }

    protected async init()
    {
        this.contractFactory = this.injector.get(Bep20ContractFactory);
        this.provider = await this.providerFactoryService.getProvider('bsc');

        this.signer = new ethers.VoidSigner(this.address, this.provider as any);

        await this.generateContract(this.contractData);

        this.setupStateSubject.next(true);
    }

    public getContractAddress(): string | null
    {
        return this.contract.address;
    }

    public getContractInstance(): ethers.Contract
    {
        return this.contract;
    }

    public async checkTransaction(_to: string, _value: string): Promise<any>
    {
        const decimals = await this.contract.decimals();
        const txRequest = this.signer.checkTransaction({
            to: _to,
            value: ethers.utils.parseUnits(_value, decimals),
        });

        return txRequest;
    }

    public async getBalance(): Promise<any>
    {
        const balance = await this.contract.balanceOf(this.address);

        const decimals = await this.contract.decimals();
        return utils.formatUnits(balance, decimals);
    }

    public async getPrice(
        _currency = 'usd'
    ): Promise<{
        price: string;
        price_change: string;
    }>
    {
        if (!this.contract || !this.contract.address)
        {
            return {
                price: '0',
                price_change: '0',
            };
        }

        return await this.pricesService.getPrice(
            this.symbol.toUpperCase(),
            _currency,
            ePlatforms.BSC,
            this.contract.address.toLowerCase()
        );
    }

    public async populateTransaction(
        _transaction: UnsignedTransaction,
        _transfer = true
    ): Promise<any>
    {
        let unsignedTx = _transaction;
        if (this.contract != null && _transfer)
        {
            unsignedTx = await this.contract.populateTransaction.transfer(
                _transaction.to,
                _transaction.value
            );
        }

        delete _transaction.maxPriorityFeePerGas;

        // const gas = await this.signer.estimateGas(_transaction);

        const tx = <any>await this.signer.populateTransaction({...unsignedTx});

        if (!tx.value)
        {
            tx.value = ethers.utils.parseEther('0');
        }

        return tx;
    }

    public async parseTransaction(_tx: ITransactionResponceEx, _parseData = true): Promise<ITransactionParsed>
    {
        const tx = { ..._tx };

        if (!!tx.data && _parseData)
        {

            const parsedTxData = await this.parseTxData(tx);

            tx.value =
                parsedTxData.tokens ||
                parsedTxData.value ||
                parsedTxData.amount ||
                parsedTxData._value ||
                tx.value;
            tx.to = parsedTxData.to || parsedTxData._to || parsedTxData.recipient || tx.to;
        }

        const txRes = await super.parseTransaction(tx);

        if (!!this.contract && !!this.contract.decimals && !!this.symbol)
        {
            let decimals = 18;
            
            try
            {
                decimals = await this.contract.decimals();
            }
            catch (err){
                console.error(err);
            }

            txRes.amount.value = ethers.utils.formatUnits(tx.value, decimals);

            const price = (
                await this.pricesService.getPrice(this.symbol, 'usd', ePlatforms.BSC)
            ).price;
            const valuePrice = Number(txRes.amount.value) * Number(price);

            txRes.amount.price = valuePrice;
        }

        return txRes;
    }

    public async parseTxData(_txData: ITransactionResponceEx): Promise<any>
    {
        if (this.abi != null)
        {
            try
            {
                const contractInterface = new ethers.utils.Interface(this.abi);

                const data = contractInterface.parseTransaction(<any>_txData);

                return data.args;
            } catch (error)
            {
                console.error(error);

                return _txData;
            }
        }

        return _txData;
    }

    public async getTransactionByHash(_hash: string): Promise<ITransactionResponceEx>
    {
        const tx = await super.getTransactionByHash(_hash);

        const parsedTxData = await this.parseTxData(tx);

        tx.value = parsedTxData.tokens || parsedTxData.value || parsedTxData.amount;
        tx.to = parsedTxData.to || parsedTxData._to || parsedTxData.recipient;

        return tx;
    }

    public async getFeeLimit(
        _data: { to: string; value: string; },
        _type = 'string'
    ): Promise<string | any>
    {
        const decimals = await this.contract.decimals();
        const contractPopulatedTx = await this.contract.populateTransaction.transfer(
            _data.to,
            ethers.utils.parseUnits(_data.value, decimals)
        );

        contractPopulatedTx['gasPrice'] = ethers.utils.parseUnits(
            '200',
            'gwei'
        );
        let tx;
        try
        {
            tx = await this.signer.populateTransaction(contractPopulatedTx);
        } catch (e)
        {
            tx = contractPopulatedTx;
            tx.from = this.address.toLowerCase();
            tx.gasLimit = ethers.utils.parseUnits('50000', 'wei');
        }

        if (_type === 'string')
        {
            return ethers.utils.formatUnits(tx.gasLimit, 'wei');
        }
        if (_type === 'BigNumber')
        {
            return tx.gasLimit;
        }

        return tx.gasLimit;
    }

    public async getHistory(): Promise<ITransactionResponceEx[]>
    {
        // TODO: add-wallets-page pages
        let history = await this.explorer.GetHistory(
            this.address,
            this.contract.address,
            { page: 1, maxPageSize: 100 });

        this.history = history;

        return history;
    }

    private async generateContract(_contractData: IBSCContract)
    {

        const network = await this.networksService.getNetwork('bsc');
        const { contract, symbol, name, abi } = await this.contractFactory.create(
            _contractData.address,
            BscNetworks[network],
            _contractData.abi
        );

        this.contract = contract;
        this.symbol = symbol;
        this.name = name;
        this.abi = abi;
    }
}
