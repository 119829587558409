import {Inject, Injectable, OnDestroy, PLATFORM_ID} from '@angular/core';
import {isPlatformServer, Location} from "@angular/common";
import {CryptoWalletService} from "../crypto-wallet/crypto-wallet.service";

@Injectable({
  providedIn: 'root'
})
export class RoutesService implements OnDestroy {

  private readonly LS_KEY = 'routes-add-wallets-page';

  constructor(
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    if (isPlatformServer(this.platformId)) return;

    this.setupLSData();
    this.setPreauthRoute(null);
  }

  private setupLSData() {
    const lsData = localStorage.getItem(this.LS_KEY);

    if (!lsData) {
      const defaultData = {
        preauthRoute: null
      };

      localStorage.setItem(this.LS_KEY, JSON.stringify(defaultData))
    }
  }

  public setPreauthRoute(_route: string) {
    this.setData({preauthRoute: _route});
  }

  public saveRoute(_name: string, _route: string) {
    this.setData({[_name]: _route});
  }

  public getSavedRoute(_name: string): string {
    let lsData = JSON.parse(localStorage.getItem(this.LS_KEY));

    return lsData[_name];
  }

  public removeSavedRoute(_name: string): void {
    let lsData = JSON.parse(localStorage.getItem(this.LS_KEY));

    delete lsData[_name];

    localStorage.setItem(this.LS_KEY, JSON.stringify(lsData));
  }

  public getPreauthRoute(): string {
    let lsData = JSON.parse(localStorage.getItem(this.LS_KEY));

    return lsData.preauthRoute;
  }

  private setData(_data: any) {
    let lsData = JSON.parse(localStorage.getItem(this.LS_KEY));

    lsData = {
      ...lsData,
      ..._data
    };

    localStorage.setItem(this.LS_KEY, JSON.stringify(lsData));
  }

  ngOnDestroy() {
    this.setPreauthRoute(null);
  }
}
