import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { Buffer, createECDH, createCipheriv, createDecipheriv } from 'browser-crypto';

// **************************************************************************
//#region define constants
// **************************************************************************
const KEY_LENGTH = 128;
const IV_LENGTH = 16;
const CKEY_LENGTH = 32;
const HKEY_LENGTH = 64;

const CKEY_NAME: string = "AES-CBC";
const CKEY_USES: KeyUsage[] = ["encrypt", "decrypt"];

const HKEY_NAME: string = "HMAC";
const HKEY_HASH: string = "SHA-512";
const HKEY_USES: KeyUsage[] = ["sign", "verify"];
//#endregion

@Injectable({
    providedIn: 'root'
})
export class CryptoService
{

    constructor(private storage: StorageService) { }

    // **************************************************************************
    //#region public functions
    // **************************************************************************

    ///==========================================================================
    /// Encrypt and sign 'message' text with 'key'.
    /// * Arguments:
    /// - key - a complex key as 'ArrayBuffer' of KEY_LENGTH (128) length.
    ///   Consists of 'iv'[IV_LENGTH] + 'cKey'[CKEY_LENGTH] + 'hKey'[HKEY_LENGTH] + tail[16] parts.
    /// - message - source text as a string.
    /// * Return array of two values:
    /// 1.Encrypted sequence of two parts as ciphertext + signature;
    /// 2.Length of signature, usually equal to HKEY_LENGTH (64).
    public async encrypt(key: ArrayBuffer | string, message: string): Promise<[string, number]>
    {
        const iv = this.get_IV(key);
        const cKey = this.get_CKey(key);
        const ciphertext = await this.encryptMessage(iv, cKey, message);

        const hKey = this.get_HKey(key);
        const signature = await this.signMessage(hKey, ciphertext);

        const encrypted = new ArrayBuffer(ciphertext.byteLength + signature.byteLength);
        const encryptedArr = new Uint8Array(encrypted);
        const ciphertextArr = new Uint8Array(ciphertext);
        const signatureArr = new Uint8Array(signature);
        encryptedArr.set(ciphertextArr, 0);
        encryptedArr.set(signatureArr, ciphertext.byteLength);

        return [this.arrayBufferToString(encrypted), signature.byteLength];
    }

    ///==========================================================================
    /// Verify and decrypt 'data' with 'key'.
    /// * Arguments:
    /// - key - a complex key as 'ArrayBuffer' of KEY_LENGTH (128) length.
    ///   Consists of 'iv'[IV_LENGTH] + 'cKey'[CKEY_LENGTH] + 'hKey'[HKEY_LENGTH] + tail[16] parts.
    /// - data - ArrayBuffer with encrypted content.
    /// - signLength - length of signature at the end of data contents.
    /// * Return array of two values:
    /// 1.Decrypetd text message as string;
    /// 2.Boolean result of signature verification.
    /// * Errors: if data cannot be decrypted - text message has undefined value;
    public async decrypt(key: ArrayBuffer | string, _data: ArrayBuffer | string, signLength: number): Promise<[string, boolean]>
    {
        const data = typeof _data === 'string' ? this.stringToArrayBuffer(_data) : _data;
        const ciphertext = data.slice(0, data.byteLength - signLength);
        const signature = data.slice(data.byteLength - signLength);

        const hKey = this.get_HKey(key);
        const verified = await this.verifyMessage(hKey, signature, ciphertext);
        // if(!verified)
        // {
        //   return [undefined, false];
        // }

        const iv = this.get_IV(key);
        const cKey = this.get_CKey(key);
        try
        {
            const decrypted = await this.decryptData(iv, cKey, ciphertext);
            const message = this.getDecodedText(decrypted);
            return [message, verified];
        } catch (e)
        {
            return [undefined, verified];
        }
    }

    public async decryptDeprecated(_key, _data, _iv): Promise<string>
    {
        const textEncoder = new TextEncoder();

        const key = await window.crypto.subtle.importKey(
            'raw',
            textEncoder.encode(_key).buffer,
            { name: 'AES-CBC' },
            true,
            ['encrypt', 'decrypt']
        );

        const decrypted = await window.crypto.subtle.decrypt(
            {
                name: "AES-CBC",
                iv: _iv
            },
            key,
            _data
        );

        const textDecoder = new TextDecoder();

        return textDecoder.decode(decrypted);
    }

    private ab2str(buf): string
    {
        return String.fromCharCode.apply(null, new Uint8Array(buf));
    }

    private str2ab(str)
    {
        const buf = new ArrayBuffer(str.length);
        const bufView = new Uint8Array(buf);
        for (let i = 0, strLen = str.length; i < strLen; i++)
        {
            bufView[i] = str.charCodeAt(i);
        }
        return buf;
    }

    private to_hex_array = null;
    private to_byte_map = null;

    private computeMaps()
    {
        this.to_hex_array = [];
        this.to_byte_map = {};
        for (var ord = 0; ord <= 0xff; ord++)
        {
            var s = ord.toString(16);
            if (s.length < 2)
            {
                s = "0" + s;
            }
            this.to_hex_array.push(s);
            this.to_byte_map[s] = ord;
        }
    }

    public unhex(str_hexed)
    {
        if (this.to_hex_array == null || this.to_byte_map == null) this.computeMaps();
        var length2 = str_hexed.length;
        if ((length2 % 2) != 0)
        {
            throw "hex string must have length a multiple of 2";
        }
        var length = length2 / 2;
        var result = new Uint8Array(length);
        for (var i = 0; i < length; i++)
        {
            var i2 = i * 2;
            var b = str_hexed.substring(i2, i2 + 2);
            result[i] = this.to_byte_map[b];
        }
        return result;
    }

    public async exportKeyToJwk(privateKey)
    {
        const exported = await window.crypto.subtle.exportKey(
            "jwk",
            privateKey
        );
        return exported;
    }

    public importKeyFromBase642(_keyData)
    {
        return Buffer.from(_keyData, 'base64');
    }

    public async importKeyFromJwk(_keyData, _namedCurve)
    {
        const privateKey = await window.crypto.subtle.importKey(
            'jwk',
            _keyData,
            {
                name: "ECDH",
                namedCurve: _namedCurve
            },
            true,
            ['deriveBits']);
        return privateKey;
    }

    public exportKeyToBase642(_key: Uint8Array)
    {
        return Buffer.from(_key).toString('base64');
    }

    public async exportKeyToBase64(publicKey)
    {
        const exported = await window.crypto.subtle.exportKey(
            "raw",
            publicKey
        );
        const strExported = this.ab2str(exported);
        const base64Exported = window.btoa(strExported);
        return base64Exported;
    }

    public async importKeyFromBase64(keyBase64: string, _namedCurve: "P-256" | "P-384" | "P-521")
    {
        const strDer = window.atob(keyBase64);
        const binDer = this.str2ab(strDer);
        const imported = await window.crypto.subtle.importKey(
            "raw",
            binDer,
            {
                name: "ECDH",
                namedCurve: _namedCurve
            },
            true,
            []
        );

        return imported;
    }

    public generateEcKey2(_namedCurve: "P-256" | "P-384" | "P-521" | "secp521r1"): { privateKey: Uint8Array, publicKey: Uint8Array; }
    {
        const ecdh = createECDH(_namedCurve);

        ecdh.generateKeys();

        return {
            privateKey: ecdh.getPrivateKey(),
            publicKey: ecdh.getPublicKey()
        };
    }

    public generateEcKey(_namedCurve: "P-256" | "P-384" | "P-521" | "secp521r1")
    {
        return window.crypto.subtle.generateKey(
            {
                name: "ECDH",
                namedCurve: _namedCurve
            },
            true,
            ['deriveBits']
        );
    }

    public deriveSecretKey2(privateKey, publicKey, _namedCurve: "P-256" | "P-384" | "P-521" | "secp521r1")
    {
        const ecdh = createECDH(_namedCurve);

        ecdh.setPrivateKey(privateKey);

        const secret = ecdh.computeSecret(publicKey);

        return secret;
    }

    public async deriveSecretKey(privateKey, publicKey,
        namedCurve: "P-256" | "P-384" | "P-521",
        length)
    {
        const derived = await window.crypto.subtle.deriveBits(
            <EcdhKeyDeriveParams>{
                name: "ECDH",
                namedCurve: namedCurve,
                public: publicKey
            },
            privateKey,
            length
        );

        return derived;
    }

    public getIV(sharedBuf)
    {
        return sharedBuf.slice(0, 16);
    }

    public async getSecret(sharedBuf)
    {
        const keyMaterial = sharedBuf.slice(16, 66);
        const key = await window.crypto.subtle.digest("SHA-256", keyMaterial);
        const secretKey = await window.crypto.subtle.importKey(
            "raw",
            key,
            {
                name: "AES-CTR"
            },
            true,
            ["encrypt", "decrypt"]
        );

        return secretKey;
    }

    public async encryptAesCtr(key, iv, message: string)
    {
        const textEncoder = new TextEncoder();
        const encoded = textEncoder.encode(message);
        const encrypted = await window.crypto.subtle.encrypt(
            <AesCtrParams>{
                name: "AES-CTR",
                counter: iv,
                length: 64
            },
            key,
            encoded
        );

        return encrypted;
    }

    public async decryptAesCtr(key, iv, ciphertext)
    {
        const textEncoder = new TextDecoder();
        const decrypted = await window.crypto.subtle.decrypt(
            <AesCtrParams>{
                name: "AES-CTR",
                counter: iv,
                length: 64
            },
            key,
            ciphertext
        );
        const encoded = textEncoder.decode(decrypted);
        return encoded;
    }

    public async decryptOtt(_encrypted: string): Promise<string>
    {
        const userKey = await this.storage.get('userkey_secret');
        if (!userKey) throw new Error('USER_SECRET_NOT_FOUND');

        const iv = await this.storage.get('userkey_iv');
        if (!userKey) throw new Error('USER_IV_NOT_FOUND');

        const ott_encrypted = this.unhex(_encrypted);
        const ott_decrypted = await this.decryptAesCtr(userKey, iv, ott_encrypted);
        return ott_decrypted;
    }

    /// obsolete: not use
    public async generateKeyAsync(size)
    {
        const key = await window.crypto.subtle.generateKey(
            {
                name: "RSA-OAEP",
                modulusLength: size,
                publicExponent: new Uint8Array([1, 0, 1]),
                hash: "SHA-256"
            },
            true,
            ["encrypt", "decrypt"]
        );
        return key;
    }

    ///==========================================================================
    /// Generate ArrayBuffer with 'size' length with random  content as a key.
    /// - By default 'size' equal to KEY_LENGTH (128)
    private generateKey(size)
    {
        const keyBuff = size ? new ArrayBuffer(size) : new ArrayBuffer(KEY_LENGTH);
        const uint8arr = new Uint8Array(keyBuff);
        window.crypto.getRandomValues(uint8arr);
        return keyBuff;
    }

    ///==========================================================================
    /// Get initial vector from a complex key
    private get_IV(_key)
    {
        const key = typeof _key === 'string' ? this.stringToArrayBuffer(_key) : _key;

        const begPos = 0;
        const endPos = begPos + IV_LENGTH;
        return key.slice(begPos, endPos);
    }

    ///==========================================================================
    /// Get crypto key from a complex key
    private get_CKey(_key: ArrayBuffer | string)
    {
        const key = typeof _key === 'string' ? this.stringToArrayBuffer(_key) : _key;

        const begPos = IV_LENGTH;
        const endPos = begPos + CKEY_LENGTH;
        return key.slice(begPos, endPos);
    }

    ///==========================================================================
    /// Get signing key from a complex key
    private get_HKey(_key)
    {
        const key = typeof _key === 'string' ? this.stringToArrayBuffer(_key) : _key;

        const begPos = IV_LENGTH + CKEY_LENGTH;
        const endPos = begPos + HKEY_LENGTH;
        return key.slice(begPos, endPos);
    }

    ///==========================================================================
    /// Encrypt text message with pair of 'iv' and 'ckey' using CKEY_NAME(AES-CBC) algorithm.
    /// * Arguments:
    /// - iv - initial vector as 'ArrayBuffer' of IV_LENGTH (16) length.
    /// - ckey - crypto key as 'ArrayBuffer' of CKEY_LENGTH (32) length.
    /// - message - source text as a string.
    /// * Return:
    ///   Ciphertext as byte sequence of 'ArrayBuffer' type.
    private async encryptMessage(iv, cKeyRaw, message)
    {
        const encoded = this.getEncodedText(message);

        const cKey = await window.crypto.subtle.importKey(
            "raw",
            cKeyRaw,
            CKEY_NAME,
            true,
            CKEY_USES
        );

        return await window.crypto.subtle.encrypt(
            {
                name: CKEY_NAME,
                iv
            },
            cKey,
            encoded
        );
    }

    ///==========================================================================
    /// Decrypt and decode ciphertext with pair of 'iv' and 'ckey' using CKEY_NAME(AES-CBC) algorithm.
    /// * Arguments:
    /// - iv - initial vector as 'ArrayBuffer' of IV_LENGTH (16) length.
    /// - ckey - crypto key as 'ArrayBuffer' of CKEY_LENGTH (32) length.
    /// - ciphertext - byte sequence of encrypted source text of 'ArrayBuffer' type.
    /// * Return:
    ///   Source text message as a string.
    /// * Exceptions: all exception is suppressed and returns the undefined value.
    private async decryptMessage(iv, cKeyRaw, cipherText)
    {
        try
        {
            const decrypted = await this.decryptData(iv, cKeyRaw, cipherText);
            const message = this.getDecodedText(decrypted);
            return message;
        } catch {
            return undefined;
        }
    }

    // **************************************************************************
    /// Sign message with sign key.
    /// Return: signature as byte sequence of 'ArrayBuffer' type.
    private async signMessage(hKeyRaw, data)
    {
        const hkey = await window.crypto.subtle.importKey(
            "raw",
            hKeyRaw,
            {
                name: HKEY_NAME,
                hash: { name: HKEY_HASH }
            },
            true,
            HKEY_USES
        );

        return await window.crypto.subtle.sign(
            HKEY_NAME,
            hkey,
            data
        );
    }

    // **************************************************************************
    /// Verify data with sign key and signature content.
    /// Return: 'true' or 'false'
    private async verifyMessage(hKeyRaw: JsonWebKey, signature, data)
    {
        const hkey = await window.crypto.subtle.importKey(
            "raw",
            hKeyRaw,
            {
                name: HKEY_NAME,
                hash: { name: HKEY_HASH }
            },
            true,
            HKEY_USES
        );

        return await window.crypto.subtle.verify(
            HKEY_NAME,
            hkey,
            signature,
            data
        );
    }
    //#endregion

    // **************************************************************************
    //#region private functions
    // **************************************************************************

    ///==========================================================================
    /// Just decrypt ciphertext with pair of 'iv' and 'ckey' using CKEY_NAME(AES-CBC) algorithm.
    /// * Arguments:
    /// - iv - initial vector as 'ArrayBuffer' of IV_LENGTH (16) length.
    /// - ckey - crypto key as 'ArrayBuffer' of CKEY_LENGTH (32) length.
    /// - ciphertext - byte sequence of encrypted source text of 'ArrayBuffer' type.
    /// * Return: Decrypted sequence of bytes as 'ArrayBuffer'.
    ///   To get the source text, use function 'decryptMessage'.
    /// * Exceptions: SyntaxError, TypeError, InvalidAccessError and OperationError;
    ///  (details: https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/importKey#exceptions
    ///            https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/decrypt#exceptions)
    private async decryptData(iv, cKeyRaw, cipherText)
    {
        const cKey = await window.crypto.subtle.importKey(
            "raw",
            cKeyRaw,
            CKEY_NAME,
            true,
            CKEY_USES
        );

        const decrypted = await window.crypto.subtle.decrypt(
            {
                name: CKEY_NAME,
                iv
            },
            cKey,
            cipherText
        );

        return decrypted;
    }

    ///==========================================================================
    /// Encode message text to a byte sequence
    public getEncodedText(messageText)
    {
        let enc = new TextEncoder();
        return enc.encode(messageText);
    }

    ///==========================================================================
    /// Decode message text from a byte sequence
    public getDecodedText(decoded)
    {
        let dec = new TextDecoder();
        return dec.decode(decoded);
    }
    //#endregion

    public stringToArrayBuffer(str)
    {
        var buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
        var bufView = new Uint16Array(buf);
        for (var i = 0, strLen = str.length; i < strLen; i++)
        {
            bufView[i] = str.charCodeAt(i);
        }
        return buf;
    }

    public arrayBufferToString(buf)
    {
        return String.fromCharCode.apply(null, new Uint16Array(buf));
    }
}
