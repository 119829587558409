import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: '/wallets'},
  {
    path: 'sign-in',
    loadChildren: () => import('./public/sing-in/sing-in.module').then(m => m.SingInModule)
  },
  {
    path: 'mfa',
    canActivate: [AuthGuard],
    loadChildren: () => import('./public/mfa/mfa.module').then(m => m.MfaModule)
  },
  {
    path: 'authenticate',
    canActivate: [AuthGuard],
    loadChildren: () => import('./public/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'unlock',
    canActivate: [AuthGuard],
    loadChildren: () => import('./public/unlocking/unlocking.module').then(m => m.UnlockingModule)
  },
  {
    path: '',
    // canActivate: [LockGuard],
    loadChildren: () => import('./private/private.module').then(m => m.PrivateModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: "top",
    // enableTracing: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
