import {eAuthV2Result} from 'src/common/request_enums';
import {nowToTimeStr} from 'src/common';
import {environment} from '../../../environments/environment';

export const BACKUP_SERVICE_API    = environment.hyper_id.backup_api;

export enum SERVICE_BACKUP_METHODS
{
    "store" = 'store',
    "load" = 'load'
}

export async function GetOttV2(_http, keyId: number): Promise<string>
{
    console.debug(`${nowToTimeStr()} >> /api/backup-ott/v2(key_id:${keyId})`);
    const responce = await _http.post('/api/backup-ott/v2', { key_id: keyId }).toPromise();
    console.debug(`${nowToTimeStr()} << /api/backup-ott/v2(responce[${JSON.stringify(responce).length}])`);

    const result = <eAuthV2Result>responce['result'];
    if(result != eAuthV2Result.success){
        throw new Error(`GetOttV2 failed: ${eAuthV2Result[result]}`);
    }

    return responce['ott_hexed'];
}
