export enum ePlatforms
{
    ETHEREUM = 'Ethereum',
    BSC = 'BSC'
}

export enum eChainId
{
    ETHEREUM_MAINNET = 1,
    ETHEREUM_KOVAN = 42,
    ETHEREUM_ROPSTEN = 3,
    BSC_MAINNET = 56,
    BSC_TESTNET = 97
}

export const TESTNET_NETWORKS: Record<ePlatforms, string> = {
    [ePlatforms.ETHEREUM]: 'kovan',
    [ePlatforms.BSC]: 'testnet'
};


export class Networks
{
    public static getChainIdByNetworkName(_platform: ePlatforms, _networkName: string): eChainId
    {
        const networkName = _networkName.toLowerCase();

        if (_platform === ePlatforms.ETHEREUM)
        {
            if (networkName === 'mainnet') return eChainId.ETHEREUM_MAINNET;
            if (networkName === 'kovan') return eChainId.ETHEREUM_KOVAN;
            if (networkName === 'ropsten') return eChainId.ETHEREUM_ROPSTEN;
        }

        if (_platform === ePlatforms.BSC)
        {
            if (networkName === 'mainnet') return eChainId.BSC_MAINNET;
            if (networkName === 'testnet') return eChainId.BSC_TESTNET;
        }

        throw new Error(`Cannot detect chain id by platform "${_platform}" and network "${_networkName}"`);
    }

    public static getPlatformByChainId(_chainId: number): ePlatforms
    {
        switch (_chainId)
        {
            case eChainId.ETHEREUM_MAINNET:
            case eChainId.ETHEREUM_KOVAN:
            case eChainId.ETHEREUM_ROPSTEN:
                return ePlatforms.ETHEREUM;
            case eChainId.BSC_MAINNET:
            case eChainId.BSC_TESTNET:
                return ePlatforms.BSC;
        }
    }
}
