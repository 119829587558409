import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ReplaySubject} from 'rxjs';
import {take} from 'rxjs/operators';

export type TConfig = {
  "client_key": {
    "id": string,
    "named_curve": "P-256" | "P-384" | "P-521" | "secp521r1",
    "expiration_min": number,
  },
  "authentication": {
    biometric?: boolean,
    mfa?: boolean
  },
  "currencies_order": {
    "default": {platform: string, symbol: string}[],
    "user_flag": {
      [key: string]: string[]
    }
  },
  "currencies": {
    "hide_if_empty_balance": string[]
    "add_if_not_empty_balance": string[],
    "fillter_hide_zero_balance": string[]
  },
  "dapps": {
    "bonuses": {
        [key: string]: {
            "bonuses": {
                "title": string,
                "id": string,
                "type": string
            }[],
            "network": string
        }
    },
    [key: string]: any
  },
  "airdrop": {
    [key: string]: boolean
  }
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private config: TConfig;

  private configSubject: ReplaySubject<TConfig> = new ReplaySubject(1);

  constructor(
    private http: HttpClient
  ) {
    this.loadConfig();
  }

  private async loadConfig() {
    const response = <TConfig>await this.http.get(`/assets/data/config.json?t=${Date.now()}`).toPromise();

    this.config = response;
    this.configSubject.next(this.config);
  }

  public async get(): Promise<TConfig> {
    return this.configSubject.pipe(take(1)).toPromise();
  }
}
