import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Contract, ContractInterface } from 'ethers';
import { ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { eEthereumNetworks } from '../networks/networks.service';
import { ProviderFactoryService } from '../provider/provider-factory.service';
import { sleep } from '../utils';
import { eBlockChainExplorerFactory } from '../wallets/explorers/blockchain-explorer';
import { eRejectFailReason, IBlockChainExplorer } from '../wallets/explorers/types';
import { BaseContractFactory } from './contract-factory-base';

export interface IErc20ContractData {
    contract: Contract;
    symbol?: string;
    name?: string;
    abi?: any;
}

@Injectable({
    providedIn: 'root',
})
export class Erc20ContractFactory extends BaseContractFactory {
    private data: {
        [address: string]: {
            [network: string]: ReplaySubject<IErc20ContractData>;
        };
    } = {};

    private readonly explorer:IBlockChainExplorer;

    private currenciesData: ReplaySubject<{
        [address: string]: { name: string; symbol: string };
    }> = new ReplaySubject(1);

    private readonly ERC20_ABI = [
        {
            constant: true,
            inputs: [],
            name: 'name',
            outputs: [
                {
                    name: '',
                    type: 'string',
                },
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: false,
            inputs: [
                {
                    name: '_spender',
                    type: 'address',
                },
                {
                    name: '_value',
                    type: 'uint256',
                },
            ],
            name: 'approve',
            outputs: [
                {
                    name: '',
                    type: 'bool',
                },
            ],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'totalSupply',
            outputs: [
                {
                    name: '',
                    type: 'uint256',
                },
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: false,
            inputs: [
                {
                    name: '_from',
                    type: 'address',
                },
                {
                    name: '_to',
                    type: 'address',
                },
                {
                    name: '_value',
                    type: 'uint256',
                },
            ],
            name: 'transferFrom',
            outputs: [
                {
                    name: '',
                    type: 'bool',
                },
            ],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'decimals',
            outputs: [
                {
                    name: '',
                    type: 'uint8',
                },
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [
                {
                    name: '_owner',
                    type: 'address',
                },
            ],
            name: 'balanceOf',
            outputs: [
                {
                    name: 'balance',
                    type: 'uint256',
                },
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'symbol',
            outputs: [
                {
                    name: '',
                    type: 'string',
                },
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: false,
            inputs: [
                {
                    name: '_to',
                    type: 'address',
                },
                {
                    name: '_value',
                    type: 'uint256',
                },
            ],
            name: 'transfer',
            outputs: [
                {
                    name: '',
                    type: 'bool',
                },
            ],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            constant: true,
            inputs: [
                {
                    name: '_owner',
                    type: 'address',
                },
                {
                    name: '_spender',
                    type: 'address',
                },
            ],
            name: 'allowance',
            outputs: [
                {
                    name: '',
                    type: 'uint256',
                },
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            payable: true,
            stateMutability: 'payable',
            type: 'fallback',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    name: 'owner',
                    type: 'address',
                },
                {
                    indexed: true,
                    name: 'spender',
                    type: 'address',
                },
                {
                    indexed: false,
                    name: 'value',
                    type: 'uint256',
                },
            ],
            name: 'Approval',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    name: 'from',
                    type: 'address',
                },
                {
                    indexed: true,
                    name: 'to',
                    type: 'address',
                },
                {
                    indexed: false,
                    name: 'value',
                    type: 'uint256',
                },
            ],
            name: 'Transfer',
            type: 'event',
        },
    ];

    constructor(
        private injector: Injector,
        private providerFactoryService: ProviderFactoryService,
        private http: HttpClient
    ) {
        super();
        this.explorer = eBlockChainExplorerFactory.CreateEtherExplorer(providerFactoryService);
        this.loadCurrenciesData();
    }

    public async create(
        _address: string,
        _network: string,
        _abi?: ContractInterface
    ): Promise<IErc20ContractData> {
        const address = _address.toLowerCase();

        if (Boolean(this.data[address]?.[_network])) {
            return await this.data[address][_network].pipe(take(1)).toPromise();
        }

        if (!this.data[address]) this.data[address] = {};

        this.data[address][_network] = new ReplaySubject(1);

        const abi = _abi || (await this.loadContractABI(address));

        const provider = await this.providerFactoryService.getProvider(
            'ethereum'
        );

        const contract = new Contract(address, abi, provider);

        const contractData = await this.getContractData(address);

        if (!!contractData)
        {
            this.data[address][_network].next({ contract, ...contractData, abi });
            return { contract, ...contractData, abi };
        }

        try {
            let [symbol = null, name = null] = await Promise.all([
                contract.symbol(),
                contract.name(),
            ]);

            const contractData = { contract, symbol, name, abi };

            this.data[address][_network].next(contractData);

            return contractData;
        } catch (e) {
            console.error(e);
            this.data[address][_network].next({ contract });
            return { contract };
        }
    }

    private async loadContractABI(_address: string): Promise<ContractInterface>
    {
        try
        {
            let abi = await this.explorer.GetContractABI(_address);
            
            if (!JSON.parse(abi).some(_item => ['symbol', 'name'].includes(_item.name))) return this.ERC20_ABI;

            return abi;
        }
        catch (e)
        {
            //todo 403
            //todo max retry limit
            if (e == eRejectFailReason.FAIL_BY_LIMIT_REACHED)
            {
                const SLEEP_BEFORE_NEXT_TRY_API_LIMIT_MS = 200;
                await sleep(SLEEP_BEFORE_NEXT_TRY_API_LIMIT_MS);

                return this.loadContractABI(_address);
            }
            else
            {
                //todo
            }
        }

        return this.ERC20_ABI;
    }

    private async loadCurrenciesData() {
        const response = <any>(
            await this.http
                .get(`/assets/data/currencies.json?t=${Date.now()}`)
                .toPromise()
        );

        this.currenciesData.next(response);
    }

    private getContractData(
        _address
    ): Promise<{ name: string; symbol: string }> {
        return this.currenciesData
            .pipe(
                map((_data) => _data[_address]),
                take(1)
            )
            .toPromise();
    }
}

