import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MarketsFactoryService } from '../markets/markets-factory.service';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { eMarketType } from '../markets/eMarketName';
import { ePlatforms } from '../../../common/networks';

const preventMarketPrice = [
    '0x2b46b3a32984520f47a194a52863a77f29ca83e1',
    '0xaff4481d10270f50f203e0763e2597776068cbc5',
    '0x935099adf26ea8f7e84a175c930d814f05b2d637',
    '0x81bd4b8f03ce0f1d0cdecfc1a4549c5f524a23e6',
    '0x7dd223b4138060da1f72f7858ae78e7213ac8b51',
    '0xbfbea8db7866eda7720741b092f24cadc644b980',
    '0x07fe3d031e29d497fbcd9be13d6ed5a174d43ad1',
    '0xf56382698fd39d5fad8de8e1467dc483e6bd2a62',
];

export interface IPrice {
    price: string;
    price_change: string;
}

@Injectable({
    providedIn: 'root',
})
export class PricesService {
    private currencyMarketData: { [key: string]: ReplaySubject<IPrice> } = {};

    private prices: {
        [key: string]: { [fiat: string]: ReplaySubject<IPrice> };
    } = {};

    private http: HttpClient;
    private marketService: MarketsFactoryService;

    private pricesData: ReplaySubject<{
        [address: string]: { type: string; [key: string]: any };
    }> = new ReplaySubject(1);

    private readonly DEFAULT_PRICE: IPrice = { price: '0', price_change: null };

    constructor(private injector: Injector) {
        this.http = this.injector.get(HttpClient);
        this.marketService = this.injector.get(MarketsFactoryService);

        this.loadPricesData();
    }

    public async getPrice(
        _symbol: string,
        _fiat: string,
        _platform: ePlatforms,
        _contractAddress?: string
    ): Promise<{
        price: string;
        price_change: string;
    }> {
        const symbol = _symbol.toUpperCase();
        const fiat = _fiat.toLowerCase();
        const contractAddress = !!_contractAddress
            ? _contractAddress.toLowerCase()
            : null;
        const key = (contractAddress || symbol).toLowerCase();

        if (!!this.prices[key]?.[fiat]) {
            return this.prices[key][fiat].pipe(take(1)).toPromise();
        }

        if (!this.prices[key]) this.prices[key] = {};

        this.prices[key][fiat] = new ReplaySubject(1);

        const priceSource = await this.pricesData.pipe(take(1)).toPromise();

        const price: IPrice = await this.getMarketData(
            symbol,
            fiat,
            _platform,
            contractAddress,
            priceSource[key]
        );

        this.prices[key][fiat].next(price);

        return this.prices[key][fiat].pipe(take(1)).toPromise();
    }

    private async getMarketData(
        _symbol: string,
        _fiat: string,
        _platform: ePlatforms,
        _contractAddress?: string,
        _priceSource?: any
    ): Promise<IPrice> {
        if (Boolean(preventMarketPrice.includes(_symbol.toUpperCase()))) {
            return this.DEFAULT_PRICE;
        }

        if (
            Boolean(_contractAddress) &&
            Boolean(preventMarketPrice.includes(_contractAddress.toLowerCase()))
        ) {
            return this.DEFAULT_PRICE;
        }

        try {
            if (!_priceSource) {
                return this.getCoingeckoData(_symbol, _fiat, _platform, _contractAddress);
            }

            if (_priceSource != null && _priceSource.source === 'exchange') {
                return this.getExchangeData(
                    _symbol,
                    _priceSource.market,
                    _priceSource[_fiat]
                );
            }

            if (_priceSource != null && _priceSource.source === 'coingecko') {
                return this.getCoingeckoData(
                    _symbol,
                    _fiat,
                    _platform,
                    _priceSource.address
                );
            }

            if (_priceSource != null && _priceSource.source === 'kaizen') {
                return this.getKaizenData(
                    _priceSource.endpoint,
                    _priceSource.key
                );
            }
        } catch (error) {
            return this.DEFAULT_PRICE;
        }

        return this.DEFAULT_PRICE;
    }

    private async getCoingeckoData(
        _symbol: string,
        _fiat: string,
        _platform: ePlatforms,
        _contractAddress?: string
    ): Promise<IPrice> {
        try {
            const url = this.getCoingeckoUrl(_symbol, _platform, _contractAddress);

            const response = await this.http.get(url).toPromise();

            if (Boolean(response['error'])) {
                return this.DEFAULT_PRICE;
            } else {
                return {
                    price: response['market_data']['current_price'][_fiat],
                    price_change:
                        response['market_data'][
                            'price_change_percentage_24h_in_currency'
                        ][_fiat],
                };
            }
        } catch (e) {
            return this.DEFAULT_PRICE;
        }
    }

    private getCoingeckoUrl(_symbol: string, _platform: ePlatforms, _contract?: string): string {
        const coinsIds = {
            [ePlatforms.ETHEREUM]: 'ethereum',
            [ePlatforms.BSC]: 'binancecoin'
        }

        const tokensIds = {
            [ePlatforms.ETHEREUM]: 'ethereum',
            [ePlatforms.BSC]: 'binance-smart-chain'
        }


        if (Boolean(_contract)) {
            return `https://api.coingecko.com/api/v3/coins/${tokensIds[_platform]}/contract/${_contract}`;
        }

        return `https://api.coingecko.com/api/v3/coins/${coinsIds[_platform]}?tickers=false&community_data=false&developer_data=false`;

    }

    private async getExchangeData(
        _symbol: string,
        _market: string,
        _pair: string
    ): Promise<IPrice> {
        const market = await this.marketService.getMarket(eMarketType[_market]);

        if (!_market) {
            return this.DEFAULT_PRICE;
        }

        try {
            const prices = await market.getTicker(_pair);

            return {
                price: prices.sell,
                price_change: '0',
            };
        } catch (e) {
            return this.DEFAULT_PRICE;
        }
    }

    private async getKaizenData(
        _endpoint: string,
        _key: string
    ): Promise<IPrice> {
        try {
            const response = await this.http
                .get(`https://kaizen.finance${_endpoint}`)
                .toPromise();

            return {
                price: response[_key],
                price_change: null,
            };
        } catch (e) {
            return this.DEFAULT_PRICE;
        }
    }

    private async loadPricesData() {
        const response = <any>(
            await this.http
                .get(`/assets/data/prices.json?t=${Date.now()}`)
                .toPromise()
        );

        this.pricesData.next(response);
    }
}
