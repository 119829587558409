import { eActionTemplate } from "../request_enums";
import { eActionType, getActionId } from "./action-types";

type value_version_1 = 1;

export type action_version = 0 | value_version_1;

type icon_type = string[];

export interface ITransactionValues_1
{
    amount: string,
    symbol: string,
    icon: icon_type,
    src_label: string,
    src_address: string,
    dst_label: string,
    dst_address: string
}

export interface IExchangeValues_1
{
    market_name: string,
    market_icon: icon_type,
    pay_label: string,
    pay_amount: string,
    pay_icon: icon_type,
    pay_symbol: string,
    get_label: string,
    get_amount: string,
    get_icon: icon_type,
    get_symbol: string
}

export interface IActionValues_1
{
    type: eActionType,
    wallet_name?: string,
    action_icon?: icon_type,
    action_info: string | string[]
}

export interface IValues_1 
{
    transaction?: ITransactionValues_1;
    exchange?: IExchangeValues_1,
    action?: IActionValues_1
};

export function getAction(type: eActionType)
{
    return actions[type];
}

const actions = {
    [eActionType.TransactionStart]: {
        action_id: `${getActionId(eActionType.TransactionStart)}:{0}`,
        values: {
            src_label: 'ACTIONS.TRANSACTION.SRC-TITLE',
            src_address: '{1}',
            dst_label: 'ACTIONS.TRANSACTION.DST-TITLE',
            dst_address: '{2}',
            amount: '{3}',
            symbol: '{4}',
            icon: ['{5}']
        } as ITransactionValues_1
    },
    [eActionType.ExchangeStart]: {
        action_id: `${getActionId(eActionType.ExchangeStart)}:{0}`,
        values: {
            market_name: '{1}',
            market_icon: ['6'],
            pay_label: 'ACTIONS.EXCHANGE.PAY-TITLE',
            pay_amount: '{2}',
            pay_icon: ['{7}'],
            pay_symbol: '{3}',
            get_label: 'ACTIONS.EXCHANGE.GET-TITLE',
            get_amount: '{4}',
            get_icon: ['{8}'],
            get_symbol: '{5}'
        } as IExchangeValues_1
    },
    [eActionType.ExchangeLoad]: {
        action_id: `${getActionId(eActionType.ExchangeLoad)}:{0}`,
        values: {
            type: eActionType.ExchangeLoad,
            action_info: ['{1}', ': ', 'ACTIONS.ACTION.EXCHANGE-LOAD'],
            action_icon: ['{2}']
        } as IActionValues_1
    },
    [eActionType.BackupRemove]: {
        action_id: `${getActionId(eActionType.BackupRemove)}:{0}`,
        values: {
            type: eActionType.BackupRemove,
            wallet_name: '{1}',
            action_info: 'ACTIONS.ACTION.BACKUP-REMOVE',
        } as IActionValues_1
    },
    [eActionType.BackupLoad]: {
        action_id: `${getActionId(eActionType.BackupLoad)}:{0}`,
        values: {
            type: eActionType.BackupLoad,
            wallet_name: '{1}',
            action_info: 'ACTIONS.ACTION.BACKUP-LOAD',
        } as IActionValues_1
    },
    [eActionType.ExportPhrase]: {
        action_id: `${getActionId(eActionType.ExportPhrase)}:{0}`,
        values: {
            type: eActionType.ExportPhrase,
            wallet_name: '{1}',
            action_info: 'ACTIONS.ACTION.EXPORT-PHRASE',
        } as IActionValues_1
    },
    [eActionType.ExportJson]: {
        action_id: `${getActionId(eActionType.ExportJson)}:{0}`,
        values: {
            type: eActionType.ExportJson,
            wallet_name: '{1}',
            action_info: 'ACTIONS.ACTION.EXPORT-JSON',
        } as IActionValues_1
    },
    [eActionType.ExportKey]: {
        action_id: `${getActionId(eActionType.ExportKey)}:{0}`,
        values: {
            type: eActionType.ExportKey,
            wallet_name: '{1}',
            action_info: 'ACTIONS.ACTION.EXPORT-KEY',
        } as IActionValues_1
    },
    [eActionType.WalletRemove]: {
        action_id: `${getActionId(eActionType.WalletRemove)}:{0}`,
        values: {
            type: eActionType.WalletRemove,
            wallet_name: '{1}',
            action_info: 'ACTIONS.ACTION.WALLET-REMOVE',
        } as IActionValues_1
    },
    [eActionType.WalletUpdate]: {
        action_id: `${getActionId(eActionType.WalletUpdate)}:{0}`,
        values: {
            type: eActionType.WalletUpdate,
            wallet_name: '{1}',
            action_info: 'ACTIONS.ACTION.WALLET-UPDATE',
        } as IActionValues_1
    },
    [eActionType.SecurityChange]: {
        action_id: `${getActionId(eActionType.SecurityChange)}`,
        values: {
            type: eActionType.SecurityChange,
            action_info: 'ACTIONS.ACTION.SECURITY-CHANGE',
        } as IActionValues_1
    }
};
