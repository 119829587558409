import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

const cryptoJs  = require('crypto-js');

export function uuidv4(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export function parseExchangeSymbol(_symbol: string): string[] {
  const exchangeSymbol = _symbol.toUpperCase();
  let coins = exchangeSymbol.toUpperCase().split(/-|_|\/|\./g);

  if (coins.length === 2) {
    return coins;
  }

  return [null, null];
}

export function nowToTimeStr(): string{
  const nowDate = new Date(Date.now());
  const formatOptions = <Intl.DateTimeFormatOptions>{
      hour12:	false,
      hour:	'2-digit',
      minute:	'2-digit',
      second:	'2-digit' };
  return nowDate.toLocaleTimeString(undefined, formatOptions);
}

export function getHashHex(message){
  const hash = cryptoJs.MD5(message);
  return hash.toString();
}

export function ShortAddress(value: string, ...args: unknown[])
{
    if (value == null)
    {
        return value;
    }

    const size = value.length;
    const end: number = Number(args[0]) || 4;
    const start: number = Number(args[1]) || 6;

    const SUBSTITUTE  = "..";

    // SHORT_PART can be bigger than actual text by one symbol.
    const REQUIRED_MIN_SIZE = end + start + SUBSTITUTE.length;

    if (size < REQUIRED_MIN_SIZE)
    {
        return value;
    }

    //why toLocaleLowerCase ??
    return (value.slice(0, start) + SUBSTITUTE + value.slice(size - end, size)).toLocaleLowerCase();
}

function hasValue(value: any) {
  return value !== null && value !== undefined;
}

export function getValue<T>(observable: Observable<T>): Promise<T> {
  return observable
    .pipe(
      filter(hasValue),
      first()
    )
    .toPromise();
}
