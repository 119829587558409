export type action_ids =
    'transaction' |
    'exchange-start' |
    'exchange-load' |
    'backup-remove' |
    'backup-load' |
    'export-phrase' |
    'export-json' |
    'export-key' |
    'wallet-remove' |
    'wallet-update' |
    'security-change';

export enum eActionType {
    Authorization,
    TransactionStart,
    ExchangeStart,
    ExchangeLoad,
    BackupRemove,
    BackupLoad,
    ExportPhrase,
    ExportJson,
    ExportKey,
    WalletRemove,
    WalletUpdate,
    SecurityChange
}

export function getActionId(type: eActionType): action_ids
{
    let id: action_ids;
    switch (type)
    {
        case eActionType.TransactionStart:  return 'transaction';
        case eActionType.ExchangeStart:     return 'exchange-start';
        case eActionType.ExchangeLoad:      return 'exchange-load';
        case eActionType.BackupRemove:      return 'backup-remove';
        case eActionType.BackupLoad:        return 'backup-load';
        case eActionType.ExportPhrase:      return 'export-phrase';
        case eActionType.ExportJson:        return 'export-json';
        case eActionType.ExportKey:         return 'export-key';
        case eActionType.WalletRemove:      return 'wallet-remove';
        case eActionType.WalletUpdate:      return 'wallet-update';
        case eActionType.SecurityChange:    return 'security-change';
    }
    return id;
}
