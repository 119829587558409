import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {Router} from "@angular/router";
import {Location} from '@angular/common';
import {AuthService} from '../services/auth/auth.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
    private router: Router,
    private location: Location,
    private injector: Injector
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.startsWith('/api')) return next.handle(req);

    return next.handle(req).pipe(
      catchError((error, caught) => {
        if (error.status == 401 && !this.location.path().includes('/sign-in')) {
          const authService = this.injector.get(AuthService);
          authService.logOut(false);
          // this.router.navigateByUrl('/sign-in');
        }

        return throwError(error);
      })
    )
  }
}
