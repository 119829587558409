import {HttpClient} from '@angular/common/http';
import {IServiceAPIBackupSave, IServiceAPIBackupSaveAck} from 'src/common/backup_common';
import {BACKUP_SERVICE_API, SERVICE_BACKUP_METHODS, GetOttV2} from './backup_common';
import {Injectable} from '@angular/core';
import {CryptoService} from '../crypto/crypto.service';

enum eCreateState {
  CREATE,
  WAIT_CREATE_ACK,
  STORE,
  WAIT_STORE_ACK
}

@Injectable({
  providedIn: 'root'
})
export class eCreateBackupFlow {

  constructor(
    private http: HttpClient,
    private cryptoService: CryptoService) {
  }

  public async create(_keyId:number, _description, _data) {
    const dataEncrypted = JSON.stringify(_data);
    const dataDescription = JSON.stringify(_description);
    return await this.ProcessState_Create(_keyId, dataDescription, dataEncrypted);
  }

  private async ProcessState_Create(_keyId: number, _description: string, _dataEncrypted: string)
  {
      try
      {
          //1) Get Ott
          var challengePlain = await GetOttV2(this.http, _keyId);
          var ott = await this.cryptoService.decryptOtt(challengePlain);

          //2) Save on service
          var backupId = await this.ProcessState_Store(ott, _dataEncrypted);
          return backupId;
        }
        catch (err)
        {
            throw err;
        }
    }

    private async ProcessState_Store(_challenge: string, _dataEncrypted: string): Promise<string>
    {
        // this.state = eCreateState.WAIT_STORE_ACK;
        let body: IServiceAPIBackupSave = {
            challenge: _challenge,
            backup: _dataEncrypted
        };

        var res = <IServiceAPIBackupSaveAck>await this.http.post(`${BACKUP_SERVICE_API}${SERVICE_BACKUP_METHODS.store}`, body).toPromise();
        return res.id;
    }
  }
