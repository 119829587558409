import {Injectable} from '@angular/core';
import {StorageService} from '../storage/storage.service';
import {HttpClient} from "@angular/common/http";
import {nowToTimeStr} from 'src/common/index';
import {ICryptoWallet} from '../../../server/api/crypto-wallets/crypto-wallet-model';
import { ePlatforms } from '../../../common/networks';

@Injectable({
  providedIn: 'root'
})
export class CryptoWalletApi {

  constructor(
    private storage: StorageService,
    private http: HttpClient
  ) {

  }

  public async getCryptoWallets(): Promise<ICryptoWallet[]> {
    try {
      const response = <ICryptoWallet[]>await this.http.get('/api/crypto-wallet').toPromise();
      return response;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  public async setCryptoWallet(_name: string, _phrase: boolean, _backup: boolean = false, _base = false, _platform: ePlatforms = null) {
    try {
      const response = await this.http.post('/api/crypto-wallet', {
        name: _name,
        phrase: _phrase,
        backup: _backup,
        base: _base,
        platform: _platform
      }).toPromise();

      return {...response, platform: _platform};
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  public async setCryptoWalletKeyId(_cryptoWalletId: string, _keyId: number) {
    try {
      const response = await this.http.post('/api/crypto-wallet/key', {
        wallet_id: _cryptoWalletId,
        key_id: _keyId
      }).toPromise();

      console.debug(`${nowToTimeStr()}<< /crypto-wallet/key: update:${_cryptoWalletId} with key: ${_keyId} => response:${JSON.stringify(response)}`)
      return response;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  public async setCryptoWalletBackupId(_cryptoWalletId: string, _backupId: string) {
    try {
      const response = await this.http.post('/api/crypto-wallet/backup', {
        cryptoWalletId: _cryptoWalletId,
        backupId: _backupId
      }).toPromise();

      return response;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  public async getBackupIds(_cryptoWalletId: string): Promise<{backup_id: string, key_id: number}> {
    try {
      const response = <{backup_id: string, key_id: number}>await this.http.get(`/api/crypto-wallet/backup/${_cryptoWalletId}`).toPromise();

      if(!response) return null;
      return response;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  public async revokeBackup(_cryptoWalletId: string) {
    try {
      await this.http.delete(`/api/crypto-wallet/backup/${_cryptoWalletId}`).toPromise();
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  public async deleteCryptoWallet(_id: string): Promise<any> {
    try {
      const response = await this.http.delete(`/api/crypto-wallet/wallet/${_id}`).toPromise();

      return response;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  public async getPrivateDataFromLocal(_cryptoWalletId: string): Promise<string> {
    const cryptoWallets = await this.storage.get('crypto-wallets') || [];

    const cryptoWallet = cryptoWallets.find(_cryptoWallet => _cryptoWallet.cryptoWalletId === _cryptoWalletId);

    return Boolean(cryptoWallet) ? JSON.parse(cryptoWallet.data) : null;
  }

  public async setPrivateDataToLocal(_cryptoWalletId: string, _privateData): Promise<any> {
    let cryptoWallets = await this.storage.get('crypto-wallets') || [];

    cryptoWallets = cryptoWallets.filter(_cryptoWallet => _cryptoWallet.cryptoWalletId !== _cryptoWalletId);

    cryptoWallets.push({
      cryptoWalletId: _cryptoWalletId,
      data: JSON.stringify(_privateData)
    });

    return await this.storage.set('crypto-wallets', cryptoWallets);
  }

  public async delPrivateDataFromLocal(_cryptoWalletId: string): Promise<any> {
    let cryptoWallets = await this.storage.get('crypto-wallets') || [];

    cryptoWallets = cryptoWallets.filter(_cryptoWallet => _cryptoWallet.cryptoWalletId !== _cryptoWalletId);

    return await this.storage.set('crypto-wallets', cryptoWallets);
  }
}
