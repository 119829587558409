import { eOttPermission } from "src/common/request_enums";

export const environment = {
  production: true,
  stage: true,
  etherScanApi: 'FXEXVFISMMRKGJ64GFXRHUT2BCWUK4YF68',
  alchemy: {
    kovan: ['qoVIoN0TZVyoIdsIZJGxuUxnTSfK0lX0'],
    ropsten: ['qoVIoN0TZVyoIdsIZJGxuUxnTSfK0lX0'],
    mainnet: ['tyC39MlQKMjgqUmAy2y5ad05Z8bv-Ca9', 'jJp3JiHUQnj1BWEone9qK162Uosvl7y-', 'p6mDs9bExjHJA5TwQmRKIaYPeZg7UYUs', '_QA-RfVLP8DlzdUIWBX39-MyFgDtkpN0']
  },
  etherContractsApi: {
    mainnet: 'https://api-cn.etherscan.com/api',
    kovan: 'https://api-kovan.etherscan.io/api',
    ropsten: 'https://api-ropsten.etherscan.io/api',
    cn: 'https://api-cn.etherscan.com/api',
  },
  bsc: {
    mainnet: [
      'https://bsc-dataseed.binance.org/',
      'https://bsc-dataseed1.defibit.io/',
      'https://bsc-dataseed1.ninicoin.io/'
    ],
    testnet: [
      'https://data-seed-prebsc-1-s1.binance.org:8545/',
      'https://data-seed-prebsc-1-s2.binance.org:8545/',
      'https://data-seed-prebsc-2-s2.binance.org:8545/',
      'https://data-seed-prebsc-1-s3.binance.org:8545/',
      'https://data-seed-prebsc-2-s3.binance.org:8545/'
    ]
  },
  bscContractsApi: {
    mainnet: 'https://api.bscscan.com/api',
    testnet: 'https://api-testnet.bscscan.com/api'
  },
  bscExplorerApi: '85UGHD22WX3FE7P1XRINNS9TSI3WQDT7HT',
  idbStoreName: 'hyper-wallets',
  hyper_id: {
    host: 'https://login.hypersecureid.com',
    resourceServer: 'https://rs1.hypersecureid.com',
    backup_api: 'https://backup-service.uprotel.com/api/'
  },
  claimer_host: '',
  networks: {
    ethereum: 'mainnet',
    bsc: 'mainnet'
  },
  ott:{
    authenticationRequired: [ //todo: rename to notification ?
      eOttPermission.keys
    ]
  },
  authentication:{
    mfa: true,
    web_authn: false
  },
  session: {
    offline_timeout_for_lock: 1000 * 60 * 60,
  inactivity_timeout_for_lock: 1000 * 60 * 30,
    update_security_timeout: 1000 * 60 * 5
  },
  actions:{
    code_length: 2,
    unlock_expiration_timeout: 1000 * 60 * 5,
    key_approve_timeout: 1000 * 60 * 5
  },
  markets: {
    mxc: {
      access_key: 'mx0gcTZXQgjkWQb4Ni',
      secret_key: null
    }
  },
  spacebox_origin: 'https://spaced-bbox-stage.azurewebsites.net',
  resourceServerTimeoutMs: 30_000,
};
