import { DBConfig } from 'ngx-indexed-db';
import { environment } from '../../../environments/environment';

export const dbConfig: DBConfig  = {
  name: 'HyperWalletDB',
  version: 1,
  objectStoresMeta: [{
    store: environment.idbStoreName,
    storeConfig: { keyPath: 'key', autoIncrement: false },
    storeSchema: [
      { name: 'key', keypath: 'key', options: { unique: true } }
    ]
  }]
};
