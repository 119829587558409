import
{
    EtherCurrenciesFactory,
    ICurrency,
    IEthereumContract,
} from './currency';
import { Injector } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { WalletsApi } from '../api/wallets';
import { BigNumber, ethers, providers, utils } from 'ethers';
import { EventTransportService } from '../event-transport/event-transport.service';
import { AppPasswordService } from '../app-password/app-password.service';
import { AppKeyV2Service } from '../app-password/app-key-v2.service';
import { take } from 'rxjs/operators';
import { CurrencyApi } from '../api/currency';
import { NetworksService } from '../networks/networks.service';
import { ProviderFactoryService } from '../provider/provider-factory.service';
import { EncryptedWalletApi } from '../api/encrypted-wallet';
import { CryptoWalletApi } from '../api/crypto-wallet';
import { CryptoService } from '../crypto/crypto.service';
import { CryptoWalletService } from '../crypto-wallet/crypto-wallet.service';
import { HttpClient } from '@angular/common/http';
import { ConfigService, TConfig } from '../config/config.service';
import { UserApi } from '../api/user';
import { eOttPermission } from 'src/common/request_enums';
import { getHashHex } from 'src/common';
import { IActionInfo, IOttInfo } from 'src/common/action-interfaces';
import { eActionType } from 'src/common/actions/action-types';
import { ShortAddress } from 'src/common';
import { ActionService } from '../action/action.service';
import { eBlockChainExplorerFactory } from './explorers/blockchain-explorer';
import { IBlockChainExplorer, ITransactionResponceEx } from './explorers/types';
import { ePlatforms } from '../../../common/networks';
import { LoggerService } from '../logger/logger.service';


export interface IWallet
{
    platform: ePlatforms;
    address: string;
}

export interface IContract
{
    [network: string]: {
        address: string,
        abi: string
    };
}

export type ContractLike = string | IContract;

export function getPlatformFromString(_platform: string): ePlatforms
{
    for (let key in ePlatforms)
    {
        if (key.toLowerCase() === _platform.toLowerCase()) return ePlatforms[key];
    }
}

export abstract class Wallet
{
    protected address: string;
    protected iconUrl: string;
    protected currenciesCount: number = 0;
    protected currencies: ICurrency[] = [];
    protected cryptoWallet: CryptoWalletService;
    protected cryptoWalletId: string;
    protected currenciesSubject: ReplaySubject<ICurrency[]> = new ReplaySubject(
        1
    );
    protected privateWallet: any;
    protected isWalletReady: ReplaySubject<boolean> = new ReplaySubject(1);
    protected network: string;
    protected actionService: ActionService;
    protected walletApi: WalletsApi;
    protected provider: providers.Provider;
    protected history: ITransactionResponceEx[] = [];
    protected encryptedWalletApi: EncryptedWalletApi;
    protected cryptoWalletApi: CryptoWalletApi;
    protected cryptoService: CryptoService;
    protected eventTransport: EventTransportService;
    protected oldPasswordService: AppPasswordService;
    protected passwordService: AppKeyV2Service;
    protected currencyApi: CurrencyApi;
    protected userApi: UserApi;
    protected networksService: NetworksService;
    protected loggerService: LoggerService;
    protected providerFactoryService: ProviderFactoryService;
    protected http: HttpClient;
    protected currenciesLoadedSubject: ReplaySubject<boolean> = new ReplaySubject(
        1
    );
    protected configService: ConfigService;
    protected initCurrencies: boolean = false;

    constructor(
        protected injector: Injector,
        _cryptoWallet: CryptoWalletService,
        _cryptoWalletId: string,
        _address: string,
        _initCurrencies: boolean
    )
    {
        this.address = _address;
        this.cryptoWallet = _cryptoWallet;
        this.cryptoWalletId = _cryptoWalletId;
        this.initCurrencies = _initCurrencies;

        this.actionService = this.injector.get(ActionService);
        this.walletApi = this.injector.get(WalletsApi);
        this.currencyApi = this.injector.get(CurrencyApi);
        this.encryptedWalletApi = this.injector.get(EncryptedWalletApi);
        this.cryptoWalletApi = this.injector.get(CryptoWalletApi);
        this.cryptoService = this.injector.get(CryptoService);
        this.eventTransport = this.injector.get(EventTransportService);
        this.oldPasswordService = this.injector.get(AppPasswordService);
        this.passwordService = this.injector.get(AppKeyV2Service);
        this.networksService = this.injector.get(NetworksService);
        this.providerFactoryService = this.injector.get(ProviderFactoryService);
        this.http = this.injector.get(HttpClient);
        this.configService = this.injector.get(ConfigService);
        this.userApi = this.injector.get(UserApi);
        this.loggerService = this.injector.get(LoggerService);
    }

    public getAddress(): string
    {
        return this.address;
    }

    public getIconUrl(): string
    {
        return this.iconUrl;
    }

    public getCurrencies(): Observable<ICurrency[]>
    {
        return this.currenciesSubject.asObservable();
    }

    public async getChainId(): Promise<number>
    {
        return (await this.provider.getNetwork()).chainId;
    }

    //todo Refac me.
    public removeCurrency(
        _symbol: string,
        _contractAddressUpper: string
    ): boolean
    {
        let index = this.currencies.findIndex((c) =>
        {
            if (
                _contractAddressUpper != null &&
                c.getContractAddress() != null
            )
            {
                return (
                    _contractAddressUpper ==
                    c.getContractAddress().toUpperCase()
                );
            }

            return c.getSymbol() == _symbol;
        });

        if (index != -1)
        {
            this.currenciesSubject.next(this.currencies);
            this.currencies.splice(index, 1);
            return true;
        }

        return false;
    }

    public addCurrency(_currency: ICurrency)
    {
        if (
            this.currencies.find((currency) =>
            {
                return currency.getSymbol() === _currency.getSymbol();
            })
        )
        {
            return;
        }

        this.currencies.push(_currency);

        this.currenciesSubject.next(this.currencies);

        this.checkCurrenciesLoaded();
    }

    public onWalletReady(): Observable<boolean>
    {
        return this.isWalletReady.asObservable();
    }

    public isAllCurrenciesLoaded(): Observable<boolean>
    {
        return this.currenciesLoadedSubject.asObservable();
    }

    public async getCurrencyByAddress(_address: string): Promise<ICurrency>
    {
        await this.isAllCurrenciesLoaded().pipe(take(1)).toPromise();
        return this.currencies.find(
            (_currency) => _currency.getAddress() === _address
        );
    }

    public async getCurrencyBySymbol(_symbol: string): Promise<ICurrency>
    {
        await this.isAllCurrenciesLoaded().pipe(take(1)).toPromise();

        return this.currencies.find(
            (_currency) => _currency.getSymbol() === _symbol
        );
    }

    protected checkCurrenciesLoaded()
    {
        if (this.currenciesCount === this.currencies.length)
        {
            this.currenciesSubject.next(this.currencies);
            this.currenciesLoadedSubject.next(true);
        }
    }

    public async sendTransaction(_txData: any, _txInfo: { symbol: string, value: string, to?: string, icon?: string })
    {
        const txStr = JSON.stringify(_txData);

        /*
            add guid to tx
        */
        const actionInfo = await this.actionService.prepareAction(
            eActionType.TransactionStart,
            getHashHex(txStr),
            ShortAddress(_txData.from, 4),
            ShortAddress(_txInfo.to || _txData.to, 4),
            _txInfo.value,
            _txInfo.symbol,
            _txInfo.icon);

        await this.setupPrivateData(actionInfo);
    }

    protected async setupPrivateData(_actionInfo: IActionInfo)
    {
        const cryptoWallet = await this.cryptoWallet.getCryptoWalletById(this.cryptoWalletId);
        const ottInfo = <IOttInfo>{ permission: eOttPermission.keys, key_id: cryptoWallet.key_id };

        const privateData = await this.cryptoWallet.getPrivateData(this.cryptoWalletId, ottInfo, _actionInfo);

        if (privateData.type === 'private-key')
        {
            return this.setPrivateKey(privateData.data);
        }

        if (privateData.type === 'phrase')
        {
            return this.setWalletPhrase(privateData.data);
        }

        if (privateData.type === 'json')
        {
            const jsonData = JSON.parse(privateData.data);
            return this.setEncryptedData(jsonData.json, jsonData.password);
        }
    }

    protected abstract loadCurrencies();

    public abstract getBlockInfo(_blockNumber: number);

    public abstract getPlatform(): string;

    public abstract getName(): string;

    public abstract getHistory(): Promise<ITransactionResponceEx[]>;

    public abstract getPrivateKey(): Promise<any>;

    public abstract getEncryptedJSON(_password: string): Promise<string>;

    public abstract setEncryptedData(
        _encrData: string,
        _password: string
    ): Promise<any>;

    public abstract setPrivateKey(_privateKey: string): Promise<any>;

    public abstract setWalletPhrase(_phrase: string): Promise<any>;

    public static getAddressByPrivateKey(_privateKey: string): string
    {
        return null;
    }

    public static getAddressByPhrase(_phrase: string): string
    {
        return null;
    }

    public static getWalletDataByEncryptedJson(
        _json: string,
        _password: string
    ): string
    {
        return null;
    }

    public static generatePhrase(): string
    {
        const wallet = ethers.Wallet.createRandom();

        return wallet.mnemonic.phrase;
    }

    public abstract getLinkToExplorer(_txHash: string): Promise<string>;

    public abstract addCurrencyByContract(_contract: ContractLike, _symbol: string, _id?: number, _config?: TConfig, _data?: any)
}

export class EtherWallet extends Wallet
{
    protected iconUrl: string = '/assets/images/symbols/eth.svg';

    private name: string = 'Ethereum';

    private readonly explorer: IBlockChainExplorer;

    constructor(
        protected injector: Injector,
        _cryptoWallet: CryptoWalletService,
        _cryptoWalletId: string,
        _address: string,
        _initCurrencies = false
    )
    {
        super(injector, _cryptoWallet, _cryptoWalletId, _address, _initCurrencies);

        this.init();

        this.explorer = eBlockChainExplorerFactory.CreateEtherExplorer(injector.get(ProviderFactoryService));
    }

    protected async init()
    {
        this.provider = await this.providerFactoryService.getProvider(
            'ethereum'
        );

        await this.loadCurrencies();

        this.isWalletReady.next(true);

        this.eventTransport
            .on('ETHEREUM_PROVIDER_UPDATE')
            .subscribe(async () =>
            {
                this.isWalletReady.next(false);

                this.provider = await this.providerFactoryService.getProvider(
                    'ethereum'
                );

                await this.loadCurrencies();

                this.isWalletReady.next(true);
            });
    }

    public async setEncryptedData(_encrJson: string, _password: string)
    {
        this.privateWallet = await ethers.Wallet.fromEncryptedJson(
            _encrJson,
            _password
        );
    }

    public async setPrivateKey(_privateKey: string)
    {
        let privateKey = _privateKey;

        if (!privateKey.startsWith('0x'))
        {
            privateKey = '0x' + privateKey;
        }

        this.privateWallet = new ethers.Wallet(privateKey);
    }

    public async setWalletPhrase(_phrase: string)
    {
        this.privateWallet = ethers.Wallet.fromMnemonic(_phrase);
    }

    private static ConvertContractToObject(_contract: ContractLike): IContract
    {
        if (Boolean(_contract) && _contract !== 'null')
        {
            return typeof _contract === 'string'
                ? JSON.parse(_contract)
                : _contract;
        }
        else
        {
            return null;
        }
    }

    public async addCurrencyByContract(
        _contract: ContractLike,
        _symbol: string,
        _id?: number,
        _config?: TConfig,
        _data?: any
    )
    {
        let contractData: IEthereumContract = null;

        let contract = EtherWallet.ConvertContractToObject(_contract);

        if (contract != null)
        {
            if (!Boolean(contract[this.network]) && _config != null)
            {
                //todo refac
                this.currenciesCount -= 1;
                this.checkCurrenciesLoaded();
                return;
            }

            contractData = {
                address: contract[this.network].address,
                abi: contract[this.network].abi,
            };
        }

        const currency = EtherCurrenciesFactory(
            _symbol,
            this.injector,
            this,
            this.address,
            contractData
        );

        //refac me
        // if (_config != null) {
        //     await currency.onSetup.pipe(take(1)).toPromise();

        //     if (
        //         !_data &&
        //         _config.currencies.add_if_not_empty_balance.includes(
        //             _symbol.toUpperCase()
        //         ) &&
        //         Number(await currency.getBalance()) === 0
        //     ) {
        //         console.log(_symbol);
        //         this.currenciesCount -= 1;
        //         this.checkCurrenciesLoaded();
        //         this.currencyApi.removeWalletCurrency(this.cryptoWalletId, _id);
        //         return;
        //     }
        // }

        await currency.onSetup.pipe(take(1)).toPromise();

        this.addCurrency(currency);
    }

    protected async loadCurrencies()
    {
        this.currencies = [];
        const config = await this.configService.get();
        this.network = await this.networksService.getNetwork('ethereum');

        let currencies = await this.currencyApi.getWalletCurrency(
            this.cryptoWalletId,
            this.address,
            'ethereum'
        );

        if (currencies.length === 0 && this.initCurrencies)
        {
            currencies = await this.currencyApi.createBaseWalletCurrencies(
                this.cryptoWalletId,
                this.address,
                this.getPlatform()
            );
        }

        this.currenciesCount = currencies.length;

        if (currencies.length === 0 && !this.initCurrencies)
        {
            this.currenciesSubject.next(this.currencies);
            this.checkCurrenciesLoaded();
        }

        try
        {
            await Promise.all(
                currencies.map(async (_currency, index) =>
                {
                    // await sleep(index * 300); // set timeout to bypass etherscan requests limit per second (max 5)
                    await this.addCurrencyByContract(
                        _currency.contract,
                        _currency.symbol,
                        _currency['id'],
                        config
                    );
                })
            );
        } catch (error)
        {
            console.log(error);
        }
    }

    public async getBlockInfo(_blockNumber: number)
    {
        return await this.provider.getBlock(_blockNumber);
    }

    public getPlatform(): string
    {
        return ePlatforms.ETHEREUM;
    }

    public getName(): string
    {
        return this.name;
    }

    public async getHistory(): Promise<ITransactionResponceEx[]>
    {
        const block = await this.provider.getBlockNumber();

        //todo do pagination instead
        let history = await this.explorer.GetHistory(
            this.address,
            null,
            { startBlock: 0, endBlock: block });

        this.history = history;
        return this.history;
    }

    public async getPrivateKey(): Promise<string>
    {
        const cryptoWallet = await this.cryptoWallet.getCryptoWalletById(this.cryptoWalletId);
        const actionInfo = await this.actionService.prepareAction(eActionType.ExportKey, this.cryptoWalletId, cryptoWallet.name);
        await this.setupPrivateData(actionInfo);
        return this.privateWallet.privateKey;
    }

    public async getEncryptedJSON(_password: string): Promise<string>
    {
        const cryptoWallet = await this.cryptoWallet.getCryptoWalletById(this.cryptoWalletId);
        const actionInfo = await this.actionService.prepareAction(eActionType.ExportJson, this.cryptoWalletId, cryptoWallet.name);
        await this.setupPrivateData(actionInfo);

        return await this.privateWallet.encrypt(_password);
    }

    public async sendTransaction(_txData: any, _txInfo: { symbol: string, value: string, to?: string }): Promise<any>
    {
        await super.sendTransaction(_txData, _txInfo);

        const connectedWallet = this.privateWallet.connect(this.provider);

        this.loggerService.log('_txData: ', JSON.stringify(_txData));

        const populated = await connectedWallet.populateTransaction(_txData);

        this.loggerService.log('populated 1: ', JSON.stringify(populated));

        populated.from = connectedWallet.address;

        const pendingBlock = await this.provider.getBlock('latest');

        const baseFeePerGas = pendingBlock.baseFeePerGas.add(pendingBlock.baseFeePerGas.div(BigNumber.from('8')));

        populated.maxFeePerGas = baseFeePerGas.add(populated.maxPriorityFeePerGas);

        const maxPriorityFeePerGasNumber = Number(utils.formatUnits(populated.maxPriorityFeePerGas, 'gwei'));
        const maxFeePerGasNumber = Number(utils.formatUnits(populated.maxFeePerGas, 'gwei'));

        if (maxFeePerGasNumber < maxPriorityFeePerGasNumber)
        {
            populated.maxFeePerGas = populated.maxPriorityFeePerGas;
        }

        const calculatedFee = populated.maxFeePerGas.mul(populated.gasLimit);

        const txForLog = {
            tx: populated,
            maxPriorityFeePerGas: utils.formatUnits(populated.maxPriorityFeePerGas, 'gwei'),
            maxFeePerGas: utils.formatUnits(populated.maxFeePerGas, 'gwei'),
            calculatedFeeBN: calculatedFee,
            calculatedFee: utils.formatUnits(calculatedFee)
        };

        this.loggerService.log('populated: ', JSON.stringify(txForLog));

        console.log(txForLog)

        // delete populated.maxFeePerGas;

        return await connectedWallet.sendTransaction(populated);
    }

    public static checkAddress(_address: string): string
    {
        const wallet = new ethers.Wallet(_address);
        return wallet.address;
    }

    public static getAddressByPrivateKey(_privateKey: string): string
    {
        let privateKEy = _privateKey;

        if (!_privateKey.startsWith('0x'))
        {
            privateKEy = '0x' + _privateKey;
        }

        const wallet = new ethers.Wallet(privateKEy);
        return wallet.address;
    }

    public static getAddressByPhrase(_phrase: string): string
    {
        const wallet = ethers.Wallet.fromMnemonic(_phrase);
        return wallet.address;
    }

    public static async getAddressByEncryptedJson(
        _json: string,
        _password: string
    ): Promise<string>
    {
        const wallet = await ethers.Wallet.fromEncryptedJson(_json, _password);
        return wallet.address;
    }

    public async getLinkToExplorer(_txHash: string): Promise<string>
    {
        const network = await this.networksService.getNetwork('ethereum');

        if (network === 'kovan')
        {
            return `https://kovan.etherscan.io/tx/${_txHash}`;
        }
        else if (network === 'ropsten')
        {
            return `https://ropsten.etherscan.io/tx/${_txHash}`;
        } else
        {
            return `https://etherscan.io/tx/${_txHash}`;
        }
    }
}
