import { providers } from "ethers";

export class CustomJsonRpcProvider extends providers.JsonRpcProvider
{
  private cacheChainId:any;

  constructor(chainId, ...agrs)
  {
    super(...agrs)
    this.cacheChainId = '0x' + chainId.chainId.toString(16);
  }

  async send(method: string, params: Array<any>):Promise<any>
  {
    if(method === "eth_chainId")
    {
      return this.cacheChainId;
    } 
 
    return await super.send(method, params)
  }
}