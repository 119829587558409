import { eAuthResult } from "./request_enums";

export const KEY_MFA_APP_REF = 'mfa_app_url'

export enum eAuthenticationType {
    mfa         = 1,
    web_authn   = 2,
}

export const ArrayOfSecurityOptionName = ['unlock', 'transaction', 'wallet_backup_del', 'wallet_export', 'wallet_del', 'exchange_load', 'exchange_transaction', 'exchange_backup_del', 'security_change'] as const;
export type SecurityOptionName = typeof ArrayOfSecurityOptionName[number];

export interface ISecurityOption {
    name:   SecurityOptionName;
    value:  eAuthenticationType;
}

export interface IVerificationResult {
    authResult: eAuthResult,
    transactionId?: number
}
  
export interface ISecurityOptions {
    unlock:                eAuthenticationType | null;
    transaction:           eAuthenticationType | null;
    wallet_backup_del:     eAuthenticationType | null;
    wallet_export:         eAuthenticationType | null;
    wallet_del:            eAuthenticationType | null;
    exchange_load:         eAuthenticationType | null;
    exchange_transaction:  eAuthenticationType | null;
    exchange_backup_del:   eAuthenticationType | null;
    security_change:       eAuthenticationType | null;
}
