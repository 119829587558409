import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { startAttestation, startAssertion } from '@simplewebauthn/browser';
import { UserService } from '../user/user.service';
import { WebauthnStorage } from './webauthn-storage';
import { ConfigService } from '../config/config.service';
import { environment } from '../../../environments/environment';
import { eAuthResult } from 'src/common/request_enums';
import { nowToTimeStr } from 'src/common/index';
import { IActionInfo } from 'src/common/action-interfaces';
import { eActionType } from 'src/common/actions/action-types';
import { ActionService } from '../action/action.service';
import { AuthService } from './auth.service';
import { SessionAuthService } from './session-auth.service';
import { eAuthenticationType } from '../../../common/auth_types';

(window as any).global = window;
window['Buffer'] = window['Buffer'] || require('buffer').Buffer;

enum eAuthenticatedFromStorage
{
    ON = "on",
    OFF = "off"
}

type AuthenticatedFromStorage = eAuthenticatedFromStorage

@Injectable({
    providedIn: 'root'
})
export class WebAuthenticatorService
{

    private isUserAuthenticated: boolean = false;

    private isAuthenticatedFromStorage: eAuthenticatedFromStorage;

    constructor(
        private http: HttpClient,
        private actionService: ActionService,
        private userService: UserService,
        private webauthnStorage: WebauthnStorage,
        private configService: ConfigService,
        private authService: AuthService,
        private sessionAuthService: SessionAuthService,
    )
    {
        this.authService.loginState.subscribe(state =>
        {
            if (!state)
            {
                localStorage.removeItem("isAuthenticatedFromStorage")
            }
        });
    }

    public async isWebAuthenticationSupported(): Promise<boolean>
    {
        let result: boolean = false;

        const config = await this.configService.get();

        const biometric = (config.authentication.biometric === undefined)
            ? environment.authentication.web_authn
            : config.authentication.biometric;

        if (!biometric) return false;

        const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
        const isIOS = /(iPhone|iPod|iPad)/i.test(navigator.platform);

        if (!isMacLike && !isIOS && typeof (PublicKeyCredential) !== "undefined")
        {
            result = await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
        }

        return result;
    }

    public async checkWebAuthCreated()
    {
        const assertResponse = await this.http.get(`/api/web-authn/assertion`).toPromise();
        const allowCredentials = await this.getCreatedCredentials(assertResponse['allowCredentials']);

        console.debug(`${nowToTimeStr()} << checkWebAuthCreated ${JSON.stringify(assertResponse)}`);

        return allowCredentials.length > 0;
    }

    public async getWebAuthAssertion()
    {
        const assertResponse = await this.http.get(`/api/web-authn/assertion`).toPromise();
        const allowCredentials = await this.getCreatedCredentials(assertResponse['allowCredentials']);
        assertResponse['allowCredentials'] = allowCredentials;

        console.debug(`${nowToTimeStr()} << getWebAuthAssertion ${JSON.stringify(assertResponse)}`);
        return assertResponse;
    }

    public async createWebAuthentication(actionInfo?: IActionInfo): Promise<boolean>
    {
        try
        {
            if (this.isUserAuthenticated) return true

            let funcName = '/api/web-authn/pk-create-options';

            console.debug(`${nowToTimeStr()} >> ${funcName}`);

            const pkCreateOptionsResponse = await this.http.get(funcName).toPromise();

            console.debug(`${nowToTimeStr()} << ${funcName}: pkCreateOptionsResponse[${JSON.stringify(pkCreateOptionsResponse).length}]`);

            const credentials = await startAttestation(<any>pkCreateOptionsResponse);

            if (!actionInfo)
            {
                actionInfo = await this.actionService.prepareAction(eActionType.Authorization);
            }

            const requestData = {
                credentials: credentials,
                origin: location.origin,
                rpID: location.hostname,
                action: actionInfo
            };

            console.debug(`${nowToTimeStr()} >> /api/web-authn/verify: requestData[${JSON.stringify(requestData).length}]`);

            const responce = await this.http.post(`/api/web-authn/verify`, requestData).toPromise();

            if (responce['result'] !== 'failed')
            {
                console.debug(`${nowToTimeStr()} << /api/web-authn/verify: responce[${JSON.stringify(responce).length}]`);
            }
            else
            {
                console.error(`${nowToTimeStr()} << /api/web-authn/verify ${JSON.stringify(responce)}`);
            }

            const userData = await this.userService.dataPromise;

            await this.webauthnStorage.set(userData.id, credentials.id);

            this.setState(true);

            this.setToStorage(eAuthenticatedFromStorage.ON)

            return true;
        }
        catch (err)
        {
            console.error(err);
        }

        return false;
    }

    public async authenticateUser(actionInfo?: IActionInfo): Promise<eAuthResult>
    {
        try
        {
            const isWebAuthnSupported = await this.isWebAuthenticationSupported();

            if (!isWebAuthnSupported)
            {
                this.setState(true);
                return eAuthResult.accept;
            }

            let isWebAuthCreated = await this.checkWebAuthCreated();

            if (!isWebAuthCreated)
            {
                isWebAuthCreated = await this.createWebAuthentication(actionInfo);
            }
            if (!isWebAuthCreated)
            {
                throw new Error('WEBAUTH.CREATE.FAILED');
            }

            const assertResponse = await this.getWebAuthAssertion();

            if (assertResponse['allowCredentials'].length === 0)
            {
                throw new Error('NOT_CREATED');
            }

            const credentials = await startAssertion(<any>assertResponse);

            let funcName = '/api/web-authn/verify';

            let responce;

            if (actionInfo)
            {
                funcName = '/api/web-authn/verify-action';

                console.debug(`${nowToTimeStr()} >> ${funcName} ${JSON.stringify(credentials)}`);

                responce = await this.http.post(funcName, { credentials: credentials, origin: location.origin, rpID: location.hostname, action: actionInfo }).toPromise();
            }
            else
            {
                console.debug(`${nowToTimeStr()} >> ${funcName} ${JSON.stringify(credentials)}`);

                responce = await this.http.post(funcName, { credentials: credentials, origin: location.origin, rpID: location.hostname }).toPromise();
            }

            if (responce['result'] !== 'failed')
            {
                console.debug(`${nowToTimeStr()} << ${funcName} ${JSON.stringify(responce)}`);
            }
            else
            {
                console.error(`${nowToTimeStr()} << ${funcName} ${JSON.stringify(responce)}`);
            }

            if (responce['result'] == 'verified')
            {
                this.setState(true);
                return eAuthResult.accept;
            }

            return eAuthResult.not_completed;
        }
        catch (error)
        {
            console.error(error);
            return eAuthResult.not_completed;
        }
    }

    public async isAuthenticated(): Promise<boolean>
    {
        const authType = await this.sessionAuthService.getAuthType();

        if (authType === eAuthenticationType.mfa)
        {
            this.setState(true);
            this.setToStorage(eAuthenticatedFromStorage.ON)
        }

        const isWebAuthnSupported = await this.isWebAuthenticationSupported();

        if (!isWebAuthnSupported)
        {
            this.setState(true);
            this.setToStorage(eAuthenticatedFromStorage.ON)
        }

        if (!this.isUserAuthenticated)
        {
            this.isAuthenticatedFromStorage = this.getFromStorage();
            if (this.isAuthenticatedFromStorage === eAuthenticatedFromStorage.ON)
            {
                this.isUserAuthenticated = true;
            }
        }

        return this.isUserAuthenticated;
    }

    public skipAuthentication()
    {
        this.setState(false);
    }

    private async getCreatedCredentials(_allowCredentials: string[]): Promise<any[]>
    {
        const userData = await this.userService.dataPromise;
        const webauthnIDs = await this.webauthnStorage.get(userData.id);

        if (webauthnIDs.length === 0) return [];

        if (_allowCredentials.length === 0) return [];

        const result = _allowCredentials;

        return result;
    }

    public async authenticateUser2Ott(actionInfo: IActionInfo): Promise<any>
    {
        try
        {
            const assertResponse = await this.getWebAuthAssertion();

            if (assertResponse['allowCredentials'].length === 0)
            {
                throw new Error('NOT_CREATED');
            }

            const credentials = await startAssertion(<any>assertResponse);

            console.debug(`${nowToTimeStr()} >> /api/web-authn/verify-ott ${JSON.stringify(credentials)}`);

            const responce = await this.http.post(`/api/web-authn/verify-ott`, { credentials: credentials, origin: location.origin, rpID: location.hostname, action: actionInfo }).toPromise();

            console.debug(`${nowToTimeStr()} << /api/web-authn/verify-ott ${JSON.stringify(responce)}`);

            if (responce['result'] == 'failed')
            {
                const errorMessage = `authenticateUser2Ott >> ${responce['message']}`;
                throw new Error(errorMessage);
            }

            return responce;
        }
        catch (error)
        {
            console.error(error);
            throw error;
        }
    }

    private setState(_state: boolean)
    {
        this.isUserAuthenticated = _state;
    }

    private getFromStorage()
    {
        return <AuthenticatedFromStorage>localStorage.getItem("isAuthenticatedFromStorage")
    }

    private setToStorage(_value: AuthenticatedFromStorage)
    {
        localStorage.setItem("isAuthenticatedFromStorage", _value)
    }
}
