import { BigNumber } from 'ethers';

export class eBlockLookup
{
    startBlock:    number;
    endBlock:      number;
}

export class ePageLookup
{
    page:          number;
    maxPageSize:   number;
}

export interface ITransactionValue
{
    value:          BigNumber;
    data?:          string;
}

export interface ITransactionGas
{
    gasPrice?:       BigNumber;     //price
    gasLimit:       BigNumber;     //count
    maxFeePerGas?:   BigNumber;
}

export interface ITransaction extends ITransactionValue, ITransactionGas
{
    from:           string;
    to:             string;
}

export interface ITransactionResponceEx extends ITransaction
{
    hash:          string;
    timestamp:     number;
    confirmations: number;
    blockNumber:   number;

    gasUsed:       BigNumber;     //count
}

//todo remove me
export interface ITransactionFee
{
    feeAmount:  string; //gasPrice in gwei
    fee:        string; //in ETH
    format:     'gwei';
    fiatFee:    string; //in USD
}

//todo remove me
export interface ITransactionAmount
{
    value:  string; //in ETH
    price : number; //in USD
}

//todo remove me
export interface ITransactionParsed extends ITransactionResponceEx
{
    amount: ITransactionAmount;
    fee:    ITransactionFee;
}

//all gases in Gwei
//todo change to BN
export interface IGasOracle
{
    safeGasPrice:       string,
    proposeGasPrice:    string,
    fastGasPrice:       string,
}

export interface IBlockChainExplorer
{
    GetBlockNumber(): Promise<number>;

    /**
     * If contractAddress not null
     * Get a list of "ERC20 - Token Transfer Events" by Address.
     * Else Get a list of 'Normal' Transactions By Address.
     * @param address
     * @param contractAddress
     * @param lookSettings
     */
    GetHistory(
        address:            string,
        contractAddress:    string,//pass null for ETH
        lookSettings:       ePageLookup | eBlockLookup): Promise<ITransactionResponceEx[]>;

    /**
     * (SafeGasPrice, ProposeGasPrice And FastGasPrice returned in Gwei)
     */
    GetGasOracle(): Promise<IGasOracle>;

    /**
     * Result returned in seconds, gasprice value in Wei
     * @param gasPrice
     */
    GetEstimatationOfConfirmTime(gasPrice: string): Promise<number>;

    GetContractABI(address: string): Promise<string>;
}

export enum eRejectFailReason
{
    NONE,
    FAIL,
    FAIL_BY_LIMIT_REACHED,
}
