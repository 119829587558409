import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import axios from 'axios';
import {HttpClient} from "@angular/common/http";
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../storage/storage.service';
import { getValue } from 'src/common';

export enum eAuthProvider {
  HyperID = 'HYPER-ID'
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loginState_: ReplaySubject<boolean> = new ReplaySubject(1);
  private lockState_: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private http: HttpClient,
    private router: Router,
    private translateService: TranslateService,
    private storage: StorageService
  ) {
    this.checkLoggedIn();
    window.addEventListener('storage', e =>
    {
      switch(e.key){
        case 'is_logged_in':
          if(e.newValue == 'true')
            this.checkLoggedIn();
          if(e.newValue == 'false')
            this.logOut(false);
          break;

        case 'is_locked':
          if(e.newValue == 'true')
            this.lock(false);
          if(e.newValue == 'false')
            this.checkUnlocked();
          break;
      }
    });
  }

  get loginState(): Observable<boolean> {
    return this.loginState_.asObservable();
  }

  public async currentLoginState(): Promise<boolean>{
    return getValue(this.loginState);
  }

  get lockState(): Observable<boolean>{
    return this.lockState_.asObservable();
  }

  private async readUserKey(){
    let keyBase64 = null;
    do
    {
      keyBase64 = await this.storage.get('userkey_publicBase64');
    }
    while(keyBase64 == null)

    return encodeURIComponent(keyBase64);
  }

  public authorize(_scopes = []) {
    const lang = this.translateService.currentLang;
    location.href = `/sign-in/oauth2?lang=${lang}&scopes=${JSON.stringify(_scopes)}`;
  }

  public async readAuthorizeUrl(_scopes = []) {
    const lang = this.translateService.currentLang;
    let url = `/sign-in/oauth2?lang=${lang}&scopes=${JSON.stringify(_scopes)}`;
    // console.log(`>> /sign-in/oauth2`);
    const response = await axios(url, { method: 'POST' });

    const encodedUrl = response.data['encodedBase64Url'];
    const buffUrl = Buffer.from(encodedUrl, 'base64');
    const decodedUrl = buffUrl.toString();

    const userKey = await this.readUserKey();

    url = `${decodedUrl}&lang=${lang}&user_key=${userKey}`;
    console.log(`<< /sign-in/oauth2 ${url}`);
    return url;
  }

  private async checkLoggedIn() {
    try {
      const response = await this.http.get('/api/session/check').toPromise();
      if(Boolean(response))
      {
        this.loginState_.next(true);
        localStorage.setItem('is_logged_in', 'true');
        return true;
      }
    } catch (error) {
      console.log(error)
      this.loginState_.next(false);
      localStorage.setItem('is_logged_in', 'false');
    }

    return false;
  }

  public async checkUnlocked(): Promise<boolean> {
    try {
      const loginState = await this.currentLoginState();
      if(!loginState) return false;

      const response = await this.http.get('/api/session/check-unlocked').toPromise();
      const isUnlocked = Boolean(response);

      this.lockState_.next(!isUnlocked);
      return isUnlocked;
    } catch (error) {
      console.log(error)
    }

    return false;
  }

  public async lock(_redirect = true)
  {
    var actionData;
    console.log('lock session');
    try
    {
      if (_redirect) {
        actionData = await this.http.get('/api/session/lock').toPromise();
      }

      switch(actionData['result']){
        case 'locked':
          this.lockState_.next(true);
          localStorage.setItem('is_locked', 'true');
          this.router.navigate(['/unlock']);
          break;
        case 'logout':
          this.loginState_.next(false);
          localStorage.removeItem('is_locked');
          localStorage.removeItem('auth_type');
          localStorage.setItem('is_logged_in', 'false');
          this.router.navigateByUrl('/sign-in');
          break;
      }
    }
    catch (error)
    {
      console.log(error)
      this.loginState_.next(false);
    }
  }

  public async logOut(_redirect = true, _failed = false, _errorMessage?: string) {

    let signInUrl = _failed ? '/sign-in/failed' : '/sign-in';

    const queryParams = {};

    if (_failed)
    {
        queryParams['message'] = _errorMessage;
    }

    try {
      if (_redirect) {
        this.http.get('/api/session/log-out').toPromise();
      }

      this.loginState_.next(false);
      localStorage.removeItem('is_locked');
      localStorage.removeItem('auth_type');
      localStorage.setItem('is_logged_in', 'false');
      this.router.navigate([signInUrl], {queryParams: queryParams, queryParamsHandling: 'merge'});

    } catch (error) {
      this.loginState_.next(false);
      this.router.navigate([signInUrl], {queryParams: queryParams, queryParamsHandling: 'merge'});
    }
  }
}
